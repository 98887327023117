import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import {
  getMUserC,
  postLogin,
  postVscode,
  postAuth,
  postLogout,
  postMemberRegister,
  postMoneyBack,
  postVipList,
  postuserSum,
  postPhoneSwitch,
  postSendMsg,
  postMemberPhoneRegister,
  postShareRegister,
  postPromotionChk,
} from "@/assets/API/API";
import type {
  MUserCResponse,
  MemberData,
  VipListList,
} from "@/assets/API/APItype";

import { useContentInfo } from "./contentInfo";
import { useMemberService } from "./memberFunction/memberService";
import { useErrorAlert } from "./errorAlert";
import { useLoadingPage } from "./loadingPage";
import { useMaintain } from "@/stores/maintain";

interface LoginFormValues {
  sAccount: string;
  sPasswd: string;
  nVscode: string;
  // keepPassword: boolean | undefined;
}
export const useLogin = defineStore("login", () => {
  const { t } = useI18n();
  const router = useRouter();
  const route = useRoute();
  const isLogin = ref<boolean>(false);

  const contentInfo = useContentInfo();
  const maintain = useMaintain();
  const memberService = useMemberService();
  const errorAlert = useErrorAlert();
  const loadingPage = useLoadingPage();
  const memberData = ref<MemberData>({} as MemberData);
  const isZeroVipLevel = computed(() => {
    if (memberData.value.aVipData?.nVipLv === "0") return true;
    return false;
  });
  const isCashMember = computed(() => {
    if (memberData.value.nType1 === "0") return true;
    return false;
  });

  function detectBrowser() {
    const userAgent = navigator.userAgent;
    let url;
    if (userAgent.indexOf("Line") !== -1) {
      const main = /main/;
      const Discount = /Discount/;
      const ShareMakeFortunePage = /ShareMakeFortunePage/;
      const APPDownload = /APPDownload/;
      const nShare = /nShare|nAccount|p=|a=/;
      const urlOrigin = window.location.origin;
      const urlHref = window.location.href;
      const urlName = window.location.pathname;
      switch (true) {
        case window.location.hash === "#/":
        case main.test(urlHref):
          url = `${urlOrigin}${urlName}line/?a=main&openExternalBrowser=1`;
          break;
        case Discount.test(urlHref):
          url = `${urlOrigin}${urlName}line/?a=Discount&openExternalBrowser=1`;
          break;
        case ShareMakeFortunePage.test(urlHref):
          url = `${urlOrigin}${urlName}line/?a=ShareMakeFortunePage&openExternalBrowser=1`;
          break;
        case APPDownload.test(urlHref):
          url = `${urlOrigin}${urlName}line/?a=APPDownload&openExternalBrowser=1`;
          break;
        case nShare.test(urlHref):
          url = `${urlHref}&openExternalBrowser=1`;
          break;
        default:
          url = `${urlHref}/?openExternalBrowser=1`;
          break;
      }
      window.open(url, "_self");
    }
    // ios input zoom problem
    // @ts-ignore
    if (!/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return;
    }
    const element = document.querySelector("meta[name=viewport]");
    if (element !== null) {
      let content: any = element.getAttribute("content");
      const scalePattern = /maximum\-scale=[0-9\.]+/g;
      if (scalePattern.test(content)) {
        content = content.replace(scalePattern, "maximum-scale=1.0");
      } else {
        content = [content, "maximum-scale=1.0"].join(", ");
      }
      element.setAttribute("content", content);
    }
  }

  //#region jump cookies
  // 儲存cookie
  async function saveJump() {
    const jump = route.query.jump ? route.query.jump.toString() : "";
    if (!jump) return;

    const expirationDate = new Date(+new Date() + 8 * 3600 * 1000);
    expirationDate.setTime(expirationDate.getTime() + 1 * 60 * 60 * 1000); // 1 小时的毫秒数
    const expires = expirationDate.toUTCString();
    document.cookie = `jump=${jump}; expires=${expires}`;
  }

  function clearJump() {
    document.cookie = `jump=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  // 取得cookie
  const CookieJump = () => {
    let JumpCookie = "";

    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim(); // 去除首尾空格
      const parts = cookie.split("=");
      const name = parts[0];
      const value = parts[1];

      // 找到了名为 "jump" 的 Cookie
      if (name === "jump") {
        JumpCookie = value;
        break;
      }
    }
    return JumpCookie;
  };
  //#endregion

  // 儲存cookie
  async function saveMUserC() {
    const response: MUserCResponse = await getMUserC();
    // 檢查維護狀態
    if (maintain.isMaintain(response)) return;
    const MUC = response["m_user_c_cookie"];

    const expirationDate = new Date(+new Date() + 8 * 3600 * 1000);
    expirationDate.setTime(expirationDate.getTime() + 1 * 60 * 60 * 6000); // 1 小时的毫秒数
    const expires = expirationDate.toUTCString();
    document.cookie = `m_user_c_cookie=${MUC}; expires=${expires}`;
  }

  function clearMUserC() {
    document.cookie = `m_user_c_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  // 取得cookie
  const CookieMUC = () => {
    let MUCCookie = "";

    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim(); // 去除首尾空格
      const parts = cookie.split("=");
      const name = parts[0];
      const value = parts[1];

      // 找到了名为 "m_user_c_cookie" 的 Cookie
      if (name === "m_user_c_cookie") {
        MUCCookie = value;
        break;
      }
    }
    return MUCCookie;
  };
  // 檢查cookie
  const isLoadingLogin = ref<boolean>(false);
  async function CheckMUC(success: () => void, fail: () => void) {
    isLoadingLogin.value = true;
    const cookieMUC = CookieMUC();
    if (cookieMUC) {
      // getPhoneSwitch();
      // getUserSum();

      // 此處還錢, 改為每次進入遊戲前還錢
      // await getMoneyBack();

      await CheckAuth();
      if (isLogin.value) {
        success();
        memberService.getMailCount();
      } else {
        fail();
      }
    }
    if (!cookieMUC) {
      isLogin.value = false;
      await saveMUserC();
      // await getUserSum();
      fail();
    }
    if (
      route.name === "main" ||
      route.name === "APPDownload" ||
      window.innerWidth > 768
    ) {
      contentInfo.getContentInfo();
    }
    isLoadingLogin.value = false;
  }

  // 在線人數
  const userSum = ref<string>("");
  async function getUserSum() {
    const m_user_c = CookieMUC();
    const data = {
      m_user_c,
    };
    const res = await postuserSum(data);
    // console.log(res);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    userSum.value = res.dataList ? res.dataList.user_sum : "0";
    setTimeout(() => {
      getUserSum();
    }, 30 * 1000);
  }
  getUserSum();

  // 取回會員金額
  async function getMoneyBack() {
    loadingPage.isLoadingPage = true;
    console.log(isLoadingLogin.value, "getMoneyBack");
    const m_user_c = CookieMUC();
    const data = {
      m_user_c,
    };
    const res = await postMoneyBack(data);
    loadingPage.isLoadingPage = false;
    console.log(res, isLoadingLogin.value, "getMoneyBack");
  }

  // 驗證登入
  async function CheckAuth() {
    const m_user_c = CookieMUC();
    saveJump();
    const jump = CookieJump();
    const data = jump
      ? {
          m_user_c,
          jump,
        }
      : {
          m_user_c,
        };
    const res = await postAuth(data);
    // 檢查是否 維護中
    if (res.aMaintenance) {
      const { nMaintenance, sMsg } = res.aMaintenance;
      if (nMaintenance === '1') {
        maintain.MaintainMsg = sMsg
        await logout()
        router.push('isMaintain')
        return false
      }
    }
    
    memberData.value = res.memberData
      ? (res.memberData as MemberData)
      : ({} as MemberData);
    // console.log(res.type, memberData.value, "CheckAuth");
    switch (res.type) {
      case 0:
        isLogin.value = true;
        break;
      case 1: // 未登入
        isLogin.value = false;
        break;
      case 2: // 沒有此cookies
        isLogin.value = false;
        await saveMUserC();
        break;
      default:
        isLogin.value = false;
        await saveMUserC();
        break;
        break;
    }
    return true
  }

  // 登入 註冊頁面
  const PageMode = ref<number>(0);
  function selectMode(mode: number) {
    PageMode.value = mode;
  }

  // 檢查註冊是否為 分享發財 與 推薦碼
  const PromoCode = ref<string>("");
  const Account = ref<string>("");
  const Share = ref<string>("0");
  const HasChecked = ref<boolean>(false);

  // 儲存Cookies
  async function saveCookies(key: string, value: string) {
    const expirationDate = new Date(+new Date() + 8 * 3600 * 1000);
    expirationDate.setTime(expirationDate.getTime() + 48 * 60 * 60 * 6000); // 1 小时的毫秒数
    const expires = expirationDate.toUTCString();
    document.cookie = `${key}=${value}; expires=${expires}`;
  }
  // 取得cookie
  const getRegisterCookies = () => {
    console.log("getRegisterCookies");
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim(); // 去除首尾空格
      const parts = cookie.split("=");
      const name = parts[0];
      const value = parts[1];

      // 找到了名为 "m_user_c_cookie" 的 Cookie
      if (name === "sPromoCode") {
        PromoCode.value = value;
      }
      if (name === "sAccount") {
        Account.value = value;
      }
      if (name === "nShare") {
        Share.value = value;
      }
    }
  };
  // 清除cookie
  function clearRegisterCookies() {
    console.log("clearRegisterCookies");
    PromoCode.value = "";
    Share.value = "";
    Account.value = "";
    document.cookie = `sPromoCode=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `sAccount=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `nShare=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  function getPromoCodeAndShare() {
    const { sPromoCode, nShare, sAccount, p, a } = route.query;
    // clearRegisterCookies();
    if (sPromoCode) {
      PromoCode.value = sPromoCode.toString();
      saveCookies("sPromoCode", PromoCode.value);
      if (!HasChecked.value) PageMode.value = 1;
    }
    if (p) {
      PromoCode.value = p.toString();
      saveCookies("sPromoCode", PromoCode.value);
      if (!HasChecked.value) PageMode.value = 1;
    }
    if (nShare) {
      Share.value = nShare.toString();
      saveCookies("nShare", Share.value);
      if (!HasChecked.value) PageMode.value = 1;
    }
    if (sAccount) {
      Account.value = sAccount.toString();
      saveCookies("sAccount", Account.value);
    }
    if (a) {
      Account.value = a.toString();
      saveCookies("sAccount", Account.value);
    }
    getRegisterCookies();
    HasChecked.value = true;
  }

  // 檢查推廣碼是否過期
  async function checkPromoCodeAndShare(promoCode: string) {
    if (!promoCode) return
    const m_user_c = CookieMUC();
    const postData = {
      m_user_c,
      sRecommend: promoCode
    };
    const res = await postPromotionChk(postData);
    switch (res.type) {
      case 0: // 成功則繼續
        break;
      default: // 失敗回主頁
        clearRegisterCookies();
        router.push({ name: "main" });
        errorAlert.AddErrorAlert(res.msg);
        // failRegister(res.msg)
        break;
    }
  }

  // 進入登入頁
  async function toLoginPage() {
    await CheckMUC(successToLoginPage, failToLoginPage);
    console.log("toLoginPage");
  }
  async function successToLoginPage() {
    if (isLogin.value) {
      router.push({ name: "main" });
    } else {
      loadingPage.openLoadingPage();
      clearMUserC();
      await saveMUserC();
      await getVscode();
      await getPhoneSwitch();
      getPromoCodeAndShare();
      await checkPromoCodeAndShare(PromoCode.value);
    }
    loadingPage.closeLoadingPage();
  }
  async function failToLoginPage() {
    loadingPage.openLoadingPage();
    clearMUserC();
    await saveMUserC();
    await getVscode();
    await getPhoneSwitch();
    getPromoCodeAndShare();
    await checkPromoCodeAndShare(PromoCode.value);
    loadingPage.closeLoadingPage();
  }

  // 登入彈窗
  const isLoginAlert = ref<boolean>(false);
  function openLoginAlert() {
    isLoginAlert.value = true;
    // setTimeout(() => {
    //   closeLoginAlert();
    // }, 2000);
  }
  function closeLoginAlert() {
    isLoginAlert.value = false;
  }

  const loginMsg = ref<string>("");
  // 登入
  async function login(data: LoginFormValues) {
    loadingPage.openLoadingPage();
    const m_user_c = CookieMUC();
    const postData = {
      ...data,
      m_user_c,
    };
    const res = await postLogin(postData);
    switch (res.type) {
      case 0:
        isLogin.value = true;
        openLoginAlert();
        const isMaintain = await CheckAuth();
        if (isMaintain) router.push({ name: "main" });
        break;
      case undefined:
        isLogin.value = false;
        loginMsg.value = t("Store.Login.TimeoutTryAgain");
        failToLoginPage();
        // location.reload();
        break;
      default:
        isLogin.value = false;
        loginMsg.value = res.msg;
        // errorAlert.AddErrorAlert(res.msg);
        break;
    }
    if (res.msg === "認證碼錯誤0") {
      isLogin.value = false;
      loginMsg.value = t("Store.Login.TimeoutTryAgain");
      failToLoginPage();
    }
    loadingPage.closeLoadingPage();
  }
  //登出
  async function logout() {
    const m_user_c = CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await postLogout(postData);
    switch (res.type) {
      case 0:
        isLogin.value = false;
        clearMUserC();
        router.push({ name: "main" });
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        loginMsg.value = res.msg;
        break;
    }
  }

  const Vscode = ref<string>("");
  // 取得驗證碼
  async function getVscode() {
    const m_user_c = CookieMUC();
    const data = {
      m_user_c,
    };
    const res = await postVscode(data);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    switch (res.type) {
      case "0":
        Vscode.value = res.dataList;
        loginMsg.value = "";
        break;
      default:
        Vscode.value = res.dataList;
        getVscodeError(res.msg);
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }
  function getVscodeError(msg: string) {
    loginMsg.value = msg;
    switch (msg) {
      case "查無資料":
        loginMsg.value = t("Store.Login.RetrieveCode");
        break;
    }
  }

  // 記住密碼
  function savePassword(pwd: string, acc: string, day: number) {
    const basePwd = window.btoa(pwd);
    const baseAcc = window.btoa(acc);

    const expirationDate = new Date(+new Date() + 8 * 3600 * 1000);
    expirationDate.setTime(
      expirationDate.getTime() + 24 * 60 * 60 * 1000 * day
    ); // 3 小时的毫秒数
    const expires = expirationDate.toUTCString();
    document.cookie = `sp=${basePwd}; expires=${expires}`;
    document.cookie = `sa=${baseAcc}; expires=${expires}`;
  }

  // 取得記住密碼
  const keepPassword = ref<string>("");
  const keepAccount = ref<string>("");
  function getKeepPassword() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const parts = cookie.split("=");
      const name = parts[0];
      const value = parts[1];

      if (name === "sp") {
        keepPassword.value = window.atob(value);
      }
      if (name === "sa") {
        keepAccount.value = window.atob(value);
      }
    }
  }

  interface FormValues {
    sAccount: string;
    sPasswd: string;
    sPasswd2: string;
    sPhone: string;
    sPhone_Vscode: string;
    sRecommend: string;
    sLineId: string;
    nShare: string;
  }

  // 是否開啟手機驗證
  const phoneSwitch = ref<string>("");
  async function getPhoneSwitch() {
    const m_user_c = CookieMUC();
    const data = {
      m_user_c,
    };
    const res = await postPhoneSwitch(data);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    phoneSwitch.value = res.dataList.nType;
  }

  interface PhoneFormValues {
    sPhone: string;
  }

  // 拿取手機簡訊驗證碼 phone_vscode/send_msg
  const sendmsg = ref<string>("");
  async function getSendMsg(data: PhoneFormValues) {
    const { sPhone } = data;
    const m_user_c = CookieMUC();
    const postData = {
      m_user_c,
      sPhone,
    };
    const res = await postSendMsg(postData);
    switch (res.type) {
      case "0":
        errorAlert.AddErrorAlert(t("Store.Login.SendMsgSuccess"));
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  interface MemberFormValues {
    sAccount: string;
    sPasswd: string;
    sPasswd2: string;
    sRecommend: string;
    sLineId: string;
  }

  // 註冊
  const registerMsg = ref<string>("");
  async function register(data: MemberFormValues) {
    const { sAccount, sPasswd, sPasswd2, sRecommend, sLineId } = data;
    const m_user_c = CookieMUC();
    const postData = {
      m_user_c,
      sAccount,
      sPasswd,
      sPasswd2,
      sLineId,
      sRecommend: sRecommend ? sRecommend : "",
      // nPid: "",
    };
    const res = await postMemberRegister(postData);
    switch (res.type) {
      case "0": // 成功則進入主頁
        openLoginAlert();
        router.push({ name: "main" });
        break;
      default: // 失敗給提示
        errorAlert.AddErrorAlert(res.msg);
        // failRegister(res.msg)
        break;
    }
  }

  // 手機註冊
  async function Phoneregister(data: FormValues) {
    const {
      sAccount,
      sPasswd,
      sPasswd2,
      sRecommend,
      sPhone,
      sPhone_Vscode,
      sLineId,
      nShare,
    } = data;
    const m_user_c = CookieMUC();
    const postData = {
      m_user_c,
      sAccount,
      sPasswd,
      sPasswd2,
      sPhone: phoneSwitch.value == "0" ? sPhone : "",
      sPhone_Vscode: phoneSwitch.value == "0" ? sPhone_Vscode : "",
      sRecommend: sRecommend ? sRecommend : "",
      sLineId,
      nShare,
      // nPid: "",
    };
    const res = await postMemberPhoneRegister(postData);
    switch (res.type) {
      case "0": // 成功則進入主頁
        openLoginAlert();
        router.push({ name: "main" });
        break;
      default: // 失敗給提示
        errorAlert.AddErrorAlert(res.msg);
        // failRegister(res.msg)
        break;
    }
  }

  interface ShareFormValues {
    sAccount: string;
    sPasswd: string;
    sPasswd2: string;
    sShareAccount: string;
    sPhone: string;
    sPhone_Vscode: string;
  }

  // 會員註冊(分享發財用)
  async function ShareRegister(data: ShareFormValues) {
    const {
      sAccount,
      sPasswd,
      sPasswd2,
      sShareAccount,
      sPhone,
      sPhone_Vscode,
    } = data;
    const m_user_c = CookieMUC();
    const postData = {
      m_user_c,
      sAccount,
      sPasswd,
      sPasswd2,
      sPhone: phoneSwitch.value == "0" ? sPhone : "",
      sPhone_Vscode: phoneSwitch.value == "0" ? sPhone_Vscode : "",
      sShareAccount,
    };
    const res = await postShareRegister(postData);
    switch (res.type) {
      case "0": // 成功則進入主頁
        openLoginAlert();
        router.push({ name: "main" });
        break;
      default: // 失敗給提示
        errorAlert.AddErrorAlert(res.msg);
        // failRegister(res.msg)
        break;
    }
  }
  // function failRegister(msg: string) {
  //   registerMsg.value = msg;
  // }

  // 開戶協議
  const isAgreeRegisterPage = ref<boolean>(false);
  function openAgreeRegisterPage() {
    isAgreeRegisterPage.value = true;
  }
  function closeAgreeRegisterPage() {
    isAgreeRegisterPage.value = false;
  }

  return {
    detectBrowser,
    ShareRegister,
    getPhoneSwitch,
    getSendMsg,
    Phoneregister,
    phoneSwitch,
    userSum,
    memberData,
    isZeroVipLevel,
    isCashMember,
    isLogin,
    PageMode,
    selectMode,
    PromoCode,
    Account,
    Share,
    toLoginPage,
    isLoginAlert,
    openLoginAlert,
    closeLoginAlert,
    login,
    logout,
    CheckMUC,
    CookieMUC,
    savePassword,
    keepPassword,
    keepAccount,
    getKeepPassword,
    register,
    registerMsg,
    Vscode,
    getVscode,
    loginMsg,
    isAgreeRegisterPage,
    openAgreeRegisterPage,
    closeAgreeRegisterPage,
    isLoadingLogin,
    saveJump,
    CookieJump,
    getMoneyBack,
  };
});
