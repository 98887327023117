<template>
  <div class="sticky top-[10%] w-[95vw] md:w-[600px] mx-auto">
    <div
      class="relative bg-[url('@image/memberFunction/08/detail_board.png')] bg-100 h-[500px] md:h-[80dvh]"
    >
      <h3
        class="absolute top-[30px] md:top-[60px] left-1/2 -translate-x-1/2 text-center text-secondary text-[20px] font-bold"
      >
        {{ $t("MemberFunction.AddBank.UploadExample") }}
      </h3>
      <div
        class="px-[1em] w-[80vw] md:w-[500px] h-[350px] md:h-[55dvh] relative top-[20%] left-1/2 -translate-x-1/2 bg-100 text-primary flex flex-col text-[14px] overflow-y-auto"
      >
        <p class="text-green-600 text-[16px] font-bold">
          {{ $t("MemberFunction.AddBank.CorrectExample") }}
        </p>

        <img
          src="@image/memberFunction/18/07.png"
          alt=""
          class="w-full mt-[1dvh]"
        />

        <p class="py-[12px] text-[14px] text-primary">
          1.雙證件(身分證正面)+存摺+白紙寫上僅供MYT娛樂城使用+日期 拍再一起<br />
          2.雙證件(身分證反面)+存摺+白紙寫上僅供MYT娛樂城使用+日期 拍再一起
        </p>
        <p class="py-[12px] text-[14px] text-primary">
          請維持文件完整性，不能切角或切邊/反光/模糊不清/遮住個人資料
        </p>
        <p class="text-red-600 text-[16px] font-bold">
          {{ $t("MemberFunction.AddBank.ErrorExample") }}
        </p>
        <img
          src="@image/memberFunction/18/06.png"
          alt=""
          class="w-full mt-[1dvh]"
        />
        <p class="py-[12px] text-[14px] text-primary">
          綁定數位帳戶需提供數位帳戶資訊(一張)、銀行卡正面(一張)，共兩張。
          提供上傳圖片時，請注意以下幾點:<span class="text-red-600">
            銀行卡須提供有銀行帳號那面。</span
          >
        </p>
        <div
          class="absolute left-1/2 -translate-x-1/2 bottom-[2rem] flex justify-center items-center w-full"
        ></div>
      </div>
    </div>
  </div>
</template>
