<script setup lang="ts">
import { onMounted, onBeforeMount } from "vue"
import { useRouter, useRoute } from "vue-router"

import MainWeb from '@/components/mainWeb.vue';

const router = useRouter();
const route = useRoute();

onBeforeMount(async () => {
  if (route.path === "/") {
    router.push({name: 'main'})
  }
})
</script>

<template>
    <MainWeb />
</template>
