<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import PromotionRegisterPage from "@/components/login/PromotionRegisterPage.vue";
import { useMemberService } from "@/stores/memberFunction/memberService";
import MemberServiceContent from "@/components/menberFunction/memberServiceContent.vue";
const memberService = useMemberService();
const router = useRouter();

// 1: 註冊
const Mode = ref<number>(1);

function toMain() {
  router.push({ name: "main" });
}
</script>

<template>
  <div
    class="flex flex-col h-[100dvh] bg-cover bg-left bg-[url('@image/memberFunction/01/bg_01.png')] items-center justify-start lg:justify-center"
  >
    <button @click="toMain" class="mt-[1em] lg:mt-0">
      <p>logo</p>
    </button>
    <div class="flex justify-between items-center my-[12px]">
      <img
        class="w-[100px]"
        src="@image/memberFunction/07/join-us_arrow_left.png"
        alt=""
      />
      <p class="text-primary mx-[16px]">JOIN US</p>
      <img
        class="w-[100px]"
        src="@image/memberFunction/07/join-us_arrow_right.png"
        alt=""
      />
    </div>
    <div class="mt-[12px] mb-[24px]">
      <div class="relative">
        <p
          class="text-[#342814] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold drop-shadow-md"
        >
          註冊
        </p>
        <img
          src="@image/memberFunction/07/login_up_btn.png"
          alt=""
          :class="Mode === 1 ? 'opacity-1' : 'opacity-0'"
          class="w-[140px]"
        />
      </div>
    </div>
    <PromotionRegisterPage />
  </div>

  <!-- web -->
  <div class="hidden lg:block absolute right-[2em] bottom-[2em] z-20">
    <div class="relative" v-if="memberService.isShowService">
      <MemberServiceContent />
    </div>
    <button
      class="absolute right-[-2em] bottom-[-2em] w-[50px] h-[50px]"
      @click="memberService.ToggleService"
    >
      <img
        src="@image/memberFunction/11_web/cs_csbtn.png"
        alt=""
        class="w-full h-full"
      />
    </button>
  </div>

  <!-- app -->
  <div
    class="lg:hidden absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 z-50 w-full flex justify-center items-center"
  >
    <div class="relative" v-if="memberService.isShowService">
      <MemberServiceContent />
      <button
        class="absolute right-[0em] bottom-[-4em] w-[50px] h-[50px] z-30"
        @click="memberService.ToggleService"
      >
        <img
          src="@image/memberFunction/04/cs_icon_s.png"
          alt=""
          class="w-full h-full"
        />
      </button>
    </div>
  </div>
  <div
    class="lg:hidden absolute top-0 left-0 bg-black/50 w-full h-[100dvh] z-40"
    v-if="memberService.isShowService"
  ></div>
</template>

<style></style>
