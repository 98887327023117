import { ref, computed, watchEffect } from "vue";
import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useLoadingPage } from "@/stores/loadingPage";
import { useLogin } from "@/stores/login";
import { getMUserC } from "@/assets/API/API";

export const useMaintain = defineStore("Maintain", () => {
  const { t } = useI18n();
  const router = useRouter();
  const LoadingPage = useLoadingPage();
  const login = useLogin();
  const MaintainMsg = ref<string>("");

  async function ToMaintain() {
    const res = await getMUserC();
    login.saveJump();
    if (isMaintain(res)) return;
    router.push({ name: "home" });
  }

  const isMaintain = (data: { sMsg?: string; nMaintenance?: number }) => {
    const { nMaintenance, sMsg } = data;
    const getMsg = sMsg ? sMsg : t("Store.Maintain.InMaintenance");
    const jump = login.CookieJump();
    if (!nMaintenance) return false;
    if (jump) return false;
    if (nMaintenance === 1) {
      MaintainMsg.value = getMsg;
      router.push({ name: "maintain" });
      LoadingPage.closeLoadingPage();
      return true;
    } else {
      return false;
    }
  };
  return {
    ToMaintain,
    isMaintain,
    MaintainMsg,
  };
});
