<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useContentInfo } from "@/stores/contentInfo";
const router = useRouter();
const contentInfoStore = useContentInfo();

const { AppQrCode } = storeToRefs(contentInfoStore);
function toAPPDownload() {
  router.push({ name: "APPDownload" });
}
const BuildName = computed(() => {
  const mode = import.meta.env.MODE;
  let name = "";
  switch (mode) {
    case "TTBtest":
    case "TTB":
      name = "TTB娛樂城";
      break;
    case "MT":
    case "MTtest":
      name = "搖錢樹娛樂城";
      break;
    case "MMtest":
    case "MM":
      name = "Ming娛樂城";
      break;
    case "ICVtest":
    case "ICV":
      name = "ICV國際";
      break;
    case "ICVCNtest":
    case "ICVCN":
      name = "ICV國際";
      break;
    case "white03test":
      name = "ICV國際";
      break;
    case "N12":
      name = "昊陽娛樂";
      break;
    case "sinhao":
      name = "鑫濠娛樂";
      break;
    default:
      name = "ICV國際";
      break;
  }
  return name;
});
</script>

<template>
  <div class="py-[10vh]">
    <div class="flex items-center justify-center">
      <img
        src="@image/memberFunction/02_web/APP_download_title.png"
        alt=""
        class="w-[80%]" />
    </div>
    <div class="grid grid-cols-12 py-[5vh]">
      <div class="col-span-7">
        <img src="@image/memberFunction/02_web/app_mobile.png" alt="" />
      </div>
      <div class="col-span-4 flex flex-col justify-between items-center">
        <img src="@image/memberFunction/02_web/appdownload_word.png" alt="" />
        <p class="text-tertiary leading-[4vh]">
          {{ BuildName }}原生APP支援所有移動端,提供體育、電子、
          真人、彩票、棋牌遊戲,業內盡顯流暢、完美操作的
          娛樂城APP掃碼即可下載,即刻擁有。
        </p>
        <div class="flex justify-between items-center w-full">
          <div class="flex justify-center items-center">
            <div class="relative w-full">
              <img
                src="@image/memberFunction/02_web/qrcode_bg.png"
                alt=""
                class="w-[80%]" />
              <img
                :src="AppQrCode"
                alt=""
                class="w-[60%] absolute top-1/2 -translate-y-1/2 left-[40%] -translate-x-1/2" />
            </div>
          </div>
          <div class="flex justify-center items-center relative">
            <img
              src="@image/memberFunction/02_web/app_teach_bg.png"
              alt=""
              class="w-[80%]" />
            <div
              class="absolute flex flex-col justify-center h-full left-1/2 -translate-x-1/2">
              <button
                type="button"
                class="w-[120%] relative -translate-x-1/2 left-1/2"
                @click="toAPPDownload">
                <img
                  src="@image/memberFunction/02_web/app_andr_btn.png"
                  alt="" />
              </button>
              <button
                type="button"
                class="mt-[1vh] w-[120%] relative -translate-x-1/2 left-1/2"
                @click="toAPPDownload">
                <img
                  src="@image/memberFunction/02_web/app_ios_btn.png"
                  alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
