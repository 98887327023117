<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import DefaultImage from "@image/memberFunction/12/verify_box.png";
const { t } = useI18n();

const fileInput = ref<HTMLInputElement | null>(null);
const hasImage = ref<boolean>(false);
const defaultImage = ref<string>(DefaultImage);

async function handleFileUpload(event: Event) {
  const target = event.target as HTMLInputElement;
  const file = (target.files as FileList)[0];
  if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target) return (defaultImage.value = "");
      if (!e.target.result) return (defaultImage.value = "");
      defaultImage.value = e.target.result.toString(); // 設置圖片的data URL
    };
    reader.readAsDataURL(file); // 讀取文件
    hasImage.value = true;
  } else {
    defaultImage.value = DefaultImage;
    alert(t("MemberFunction.DepositSelectCorrectImage"));
  }
}

const clearInput = () => {
  if (!fileInput.value) return;
  fileInput.value.value = "";
  defaultImage.value = DefaultImage;
  hasImage.value = false;
};
</script>

<template>
  <div class="flex flex-col justify-center items-center py-[2em] px-[1em]">
    <div class="relative">
      <div
        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-20"
        v-show="!hasImage"
      >
        <img
          src="@image/memberFunction/12/new_file.png"
          alt=""
          class="mb-[12px] w-[40px]"
        />
        <p class="text-[12px] text-primary">
          {{ $t("MemberFunction.Select") }}
          <label for="uploadFile" class="underline underline-offset-1">
            {{ $t("MemberFunction.AddFile") }}
            <input
              ref="fileInput"
              id="uploadFile"
              type="file"
              class="hidden"
              accept=".png, .jpg, .jpeg"
              @change="handleFileUpload"
            />
          </label>
          {{ $t("MemberFunction.UploadVerified") }}
        </p>
      </div>
      <div class="mt-[12px] relative overflow-hidden rounded-lg">
        <img
          src="@image/memberFunction/common/verify_border.png"
          alt=""
          class="relative z-10"
        />
        <img
          :src="defaultImage"
          alt=""
          class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-black"
        />
      </div>
    </div>
    <div class="flex justify-around items-center w-full mt-[12px]">
      <button class="relative mr-[12px] w-[80px]" @click="clearInput">
        <img src="@image/memberFunction/common/btn_n.png" alt="" class="" />
        <p
          class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-primary text-[14px]"
        >
          {{ $t("MemberFunction.Cancel") }}
        </p>
      </button>
      <button class="relative w-[80px]">
        <img src="@image/memberFunction/common/btn_n.png" alt="" class="" />
        <p
          class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-primary text-[14px]"
        >
          {{ $t("MemberFunction.Upload") }}
        </p>
      </button>
    </div>
  </div>
</template>
