<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useCounterStore } from "@/stores/counter";
import { useContentInfo } from "@/stores/contentInfo";
import { onMounted } from "vue";
import * as APItypes from "@/assets/API/APItype";

import ContentSwiperWeb from "@/components/content/contextSwiperWeb.vue";

import DiscountDefaultImg from "@image/memberFunction/03_web/Discount_default.jpg";

const contentInfo = useContentInfo();
const store = useCounterStore();
const { Sale, SaleList, SaleTypesList, SaleBanner } = storeToRefs(contentInfo);

// 選擇列表
function SelectType(id: string) {
  contentInfo.SelectType(id);
}

// 選擇優惠詳細
const openContentDiscountAlertAlert = (item: APItypes.SaleData) => {
  contentInfo.SelectDiscount(item);
  store.toggleAlertBg();
  store.openContentDiscountAlertAlert();
};

// 判斷優惠類別
const DiscountType = (id: string) => {
  const SaleTitle = Sale.value[id].sTitle0;
  return SaleTitle ? SaleTitle : "";
};

onMounted(() => {
  contentInfo.toGetSale();
});
</script>
<template>
  <div class="text-primary">
    <!-- <div>
      <img :src="SaleBanner" alt="" class="w-full" />
    </div> -->
    <!-- <ContentSwiperWeb /> -->
    <div
      class="grid grid-cols-12 gap-x-[3vw] gap-y-[1dvh] max-w-[1366px] mx-auto px-[2vw] pb-[10dvh] pt-[5dvh]"
    >
      <div class="relative col-span-3">
        <img
          src="@image/memberFunction/03_web/discount_nav_bg.png"
          alt=""
          class="w-full"
        />
        <ul class="absolute top-[10dvh] left-[50%] -translate-x-1/2 w-full">
          <li
            v-for="type in SaleTypesList"
            :key="type.id + type.title + 'DiscountListType'"
          >
            <button
              type="button"
              class="group relative w-full pl-[3.5vw] py-[2.5dvh]"
              @click="SelectType(type.id)"
            >
              <div
                class="h-full w-[calc(100%)] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                :class="{
                  'hidden group-hover:block': !type.isSelect,
                  block: type.isSelect,
                }"
              >
                <img
                  src="@image/memberFunction/01/L_btn_p.png"
                  alt=""
                  class="w-full h-[110%]"
                />
              </div>
              <div
                class="relative z-10 w-full h-full flex justify-start items-center"
              >
                <img
                  :src="type.sIconOff"
                  alt=""
                  class="mr-[2vw] w-[20px] h-[20px]"
                  :class="{
                    'hidden group-hover:block': !type.isSelect,
                    block: type.isSelect,
                  }"
                />
                <img
                  :src="type.sIconOn"
                  alt=""
                  class="mr-[2vw] w-[20px] h-[20px]"
                  :class="{
                    'block group-hover:hidden': !type.isSelect,
                    ' hidden': type.isSelect,
                  }"
                />
                <p
                  class="font-bold text-[16px] xl:text-[18px]"
                  :class="{
                    'text-yellow-100 group-hover:text-secondary':
                      !type.isSelect,
                    ' text-secondary': type.isSelect,
                  }"
                >
                  {{ type.title }}
                </p>
              </div>
            </button>
          </li>
        </ul>
      </div>
      <ul class="col-span-9">
        <li
          class="mt-5 first:mt-0 relative"
          v-for="item in SaleList"
          :key="item.nBkid"
        >
          <button @click="openContentDiscountAlertAlert(item)">
            <div class="overflow-hidden relative w-full z-10">
              <img
                class="absolute bottom-[3px] left-1/2 -translate-x-1/2 h-[85%] w-[calc(100%-6px)] object-cover xl:rounded-b-[27px] rounded-b-[20px]"
                :src="item.img_url_pc ? item.img_url_pc : DiscountDefaultImg"
                alt=""
              />
              <img
                src="@image/memberFunction/03_web/discount.png"
                alt=""
                class="w-full h-full"
              />
              <div
                class="absolute top-[4%] left-[1vw] flex justify-center items-center"
              >
                <img
                  src="@image/memberFunction/03_web/全部_icon.png"
                  alt=""
                  class="w-[16px] mr-[.5vw]"
                />
                <p class="text-yellow-100 font-bold">
                  {{ item.sTitle0 }}
                </p>
              </div>
            </div>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
