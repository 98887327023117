<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
const memberService = useMemberService();
const store = useCounterStore();
const { TradeRecordAlertContent } = storeToRefs(memberService);

function closeTradeRecord() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}
</script>

<template>
  <div
    class="sticky top-1/2 md:top-[40%] -translate-y-1/2 w-[95vw] md:w-[600px] mx-auto"
  >
    <div
      class="relative bg-[url('@image/memberFunction/08/detail_board.png')] bg-100"
    >
      <h3
        class="absolute top-[20px] md:top-[24px] left-1/2 -translate-x-1/2 text-center text-secondary text-[20px] md:text-[24px] font-bold"
      >
        {{ $t("MemberFunction.TradeRecord.TradeRecord") }}
      </h3>
      <div
        class="p-[3em] w-full md:w-[600px] h-full top-0 bg-100 text-primary flex flex-col text-[14px] md:text-[20px]"
      >
        <!-- <div class="grid grid-cols-8 mt-[50px]">
          <p class="mr-[1em] col-span-3">會員帳號</p>
          <p class="col-span-5">{{ TradeRecordAlertContent.nUid }}</p>
        </div> -->
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.TradeRecord.Type") }}
          </p>
          <p class="col-span-5">
            {{
              TradeRecordAlertContent.sType === "game"
                ? $t("MemberFunction.TradeRecord.GameType")
                : TradeRecordAlertContent.sType === "money"
                ? $t("MemberFunction.TradeRecord.TransactionType")
                : $t("MemberFunction.TradeRecord.Undefined")
            }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.TradeRecord.List") }}
          </p>
          <p class="col-span-5">{{ TradeRecordAlertContent.sShow }}</p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.TradeRecord.Amount") }}
          </p>
          <p class="col-span-5">
            {{ store.getClearPotAndAddCommas(TradeRecordAlertContent.nDelta) }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.TradeRecord.Balance") }}
          </p>
          <p class="col-span-5">
            {{ store.getClearPotAndAddCommas(TradeRecordAlertContent.nAfter) }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.TradeRecord.Time") }}
          </p>
          <p class="col-span-5">{{ TradeRecordAlertContent.nCreateTime }}</p>
        </div>
        <div class="flex justify-around mt-[24px]">
          <button
            class="btn-y-border bg-100 px-[1em] py-[.2em] text-secondary"
            @click="closeTradeRecord"
          >
            {{ $t("MemberFunction.TradeRecord.ClosePage") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bgLinearMailBox {
  background: linear-gradient(45deg, #2c343e, #576879);
  border-style: solid;
  border-image: linear-gradient(45deg, #563d2a, #edbe7b) 1;
  border-image-slice: 1 1 1 1;
}
.bg-linear-mail-box-title {
  background: linear-gradient(#624380, #472161);
}
.bg-100 {
  background-size: 100% 100%;
}
</style>
