<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";
const memberService = useMemberService();

const { DepositStoreList } = storeToRefs(memberService);

const emit = defineEmits(["changeDepositPage"]);
function changeDepositPage(page: number) {
  emit("changeDepositPage", page);
}
</script>

<template>
  <div
    class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[16px] md:mt-[3dvh] w-full"
  >
    <p class="mr-[1em] col-span-4">
      {{ $t("MemberFunction.Deposit.BindStore") }}
    </p>
  </div>

  <div class="grid grid-cols-2 gap-[12px] w-full mt-[2dvh]">
    <template v-for="storeIndex in 2" :key="'DepositStoreList' + storeIndex">
      <button
        type="button"
        class="select-store-border min-h-[100px] p-[6px] text-[14px] flex justify-center items-center"
        v-if="DepositStoreList[storeIndex - 1]"
      >
        <p class="text-start">
          {{ $t("MemberFunction.Deposit.StoreType") }}:
          {{ DepositStoreList[storeIndex - 1].sStore }}<br />
          {{ $t("MemberFunction.Deposit.InCity") }}:
          {{ DepositStoreList[storeIndex - 1].sCounty }}<br />
          {{ $t("MemberFunction.Deposit.StoreName") }}:
          {{ DepositStoreList[storeIndex - 1].sShop }}<br />
          {{ $t("MemberFunction.Deposit.StoreCode") }}:
          {{ DepositStoreList[storeIndex - 1].sCode }}<br />
          審核狀態:
          {{
            DepositStoreList[storeIndex - 1].nStatus === "0"
              ? "未審核"
              : "審核通過"
          }}<br />
        </p>
      </button>
      <button
        type="button"
        class="add-store-border min-h-[100px] py-[6px] text-[24px] font-bold"
        @click="changeDepositPage(98)"
        v-else
      >
        +
      </button>
    </template>
  </div>
</template>
<style>
.add-store-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/11/pankcard01_bg.png") 1;
  -webkit-border-image: url("@image/memberFunction/11/pankcard01_bg.png") 1;
  -o-border-image: url("@image/memberFunction/11/pankcard01_bg.png") 1;
  border-image: url("@image/memberFunction/11/pankcard01_bg.png") 1 fill;
}
.select-store-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/12/verify_box.png") 1;
  -webkit-border-image: url("@image/memberFunction/12/verify_box.png") 1;
  -o-border-image: url("@image/memberFunction/12/verify_box.png") 1;
  border-image: url("@image/memberFunction/12/verify_box.png") 1 fill;
}
</style>
