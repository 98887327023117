<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useContentInfo } from "@/stores/contentInfo";
import { useBgAudio } from "@/stores/bgAudio";
const bgAudio = useBgAudio();
const contentInfo = useContentInfo();
const { BgMusic } = storeToRefs(contentInfo);
// 背景音樂
function changeMusicState() {
  bgAudio.changeMusicState();
}
</script>
<template>
  <button class="relative" @click="changeMusicState" v-if="BgMusic">
    <img
      src="@image/memberFunction/bg_audio/on_bg.png"
      class="object-contain h-[3dvh]"
      alt=""
      v-if="bgAudio.musicState"
    />
    <img
      src="@image/memberFunction/bg_audio/off_bg.png"
      class="object-contain h-[3dvh]"
      alt=""
      v-else
    />
    <div
      class="absolute top-1/2 -translate-y-1/2 z-10 h-[2.3dvh] w-full px-[1vw]"
    >
      <Transition
        enter-active-class="delay-[300ms]"
        enter-from-class="transform opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-300 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="transform opacity-100 translate-x-[50%]"
      >
        <div class="w-full h-full absolute" v-if="!bgAudio.musicState">
          <img
            src="@image/memberFunction/bg_audio/btn.png"
            class="object-contain h-full absolute md:-left-1/4 left-0"
            alt=""
          />
        </div>
      </Transition>
      <Transition
        enter-active-class="delay-[300ms]"
        enter-from-class="transform opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-300 "
        leave-from-class="opacity-100  translate-x-0"
        leave-to-class="transform opacity-100 translate-x-[-50%]"
      >
        <div class="w-full h-full top-0" v-if="bgAudio.musicState">
          <img
            src="@image/memberFunction/bg_audio/btn.png"
            class="object-contain h-full absolute left-1/2"
            alt=""
          />
        </div>
      </Transition>
    </div>
  </button>
</template>
