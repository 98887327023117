<script setup lang="ts"></script>
<template>
  <div
    class="bg-black/50 min-h-[100dvh] w-full flex justify-center items-center">
    <img
      src="@image/memberFunction/01/loading_GIF.gif"
      alt=""
      class="w-[50%] md:w-auto" />
  </div>
</template>
