<script setup lang="ts">
import { useRouter } from "vue-router";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import { useI18n } from "vue-i18n";
const router = useRouter();
const memberService = useMemberService();
const store = useCounterStore();
const { t } = useI18n();

function closeMailBoxAlert() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}
</script>

<template>
  <div
    class="fixed top-[30%] -translate-y-1/2 left-1/2 -translate-x-1/2 w-[95vw] md:w-auto"
  >
    <div class="relative">
      <img
        class="w-[400px] mx-auto"
        src="@image/memberFunction/common/unbindCard.png"
        alt=""
      />
      <h3
        class="absolute text-center text-[20px] text-secondary font-bold top-7 left-1/2 -translate-x-1/2"
      >
        {{ $t("MemberFunction.Withdraw.Tip") }}
      </h3>
      <div
        class="absolute flex items-center justify-center w-[100%] mb-[12px] mt-[18px] text-[18px] top-[40%] text-primary font-bold -translate-y-1/2"
      >
        <p
          style="white-space: pre-line"
          class="text-center"
          v-html="memberService.WithdrawPageAlertContent"
        ></p>
      </div>
      <div class="absolute top-2/4 left-1/2 -translate-x-1/2">
        <div class="flex justify-around mt-[2em] relative">
          <img
            class="w-[110px]"
            src="@image/memberFunction/common/btn_bb.png"
            alt=""
          />
          <button
            class="absolute px-[1em] py-[.5em] top-1/2 -translate-y-1/2 text-primary"
            @click="closeMailBoxAlert"
          >
            {{ $t("MemberFunction.Withdraw.ClosePage") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
