<script setup lang="ts">
import { ref, computed, toValue } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useErrorAlert } from "@/stores/errorAlert";
import type * as APItypes from "@/assets/API/APItype";
import { min_value } from "@vee-validate/rules";

interface MemberCompanyDepositFormData {
  nPay: string;
  nMoney: string;
  sMemo: string | undefined;
}

const { t } = useI18n();
const memberService = useMemberService();
const errorAlert = useErrorAlert();
const { MemberCompanyDeposit, MemberCompanyDepositMsg } =
  storeToRefs(memberService);

const MemberCompanyDepositForm = ref<any>(null);

function getCompanyDepositAccount(nPay: string) {
  if (!nPay) return "";
  if (!MemberCompanyDeposit.value) return "";
  const Data = MemberCompanyDeposit.value.find((item) => {
    return item.nId === nPay;
  });
  return Data?.nAccount;
}

function getCompanyDepositName(nPay: string) {
  if (!nPay) return "";
  if (!MemberCompanyDeposit.value) return "";
  const Data = MemberCompanyDeposit.value.find((item) => {
    return item.nId === nPay;
  });
  return Data?.sPayee;
}

function getCompanyDepositNo(nPay: string) {
  if (!nPay) return "";
  if (!MemberCompanyDeposit.value) return "";
  const Data = MemberCompanyDeposit.value.find((item) => {
    return item.nId === nPay;
  });
  return Data?.sBank;
}

async function submit(
  memberCompanyDepositFormData: MemberCompanyDepositFormData
) {
  const { nPay, nMoney, sMemo } = memberCompanyDepositFormData;
  const postData = {
    nPay,
    nMoney,
    sMemo: sMemo ? sMemo : "",
  };
  await memberService.getMemberCompanyDeposit(postData);
  MemberCompanyDepositForm.value.resetForm();
}

// 複製儲值帳號
async function copyBtn(url: string | undefined) {
  if (!url) return;
  // await navigator.clipboard.writeText(url);
  const textArea: HTMLInputElement = document.createElement("input");
  textArea.value = url;
  document.body.appendChild(textArea);
  textArea.select();
  // 执行复制命令并移除文本框
  document.execCommand("copy")
    ? errorAlert.AddErrorAlert(t("MemberFunction.Deposit.CopyCompleted"))
    : errorAlert.AddErrorAlert("複製失敗");

  document.body.removeChild(textArea);
}
</script>

<template>
  <VForm
    @submit="submit"
    v-slot="{ values, isSubmitting }"
    ref="MemberCompanyDepositForm"
    class="py-[2em] px-[9vw]"
  >
    <div class="text-primary">
      <label
        for="nPay"
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px]"
      >
        <p class="mr-[1em] text-primary col-span-4 flex items-center">
          {{ $t("MemberFunction.Deposit.BankDeposit") }}
        </p>
        <div class="flex flex-col col-span-8">
          <div class="relative inline-block">
            <div class="h-[26px] w-full">
              <img
                src="@image/memberFunction/common/triangle_icon.png"
                alt=""
                class="h-[12px] absolute right-[2.5%] md:right-[3.4%] top-1/2 -translate-y-1/2 z-20"
              />
            </div>
            <div>
              <VField
                :label="$t('MemberFunction.Deposit.BankDeposit')"
                name="nPay"
                v-slot="{ field }"
                :rules="{ required: true }"
                class="relative"
              >
                <select
                  v-bind="field"
                  class="absolute select-bg-border top-0 bg-transparent px-2 rounded w-full h-[26px] text-primary"
                >
                  <option class="text-black" value="">
                    {{ $t("MemberFunction.Deposit.SelectBank") }}
                  </option>
                  <option
                    class="text-black"
                    :value="item.nId"
                    v-for="item in MemberCompanyDeposit"
                    :key="item.nId + 'CompanyDeposit'"
                  >
                    {{ item.bank_name }}
                  </option>
                </select>
              </VField>
            </div>
            <ErrorMessage
              name="nPay"
              class="text-red-400 text-[14px] text-center absolute"
            />
          </div>
        </div>
      </label>
      <label
        for="CompanyDepositAccount"
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]"
      >
        <p class="mr-[1em] col-span-4 flex items-center">
          {{ $t("MemberFunction.Deposit.DepositAccount") }}
        </p>
        <div class="col-span-8 flex justify-start items-center">
          <p class="mr-[2vw]">{{ getCompanyDepositAccount(values.nPay) }}</p>
          <button
            type="button"
            class="btn-n-border"
            @click="copyBtn(getCompanyDepositAccount(values.nPay)?.toString())"
            v-if="values.nPay"
          >
            <p
              class="w-[100%] text-center text-[12px] md:text-[14px] px-[2em] py-[.1em]"
            >
              {{ $t("MemberFunction.Deposit.Copy") }}
            </p>
          </button>
        </div>
      </label>
      <label
        for="CompanyDepositName"
        class="grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh] hidden"
      >
        <p class="mr-[1em] col-span-4 flex items-center">
          {{ $t("MemberFunction.Deposit.DepositName") }}
        </p>
        <p class="col-span-8">{{ getCompanyDepositName(values.nPay) }}</p>
      </label>
      <label
        for="CompanyDepositNo"
        class="grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh] hidden"
      >
        <p class="mr-[1em] col-span-4 flex items-center">
          {{ $t("MemberFunction.Deposit.DepositBank") }}
        </p>
        <p class="col-span-8">{{ getCompanyDepositNo(values.nPay) }}</p>
      </label>
      <label
        for="nMoney"
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]"
      >
        <p class="mr-[1em] col-span-4 flex items-center">
          {{ $t("MemberFunction.Deposit.DepositAmount") }}
        </p>
        <div class="relative inline-block col-span-8">
          <div class="h-[26px]">
            <img
              src="@image/memberFunction/文字框/文字框_長_A.png"
              alt=""
              class="h-[26px] w-full"
            />
          </div>
          <VField
            class="col-span-1 absolute h-[26px] top-0 rounded-md bg-transparent w-full px-2"
            :label="$t('MemberFunction.Deposit.DepositAmountLabel')"
            name="nMoney"
            type="number"
            min="1"
            :rules="{ required: true, min_value: 1 }"
          />

          <ErrorMessage
            name="nMoney"
            class="text-red-400 text-[14px] text-center"
          />
        </div>
      </label>
      <label
        for="sMemo"
        class="span-col-2 row-span-2 grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]"
      >
        <p class="mr-[1em] col-span-4 flex items-center">
          {{ $t("MemberFunction.Deposit.DepositIdLastFiveLabel") }}
        </p>
        <div class="relative inline-block col-span-8">
          <div class="h-[26px]">
            <img
              src="@image/memberFunction/文字框/文字框_長_A.png"
              alt=""
              class="h-[26px] w-full"
            />
          </div>
          <VField
            class="col-span-1 absolute h-[26px] top-0 rounded-md bg-transparent w-full px-2"
            :label="$t('MemberFunction.Deposit.DepositIdLastFive')"
            name="sMemo"
            type="text"
            :rules="{ required: true, regex: '^[0-9]{5}$' }"
          />
          <ErrorMessage
            name="sMemo"
            class="text-red-400 text-[14px] text-center"
          />
        </div>
      </label>
      <!-- <label for="phone" class="flex items-center text-[14px] mt-[12px]  md:mt-[3dvh]">
        <p class="mr-[1em]">二維碼</p>
        <button type="button" class="relative mr-[12px] w-[120px]">
          <img
            src="@image/memberFunction/common/btn_n.png"
            alt=""
            class="h-[30px] w-full"
          />
          <p
            class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[10px]"
          >
            二維碼產生
          </p>
        </button>
      </label> -->
    </div>
    <!-- <p class="text-red-400 text-center my-[12px]">
      {{ MemberCompanyDepositMsg }}
    </p> -->
    <div class="mt-[3dvh] md:static flex flex-col justify-center items-center">
      <button
        type="submit"
        class="btn-y-border"
        :class="{ submitting: isSubmitting }"
        :disabled="isSubmitting"
      >
        <p
          class="text-[14px] md:text-[14px] font-bold text-secondary px-[2em] py-[.1em]"
        >
          {{ isSubmitting ? "" : $t("MemberFunction.Deposit.Next") }}
        </p>
      </button>
      <div class="mt-[3dvh]">
        <div class="flex justify-center items-center leading-[36px]">
          <img
            src="@image/memberFunction/11/驚嘆號icon.png"
            alt=""
            class="w-[16px] mr-1"
          />
          <span class="text-red-600">
            {{ $t("MemberFunction.Deposit.Notice") }}
          </span>
        </div>
        <div class="text-primary text-[14px] md:text-[16px] leading-[20px]">
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.CompanyNoticeList1") }}
            </p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.CompanyNoticeList2") }}
            </p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.CompanyNoticeList3") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </VForm>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
