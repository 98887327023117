<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { useCounterStore } from "@/stores/counter";
import { useMemberService } from "@/stores/memberFunction/memberService";
import * as APItypes from "@/assets/API/APItype";
const store = useCounterStore();
const memberService = useMemberService();

const { MemberUSDTDeposit, MemberUSDTPlatform } = storeToRefs(memberService);

interface USDTFormData {
  nPay: string;
  nMoney: number;
  sInputAddress: string;
}
const OUDTForm = ref<any>(null);
const USDTType = ref<string>("");
const OUDTPay = ref<string>("0");
const OUDTCountPay = ref<number>(NaN);

const ChoosePlatform = computed(() => {
  const choosePlatform = MemberUSDTPlatform.value.find(
    (item: APItypes.MemberUSDTDepositPagePlatform) => {
      return item.nId === USDTType.value;
    }
  );
  if (!choosePlatform) return null;
  return choosePlatform;
});

const OUDTStopmoney = computed(() => {
  if (!ChoosePlatform.value) return "0";
  return ChoosePlatform.value.nStopmoney;
});
const OUDTMin = computed(() => {
  if (!ChoosePlatform.value) return "0";
  return ChoosePlatform.value.nMin;
});
const OUDTMax = computed(() => {
  if (!ChoosePlatform.value) return "0";
  return ChoosePlatform.value.nMax;
});
// const OUDTAddress = computed(() => {
//   if (!ChoosePlatform.value) return "";
//   return ChoosePlatform.value.sAddress;
// });

const OUDTExchangeRate = computed(() => {
  if (JSON.stringify(MemberUSDTDeposit.value) === "{}") return 0;
  return MemberUSDTDeposit.value.nUsdtExchangeRate;
});

watch(
  () => OUDTCountPay.value,
  () => {
    if (OUDTCountPay.value) {
      OUDTPay.value = store.getClearPot(
        OUDTCountPay.value / OUDTExchangeRate.value,
        3
      );
    } else {
      OUDTPay.value = "0";
    }
  }
);
function CountPayChange() {
  if (OUDTCountPay.value > parseInt(OUDTMax.value)) {
    OUDTCountPay.value = parseInt(OUDTMax.value);
  }
  if (OUDTCountPay.value < parseInt(OUDTMin.value)) {
    OUDTCountPay.value = parseInt(OUDTMin.value);
  }
}

const countList = ref<number[]>([1000, 5000, 10000, 50000]);
function chooseCount(num: number) {
  OUDTCountPay.value = num;
  CountPayChange();
}

async function submit(form: USDTFormData) {
  await memberService.getMemberUSDTDeposit(form);
}
</script>

<template>
  <VForm
    @submit="submit"
    v-slot="{ meta, isSubmitting }"
    ref="OUDTForm"
    class="py-[2em] px-[9vw]"
  >
    <div class="text-primary">
      <label
        for="nPay"
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px]"
      >
        <p class="mr-[1em] text-primary col-span-4">
          {{ $t("MemberFunction.Deposit.USDTDepositMethod") }}
        </p>

        <div class="relative col-span-8">
          <div class="h-[26px] w-full">
            <img
              src="@image/memberFunction/common/triangle_icon.png"
              alt=""
              class="h-[12px] absolute right-[2.5%] md:right-[3.4%] top-1/2 -translate-y-1/2 z-20"
            />
          </div>
          <VField
            id="nPay"
            :label="$t('MemberFunction.Deposit.USDTDepositMethod')"
            name="nPay"
            v-slot="{ field }"
            v-model="USDTType"
            class="relative w-full"
          >
            <select
              v-bind="field"
              class="absolute select-bg-border top-0 bg-transparent px-2 rounded w-full h-[26px] text-primary"
            >
              <option class="text-black" value="">
                {{ $t("MemberFunction.Deposit.SelectUSDTDepositMethod") }}
              </option>
              <option
                class="text-black"
                v-for="value in MemberUSDTPlatform"
                :key="value + 'onlineDepositOther'"
                :value="value.nId"
              >
                {{ value.sTypeName + "-" + value.sProtocol }}
              </option>
            </select>
          </VField>
        </div>
      </label>
      <label
        for="nMoney"
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]"
      >
        <p class="mr-[1em] col-span-4">
          {{ $t("MemberFunction.Deposit.USDTDepositAmount") }}
        </p>
        <div class="relative inline-block col-span-8">
          <div class="h-[26px]">
            <img
              src="@image/memberFunction/文字框/文字框_長_A.png"
              alt=""
              class="h-[26px] w-full"
            />
          </div>
          <VField
            class="col-span-1 absolute h-[26px] top-0 rounded-md bg-transparent w-full px-2"
            id="nMoney"
            label="入款金額"
            name="nMoney"
            v-model="OUDTCountPay"
            type="number"
            min="1"
            :max="OUDTStopmoney"
            @blur="CountPayChange"
            :placeholder="`${$t(
              'MemberFunction.Deposit.USDTDepositAmount'
            )} ${OUDTMin} ~ ${OUDTMax}`"
            :rules="{ required: true, min_value: 1, max_value: OUDTStopmoney }"
          />
        </div>
      </label>
      <label
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]"
      >
        <p class="mr-[1em] col-span-4">
          {{ $t("MemberFunction.Deposit.USDTExpectedPayment") }}
        </p>
        <div class="relative inline-block col-span-8">
          <div class="h-[26px]">
            <img
              src="@image/memberFunction/文字框/文字框_長_A.png"
              alt=""
              class="h-[26px] w-full"
            />
          </div>
          <input
            type="string"
            v-model="OUDTPay"
            disabled
            class="col-span-1 absolute h-[26px] top-0 rounded-md bg-transparent w-full px-2 text-yellow-300"
          />
          <p
            class="absolute top-1/2 -translate-y-1/2 left-[5em] text-primary/50"
          >
            {{ $t("MemberFunction.Deposit.USDT") }}
          </p>
        </div>
      </label>
      <label
        for="sInputAddress"
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]"
      >
        <p class="mr-[1em] col-span-4">
          {{ $t("MemberFunction.Deposit.WalletAddress") }}
        </p>
        <div class="relative inline-block col-span-8">
          <div class="h-[52px]">
            <img
              src="@image/memberFunction/文字框/文字框_長_A.png"
              alt=""
              class="h-[52px] w-full"
            />
          </div>
          <VField
            class="col-span-1 absolute h-[26px] top-0 rounded-md bg-transparent w-full px-2"
            id="sInputAddress"
            :label="$t('MemberFunction.Deposit.MemberWalletAddress')"
            name="sInputAddress"
            type="text"
            min="1"
            :max="OUDTStopmoney"
            :rules="{ required: true }"
            v-slot="{ field }"
          >
            <textarea
              :placeholder="
                $t('MemberFunction.Deposit.MemberWalletAddressPlaceholder')
              "
              v-bind="field"
              class="rounded-md text-primary resize-none w-full absolute top-0 bg-transparent px-2 discount-scrollbar"
            ></textarea>
          </VField>
        </div>
      </label>
      <!-- <label
        for="OUDT_address"
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]"
      >
        <p class="mr-[1em] col-span-4">轉帳錢包地址</p>
        <div class="relative inline-block col-span-8">
          <div class="h-[52px]">
            <img
              src="@image/memberFunction/文字框/文字框_長_A.png"
              alt=""
              class="h-[52px] w-full"
            />
          </div>

          <textarea
            v-model="OUDTAddress"
            disabled
            class="rounded-md text-primary resize-none w-full absolute top-0 bg-transparent px-2 discount-scrollbar"
          ></textarea>
        </div>
      </label> -->
    </div>
    <ul class="flex justify-between items-center mt-[3dvh]">
      <li
        v-for="count in countList"
        :key="'USDTCountList' + count"
        class="mr-[3px] w-full"
      >
        <button
          type="button"
          class="btn-y-border w-full inline-block px-1"
          @click="chooseCount(count)"
        >
          <p class="text-[14px] md:text-[14px] font-bold text-secondary">
            {{ store.addCommas(count.toString()) }}
          </p>
        </button>
      </li>
      <li class="w-full">
        <button
          type="button"
          class="btn-y-border w-full inline-block"
          @click="chooseCount(NaN)"
        >
          <p class="text-[14px] md:text-[14px] font-bold text-secondary">
            {{ $t("MemberFunction.Deposit.Clear") }}
          </p>
        </button>
      </li>
    </ul>
    <div class="mt-[3dvh] md:static flex flex-col justify-center items-center">
      <button
        type="submit"
        class="btn-y-border"
        :disabled="!meta.valid || isSubmitting"
        :class="{ submitting: isSubmitting }"
      >
        <p
          class="text-[14px] md:text-[14px] font-bold text-secondary px-[2em] py-[.1em]"
        >
          {{ isSubmitting ? "" : "下一步" }}
        </p>
      </button>
      <div class="mt-[3dvh]">
        <div class="flex justify-center items-center leading-[36px]">
          <img
            src="@image/memberFunction/11/驚嘆號icon.png"
            alt=""
            class="w-[16px] mr-1"
          />
          <span class="text-red-600">
            {{ $t("MemberFunction.Deposit.Notice") }}
          </span>
        </div>
        <div class="text-primary text-[14px] md:text-[16px] leading-[20px]">
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.CurrentExchangeRate") }}1USDT=
              <span class="text-red-600">{{ OUDTExchangeRate }}</span
              >TWD。
            </p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>{{ $t("MemberFunction.Deposit.SubmitUSDTNoticeList1") }}</p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>{{ $t("MemberFunction.Deposit.SubmitUSDTNoticeList2") }}</p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.SubmitUSDTNoticeList3") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </VForm>
</template>
