<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref } from "vue";
import { RouterView, useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import ErrorAlert from "./components/errorAlert.vue";
import LoadingPage from "@/components/content/loadingPage.vue";
import MemberServiceContent from "@/components/menberFunction/memberServiceContent.vue";

import { useLoadingPage } from "@/stores/loadingPage";
import { useBgAudio } from "@/stores/bgAudio";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useGameIFrame } from "@/stores/gameIFrame";

import faviconImage from "@image/favicon.ico";
import { useLogin } from "./stores/login";

const route = useRoute();
const login = useLogin();
const loadingPage = useLoadingPage();
const bgAudio = useBgAudio();
const memberService = useMemberService();
const gameIFrame = useGameIFrame();
login.detectBrowser();
onMounted(() => {
  // bgAudio.BgAudioMounted();
  gameIFrame.checkSelfInIframe();
  gameIFrame.IFrameMessageOn();
  changeFavicon();
  changeTitle();
});
onBeforeUnmount(() => {
  gameIFrame.IFrameMessageOff();
});
const changeFavicon = () => {
  let faviconEle: HTMLLinkElement | null =
    document.querySelector('link[rel="icon"]');
  if (faviconEle !== null) {
    faviconEle.href = faviconImage;
  } else {
    faviconEle = document.createElement("link");
    faviconEle.rel = "icon";
    faviconEle.href = faviconImage;
    document.head.appendChild(faviconEle);
  }
};
const changeTitle = () => {
  let titleEle: HTMLTitleElement | null = document.querySelector("title");
  const titleText =
    import.meta.env.MODE === "TTBtest" || import.meta.env.MODE === "TTB"
      ? "TTB 娛樂"
      : import.meta.env.MODE === "MTtest" || import.meta.env.MODE === "MT"
      ? "搖錢樹 娛樂"
      : import.meta.env.MODE === "MMtest" || import.meta.env.MODE === "MM"
      ? "Ming 娛樂"
      : import.meta.env.MODE === "ICVtest" || import.meta.env.MODE === "ICV"
      ? "ICV 國際"
      : import.meta.env.MODE === "ICVCNtest" || import.meta.env.MODE === "ICVCN"
      ? "ICV 國際"
      : import.meta.env.MODE === "N12"
      ? "昊陽娛樂"
      : import.meta.env.MODE === "sinhao"
      ? "鑫濠娛樂"
      : import.meta.env.MODE === "white03test"
      ? "ICV 國際"
      : "ICV 國際";
  if (titleEle !== null) {
    titleEle.innerHTML = titleText;
  } else {
    titleEle = document.createElement("title");
    titleEle.innerHTML = titleText;
    document.head.appendChild(titleEle);
  }
};
</script>

<template>
  <div class="relative text-primary">
    <LoadingPage
      v-show="loadingPage.isLoadingPage"
      class="fixed top-0 z-[998]" />
    <ErrorAlert />
    <RouterView />

    <!-- 客服 -->
    <div
      class="fixed right-[5%] md:right-[2rem] top-[10%] md:top-[unset] bottom-[unset] md:bottom-[30%] z-50">
      <div class="relative" v-if="memberService.isShowService">
        <MemberServiceContent />
      </div>
      <!-- web -->
      <button
        type="button"
        class="hidden md:block absolute right-[-2em] bottom-[-2em] w-[50px] h-[50px]"
        @click="memberService.ToggleService(false)"
        v-if="route.name !== 'maintain'">
        <img src="@image/memberFunction/11_web/cs_csbtn.png" alt="" class="" />
      </button>
      <!-- mobile -->
      <button
        class="block md:hidden absolute right-[0em] bottom-[-4em] w-[50px] h-[50px] z-30"
        @click="memberService.ToggleService(true)"
        v-if="memberService.isShowService">
        <img
          src="@image/memberFunction/04/cs_icon_s.png"
          alt=""
          class="w-full h-full" />
      </button>
    </div>
    <div
      class="block md:hidden absolute top-0 left-0 bg-black/50 w-full h-[100dvh] z-40"
      v-if="memberService.isShowService"></div>
  </div>
</template>

<style scoped>
body {
  background-color: black;
}
</style>
