<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useContentInfo } from "@/stores/contentInfo";

const contentInfoStore = useContentInfo();

const { slidesTipState } = storeToRefs(contentInfoStore);
</script>

<template>
  <div class="absolute z-[50] right-0">
    <div class="relative flex w-full top-[-2vh]">
      <img
        src="@image/memberFunction/文字框/Arrow_for_left.gif"
        class="h-[24px] object-contain mr-[4vw]"
        :class="{
          'opacity-0': slidesTipState === 'none' || slidesTipState === 'left',
        }"
        alt=""
      />
      <img
        src="@image/memberFunction/文字框/Arrow_for_right.gif"
        class="h-[24px] object-contain relative mr-[2vw]"
        :class="{
          'opacity-0': slidesTipState === 'none' || slidesTipState === 'right',
        }"
        alt=""
      />
    </div>
  </div>
</template>
