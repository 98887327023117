<script setup lang="ts">
import { ref, reactive } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useCounterStore } from "@/stores/counter";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useTimeListLogic } from "@/stores/timeListLogic";
import DefaultImage from "@image/memberFunction/11/pankcard01_bg.png";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";
const { t } = useI18n();

const store = useCounterStore();
const memberService = useMemberService();
const timeListLogic = useTimeListLogic();
const { openBankVerified } = storeToRefs(memberService);
interface VerifyRealNameValue {
  sRealName: string;
  sBirthday: string;
  sIdCard: string;
  aFile0: Blob;
  aFile1: Blob;
}

const IdFrontImageInput = ref<HTMLInputElement | null>(null);
const IdBackImageInput = ref<HTMLInputElement | null>(null);
const eighteenYearsAgo = new Date();
eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
const BirthdayDefault = ref<string>(timeListLogic.DateString(eighteenYearsAgo));

const Images = reactive({
  defaultIdFrontImage: DefaultImage,
  defaultIdBackImage: DefaultImage,
});
const ImagesState = reactive({
  IdFrontImageState: false,
  IdBackImageState: false,
});
async function handleFileUpload(event: Event) {
  const target = event.target as HTMLInputElement;
  const file = (target.files as FileList)[0];
  if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
    const reader = new FileReader();

    reader.onload = async (e) => {
      switch (target.id) {
        case "IdFront":
          if (!e.target) return (Images.defaultIdFrontImage = "");
          if (!e.target.result) return (Images.defaultIdFrontImage = "");
          Images.defaultIdFrontImage = e.target.result.toString();
          ImagesState.IdFrontImageState = true;
          break;
        case "IdBack":
          if (!e.target) return (Images.defaultIdBackImage = "");
          if (!e.target.result) return (Images.defaultIdBackImage = "");
          Images.defaultIdBackImage = e.target.result.toString();
          ImagesState.IdBackImageState = true;
          break;

        default:
          break;
      }
    };
    reader.readAsDataURL(file); // 讀取文件
  } else {
    switch (target.id) {
      case "IdFront":
        Images.defaultIdFrontImage = "";
        ImagesState.IdFrontImageState = false;
        break;
      case "IdBack":
        Images.defaultIdBackImage = "";
        ImagesState.IdBackImageState = false;
        break;

      default:
        break;
    }
    alert("請選擇有效的PNG或JPG圖像文件！");
  }
}

const Msg = ref<string>("");
function submitVerifyRealName(FromValue: VerifyRealNameValue) {
  Msg.value = "";
  if (!IdFrontImageInput.value)
    return (Msg.value = t("MemberFunction.BankVerified.CheckIdCardFront"));
  if (!IdBackImageInput.value)
    return (Msg.value = t("MemberFunction.BankVerified.CheckIdCardBack"));
  if (!IdFrontImageInput.value.files)
    return (Msg.value = t("MemberFunction.BankVerified.CheckIdCardFront"));
  if (!IdBackImageInput.value.files)
    return (Msg.value = t("MemberFunction.BankVerified.CheckIdCardBack"));
  if (!IdFrontImageInput.value.files[0])
    return (Msg.value = t("MemberFunction.BankVerified.CheckIdCardFront"));
  if (!IdBackImageInput.value.files[0])
    return (Msg.value = t("MemberFunction.BankVerified.CheckIdCardBack"));

  const { sRealName, sBirthday, sIdCard } = FromValue;
  const data = {
    sRealName,
    sBirthday,
    sIdCard,
    aFile0: IdFrontImageInput.value.files[0] as Blob,
    aFile1: IdBackImageInput.value.files[0] as Blob,
  };
  memberService.submitVerifyRealName(data);
}

const clearInput = (type: string) => {
  switch (type) {
    case "IdFront":
      if (!IdFrontImageInput.value) return;

      IdFrontImageInput.value.value = "";
      Images.defaultIdFrontImage = DefaultImage;
      ImagesState.IdFrontImageState = false;
      break;
    case "IdBack":
      if (!IdBackImageInput.value) return;

      IdBackImageInput.value.value = "";
      Images.defaultIdBackImage = DefaultImage;
      ImagesState.IdBackImageState = false;
      break;

    default:
      break;
  }
};

function openBankVerifiedAlert() {
  store.toggleAlertBg();
  store.openBankVerifiedAlert();
}
</script>
<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-[1vw] pb-[3rem] pt-0 md:pt-[3rem]"
  >
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9 relative">
      <div
        class="px-0 py-0 md:px-[5vw] md:py-[2dvh] relative z-10 web-gold-border rounded-[24px]"
      >
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.BankVerified.Verified") }}
        </p>
        <div>
          <div
            v-if="!openBankVerified"
            class="absolute top-1/2 left-1/2 submitting"
          ></div>
          <div
            class="member-bg-border mt-[1vh] text-primary py-[1em] px-[8vw] md:px-[1vw] mx-[1em] rounded-[6px] pb-[80px]"
            v-else
          >
            <p
              class="text-[16px] md:text-[20px] ml-[2vw] font-bold relative left-[-2vw] before:absolute before:h-[60%] before:-translate-y-1/2 before:top-1/2 before:left-[-2vw] before:w-[3px] before:bg-yellow-100"
            >
              {{ $t("MemberFunction.BankVerified.BasicInfo") }}
            </p>
            <VForm @submit="submitVerifyRealName">
              <div>
                <label
                  for="sRealName"
                  class="grid grid-cols-8 text-[14px] md:text-[18px] mt-[3vh]"
                >
                  <p class="mr-[1em] col-span-3">
                    {{ $t("MemberFunction.BankVerified.MemberName") }}
                  </p>
                  <div class="col-span-5">
                    <VField
                      class="bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md px-[10px] w-full"
                      name="sRealName"
                      :label="$t('MemberFunction.BankVerified.MemberName')"
                      type="text"
                      :placeholder="
                        $t('MemberFunction.BankVerified.MemberNamePlaceholder')
                      "
                      :rules="{ required: true }"
                    />
                    <ErrorMessage
                      name="sRealName"
                      class="text-red-600 md:ml-3 ml-0 text-[12px]"
                    />
                  </div>
                </label>
                <label
                  for="sBirthday"
                  class="grid grid-cols-8 text-[14px] md:text-[18px] mt-[16px] md:mt-[2vh]"
                >
                  <p class="mr-[1em] col-span-3">
                    {{ $t("MemberFunction.BankVerified.Birthday") }}
                  </p>
                  <div class="col-span-5">
                    <VField
                      class="bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md pl-[10px] w-full search-date-label relative"
                      name="sBirthday"
                      :label="$t('MemberFunction.BankVerified.Birthday')"
                      type="date"
                      v-model="BirthdayDefault"
                      :rules="{ required: true }"
                    />
                    <ErrorMessage
                      name="sBirthday"
                      class="text-red-600 md:ml-3 ml-0 text-[12px]"
                    />
                  </div>
                </label>
                <label
                  for="sIdCard"
                  class="grid grid-cols-8 text-[14px] md:text-[18px] mt-[16px] md:mt-[2vh]"
                >
                  <p class="mr-[1em] col-span-3">
                    {{ $t("MemberFunction.BankVerified.IdCardNumber") }}
                  </p>
                  <div class="col-span-5">
                    <VField
                      class="bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md px-[10px] w-full"
                      name="sIdCard"
                      :label="$t('MemberFunction.BankVerified.IdCardNumber')"
                      type="text"
                      placeholder=""
                      :rules="{ required: true }"
                    />
                    <ErrorMessage
                      name="sIdCard"
                      class="text-red-600 md:ml-3 ml-0 text-[12px]"
                    />
                  </div>
                </label>
              </div>

              <p
                class="text-[16px] md:text-[20px] ml-[2vw] mt-[5dvh] font-bold relative left-[-2vw] before:absolute before:h-[60%] before:-translate-y-1/2 before:top-1/2 before:left-[-2vw] before:w-[3px] before:bg-yellow-100"
              >
                {{ $t("MemberFunction.BankVerified.UploadVerified") }}
              </p>
              <p class="text-red-600 text-center text-[14px]">
                {{ Msg }}
              </p>
              <!-- 身分證照片 -->
              <div class="grid grid-cols-8 gap-y-[2dvh] mt-[1dvh]">
                <p
                  class="text-[14px] md:text-[18px] my-[1dvh] col-span-8"
                  v-html="$t('MemberFunction.BankVerified.IdCardFront')"
                />
                <div class="relative bg-black col-span-8 rounded-[12px]">
                  <button
                    class="absolute top-[6px] right-[6px] bg-white/50 text-secondary rounded-full z-20"
                    @click="clearInput('IdFront')"
                    v-if="ImagesState.IdFrontImageState"
                  >
                    <p class="relative -translate-y-[4px] w-[20px] h-[20px]">
                      x
                    </p>
                  </button>
                  <label
                    for="IdFront"
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 w-full h-full"
                    v-show="!ImagesState.IdFrontImageState"
                  >
                    <p
                      class="text-primary text-[30px] text-center flex justify-center items-center h-full"
                    >
                      +
                    </p>
                    <input
                      ref="IdFrontImageInput"
                      id="IdFront"
                      type="file"
                      class="hidden"
                      accept=".png, .jpg, .jpeg"
                      @change="handleFileUpload"
                    />
                  </label>
                  <div class="relative overflow-hidden rounded-[6px]">
                    <img
                      src="@image/memberFunction/11/pankcard01_bg.png"
                      alt=""
                      class="relative"
                    />
                    <img
                      :src="Images.defaultIdFrontImage"
                      alt=""
                      class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-10"
                    />
                  </div>
                </div>
                <p
                  class="text-[14px] md:text-[18px] my-[1dvh] col-span-8"
                  v-html="$t('MemberFunction.BankVerified.IdCardBack')"
                />
                <div class="relative bg-black col-span-8 rounded-[12px]">
                  <button
                    class="absolute top-[6px] right-[6px] bg-white/50 text-secondary rounded-full z-20"
                    @click="clearInput('IdBack')"
                    v-if="ImagesState.IdBackImageState"
                  >
                    <p class="relative -translate-y-[4px] w-[20px] h-[20px]">
                      x
                    </p>
                  </button>
                  <label
                    for="IdBack"
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 w-full h-full"
                    v-show="!ImagesState.IdBackImageState"
                  >
                    <p
                      class="text-primary text-[30px] text-center flex justify-center items-center h-full"
                    >
                      +
                    </p>
                    <input
                      ref="IdBackImageInput"
                      id="IdBack"
                      type="file"
                      class="hidden"
                      accept=".png, .jpg, .jpeg"
                      @change="handleFileUpload"
                    />
                  </label>
                  <div class="relative overflow-hidden rounded-[6px]">
                    <img
                      src="@image/memberFunction/11/pankcard01_bg.png"
                      alt=""
                      class="relative z-10"
                    />
                    <img
                      :src="Images.defaultIdBackImage"
                      alt=""
                      class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-10"
                    />
                  </div>
                </div>
              </div>

              <div
                class="col-span-3 flex justify-around mt-[12px] md:mt-[3dvh]"
              >
                <button
                  type="button"
                  class="btn-y-border w-[100px] px-[em] py-[.1em]"
                  @click="openBankVerifiedAlert"
                >
                  <p class="text-[12px] md:text-[16px] text-secondary">
                    {{ $t("MemberFunction.BankVerified.ViewExample") }}
                  </p>
                </button>
                <button
                  class="btn-y-border w-[100px] px-[em] py-[.1em]"
                  type="submit"
                >
                  <p class="text-[12px] md:text-[16px] text-secondary">
                    {{ $t("MemberFunction.BankVerified.Submit") }}
                  </p>
                </button>
              </div>

              <div class="col-span-3">
                <div class="flex justify-center items-center mt-[12px]">
                  <img
                    src="@image/memberFunction/11/驚嘆號icon.png"
                    alt=""
                    class="w-[14px]"
                  />
                  <p
                    class="text-red-600 text-center text-[16px] md:text-[20px] font-bold"
                  >
                    {{ $t("MemberFunction.BankVerified.Notice") }}
                  </p>
                </div>
                <div
                  class="leading-[26px] md:leading-[32px] text-primary text-[12px] md:text-[18px] mt-[12px]"
                >
                  <p>{{ $t("MemberFunction.BankVerified.NoticeTitle") }}</p>
                  <p>
                    {{ $t("MemberFunction.BankVerified.NoticeList1") }}
                  </p>
                  <p>{{ $t("MemberFunction.BankVerified.NoticeList2") }}</p>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
