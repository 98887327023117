<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useLogin } from "@/stores/login";
import { useCounterStore } from "@/stores/counter";
import { useMemberService } from "@/stores/memberFunction/memberService";
import * as APItypes from "@/assets/API/APItype";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";
import VipLevel from "@/components/menberFunction/VIPPrivilege/VipLevel.vue";

import VerifyState from "@/components/menberFunction/memberAccount/memberAccountVerifyState.vue";

import VIPIconImage1 from "@image/memberFunction/common/VIP1.png";
import VIPIconImage2 from "@image/memberFunction/common/VIP2.png";
import VIPIconImage3 from "@image/memberFunction/common/VIP3.png";
import VIPIconImage4 from "@image/memberFunction/common/VIP4.png";
import VIPIconImage5 from "@image/memberFunction/common/VIP5.png";

import VIPCardImage1 from "@image/memberFunction/16/card1.png";
import VIPCardImage2 from "@image/memberFunction/16/card2.png";
import VIPCardImage3 from "@image/memberFunction/16/card3.png";
import VIPCardImage4 from "@image/memberFunction/16/card4.png";
import VIPCardImage5 from "@image/memberFunction/16/card5.png";

const login = useLogin();
const store = useCounterStore();
const memberService = useMemberService();
const { memberData, isCashMember } = storeToRefs(login);
const { VipListSort, changepwdMsg } = storeToRefs(memberService);

// 會員VIP
const memberVIPType = computed(() => {
  const type = VipListSort.value.find((item) => {
    return memberData.value.aVipData.nVipLv === item.nVipLv;
  });
  return type ? type : ({} as APItypes.VipListList);
});
const getVIPIconImage = (level: string) => {
  let image;
  switch (level) {
    case "0":
    case "1":
      image = VIPIconImage1;
      break;
    case "2":
      image = VIPIconImage2;
      break;
    case "3":
      image = VIPIconImage3;
      break;
    case "4":
      image = VIPIconImage4;
      break;
    case "5":
      image = VIPIconImage5;
      break;
    default:
      image = "";
      break;
  }
  return image;
};
const getVIPCardImage = (level: string) => {
  let image;
  switch (level) {
    case "1":
      image = VIPCardImage1;
      break;
    case "2":
      image = VIPCardImage2;
      break;
    case "3":
      image = VIPCardImage3;
      break;
    case "4":
      image = VIPCardImage4;
      break;
    case "5":
      image = VIPCardImage5;
      break;
    default:
      image = VIPCardImage5;
      break;
  }
  return image;
};

// 修改登入密碼
function openChangePasswordAlert() {
  store.toggleAlertBg();
  store.openChangePasswordAlert();
  changepwdMsg.value = "";
}

// 修改交易密碼
function openChangeTradePasswordAlert() {
  if (!login.isCashMember) return;
  store.toggleAlertBg();
  store.openChangeTradePasswordAlert();
  changepwdMsg.value = "";
}

const memberPhoneHideCenter = computed(() => {
  const phoneNumber = memberData.value.user_phone;
  const textLength = phoneNumber.length;
  if (textLength < 3) {
    return phoneNumber;
  }
  const middleIndex = Math.floor(textLength / 2);
  const start = middleIndex - 1;
  const newPhoneNumber =
    phoneNumber.slice(0, start) + "***" + phoneNumber.slice(start + 3);
  return newPhoneNumber;
});
</script>

<template>
  <div
    class="grid grid-cols-12 gap-x-[3vw] px-[1vw] pb-[10dvh] pt-[5dvh] md:pt-[3rem]">
    <SidebarWeb class="col-span-3" />
    <div class="col-span-9 inline-block">
      <div class="relative web-gold-border">
        <div class="relative p-[3vw]">
          <p class="text-primary text-[20px] font-bold">
            {{ $t("MemberFunction.MemberAccount.Information")
            }}<span class="text-[14px] ml-[1dvh]">{{
              $t("MemberFunction.MemberAccount.InformationTip")
            }}</span>
          </p>
          <div class="grid grid-cols-2 text-primary mt-[2dvh]">
            <div>
              <p
                class="text-[18px] ml-[1vw] font-bold relative before:absolute before:h-[80%] before:-translate-y-1/2 before:top-1/2 before:left-[-1vw] before:w-[3px] before:bg-yellow-100">
                {{ $t("MemberFunction.MemberAccount.BasicInformation") }}
              </p>
              <br />
              <div class="grid grid-cols-6 gap-y-[2dvh] text-[16px]">
                <p class="col-span-2">
                  {{ $t("MemberFunction.MemberAccount.Account") }}
                </p>
                <div class="col-span-4">
                  <span>
                    {{ memberData.sAccount }}
                    {{  login.isCashMember ? '' : '信用' }}
                  </span>
                  <img
                    src="@image/memberFunction/06/認證藍勾.png"
                    alt=""
                    class="inline w-[14px] ml-[2px] mb-[2px]"
                    v-if="memberData.nRank !== '0'" />
                  <img
                    v-if="isCashMember"
                    :src="getVIPIconImage(memberData.aVipData?.nVipLv)"
                    alt=""
                    class="h-[24px] inline ml-[1dvh] relative top-[-3px]" />
                </div>
                <p class="col-span-2">
                  {{ $t("MemberFunction.MemberAccount.Name") }}
                </p>

                <div
                  class="col-span-4 flex items-center justify-between mr-[1vw]">
                  <VerifyState :Info="memberData.sRealName" />
                </div>
                <p class="col-span-2">
                  {{ $t("MemberFunction.MemberAccount.Birthday") }}
                </p>
                <div
                  class="col-span-4 flex items-center justify-between mr-[1vw]">
                  <VerifyState :Info="memberData.sBirthday" />
                </div>
                <p class="col-span-2">
                  {{ $t("MemberFunction.MemberAccount.IdNumber") }}
                </p>
                <div
                  class="col-span-4 flex items-center justify-between mr-[1vw]">
                  <VerifyState :Info="memberData.sIdCard" />
                </div>
                <p class="col-span-2">
                  {{ $t("MemberFunction.MemberAccount.WalletBalance") }}
                </p>
                <p class="col-span-4">
                  {{
                    store.getClearPotAndAddCommas(memberData.nMoney).toString()
                  }}
                </p>
                <p class="col-span-2">
                  {{ $t("MemberFunction.MemberAccount.PhoneNumber") }}
                </p>
                <p class="col-span-4">{{ memberPhoneHideCenter }}</p>
                <p class="col-span-2">
                  {{ $t("MemberFunction.MemberAccount.Password") }}
                </p>
                <div class="col-span-4">
                  <button
                    type="button"
                    @click="openChangePasswordAlert"
                    class="btn-y-border">
                    <p class="text-secondary text-[14px] px-[2em] py-[.1em]">
                      {{ $t("MemberFunction.MemberAccount.Modify") }}
                    </p>
                  </button>
                </div>
                <p class="col-span-2">
                  {{ $t("MemberFunction.MemberAccount.TradePassword") }}
                </p>
                <div class="col-span-4">
                  <button
                    type="button"
                    @click="openChangeTradePasswordAlert"
                    class="btn-y-border">
                    <p class="text-secondary text-[14px] px-[2em] py-[.1em]">
                      {{ $t("MemberFunction.MemberAccount.Modify") }}
                    </p>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="isCashMember">
              <VipLevel />
              <div
                class="relative mt-[3dvh]"
                :data-vip-lv="memberVIPType.nVipLv">
                <img
                  :src="getVIPCardImage(memberVIPType.nVipLv)"
                  alt=""
                  class="" />
                <p
                  class="text-primary text-[18px] absolute top-[12px] right-[12px]">
                  {{ memberVIPType.sVipName }}
                </p>
                <div
                  class="absolute bottom-1 grid grid-cols-2 w-full text-[14px] text-primary">
                  <div class="text-center">
                    <p class="text-[18px] font-bold">
                      {{ store.addCommas(memberVIPType.nVipBet) }}
                    </p>
                    <p>
                      {{
                        $t("MemberFunction.MemberAccount.TurnoverRequirement")
                      }}
                    </p>
                  </div>
                  <div class="text-center">
                    <p class="text-[18px] font-bold">
                      {{ store.addCommas(memberVIPType.nVipBet) }}
                    </p>
                    <p>{{ $t("MemberFunction.MemberAccount.SaveTurnover") }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
