<script setup lang="ts">
import { ref, computed, watchEffect } from "vue";
import { storeToRefs } from "pinia";
import { useContentInfo } from "@/stores/contentInfo";
import { useCounterStore } from "@/stores/counter";
import * as APItypes from "@/assets/API/APItype";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css/scrollbar";
import "swiper/css/bundle";
import "swiper/css";

import playFormLayoutImg from "@image/memberFunction/01/game_company_m.png"; // 單數第一張

const contentInfoStore = useContentInfo();
const store = useCounterStore();
const { slidesTipState } = storeToRefs(contentInfoStore);
const { UserAgentWebview } = storeToRefs(store);

const props = defineProps({
  Lists: Array as () => APItypes.IndexInfoGameDataListInfo[],
  Index: Number,
  SlidesIndex: Number,
});
const emit = defineEmits(["checkSlideTip", "changeSlide"]);

const SwiperList = computed(() => {
  if (!props.Lists) return [];
  let GetArr = [];
  for (let i = 0; i < props.Lists.length; i += 3) {
    GetArr.push(props.Lists.slice(i, i + 3));
  }
  return GetArr;
});

const getBgImage = () => {
  let image;
  image = playFormLayoutImg;
  return image;
};
const getCol = (nActive: string) => {
  let isActive = nActive === "0" ? "grayscale" : "";
  let col;
  col = 2;
  return `col-span-${col} ${isActive}`;
};

function goUrl(itemInfo: APItypes.IndexInfoGameDataListInfo) {
  contentInfoStore.goUrl(itemInfo);
}

// 大於6
const SixSwiper = ref<any>(null);
function onSixSwiper(swiper: any) {
  SixSwiper.value = swiper;
}
watchEffect(() => {
  if (props.Index === props.SlidesIndex && props.Lists!.length > 3) {
    changeSlide(SixSwiper.value);
  }
});

function changeSlide(SlideSwiper: any) {
  if (!SlideSwiper) return;
  const totalIndex = SlideSwiper.slides.length;
  const currentIndex = SlideSwiper.activeIndex;
  if (currentIndex === 0) {
    slidesTipState.value = "right";
    return;
  }
  if (currentIndex > 0 && currentIndex + 1 < totalIndex) {
    slidesTipState.value = "both";
    return;
  }
  if (currentIndex + 1 >= totalIndex) {
    slidesTipState.value = "left";
    return;
  }
}
</script>

<template>
  <div
    class="grid grid-cols-2 grid-rows-3 gap-1"
    :class="UserAgentWebview ? 'h-[calc(100%-70px)]' : 'h-[calc(100%-50px)]'">
    <template v-if="props.Lists!.length <= 3">
      <button
        v-for="item in props.Lists"
        @click="goUrl(Object.values(item)[0])"
        class="relative"
        :class="getCol(Object.values(item)[0].nActive)"
        :key="Object.values(item)[0] + 'playFormLayoutOnlyTwo'"
        :disabled="Object.values(item)[0].nActive === '0'">
        <img :src="getBgImage()" alt="" class="h-full w-full" />
        <div class="absolute w-full h-full top-0 m-[6px]">
          <div class="relative h-[100%] w-full">
            <div class="h-[93%] w-full relative overflow-hidden">
              <img
                :src="Object.values(item)[0].sImgUrl"
                alt=""
                class="absolute w-[50%] right-[10%]" />
            </div>
            <div
              class="absolute h-full w-[50%] -top-1 left-0 flex flex-col justify-around items-center text-[14px] text-primary mx-[6px]">
              <div class="flex flex-col justify-center items-center">
                <img
                  :src="Object.values(item)[0].sImgIcon"
                  alt=""
                  class="w-[60px]" />
                <p class="font-bold mt-1">
                  {{ Object.values(item)[0].sTitle0 }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <p
          class="text-primary font-bold shadow-md text-[42px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 absolute"
          v-if="Object.values(item)[0].nActive === '0'">
          {{ $t("Context.InMaintenance") }}
        </p> -->
        <img
          src="@image/memberFunction/common/stamp.png"
          alt=""
          class="absolute z-[999] w-[60px] -translate-x-[0] -translate-y-[100%]"
          v-if="Object.values(item)[0].nActive === '0'" />
        <img
          :src="Object.values(item)[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] right-[-5px] top-0"
          v-if="Object.values(item)[0].nCoverpic === '1'" />
        <img
          :src="Object.values(item)[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] left-[-5px] top-[0px]"
          v-if="Object.values(item)[0].nCoverpic === '2'" />
      </button>
    </template>

    <template v-else-if="props.Lists!.length > 3">
      <div class="relative col-span-2 row-span-3 h-full">
        <Swiper
          :space-between="'3vh'"
          class="h-full w-full col-span-2"
          @swiper="onSixSwiper"
          @slideChange="changeSlide">
          <!-- for 館別 -->
          <SwiperSlide
            v-for="(list, index) in SwiperList"
            :key="index + 'playFormLayoutEvenSwiper'"
            class="h-auto">
            <div class="grid grid-cols-2 grid-rows-3 gap-1 h-full">
              <button
                v-for="item in list"
                @click="goUrl(Object.values(item)[0])"
                class="relative"
                :class="getCol(Object.values(item)[0].nActive)"
                :key="Object.values(item)[0] + 'playFormLayoutOnlyTwo'"
                :disabled="Object.values(item)[0].nActive === '0'">
                <img :src="getBgImage()" alt="" class="h-full w-full" />
                <div class="absolute w-full h-full top-0 m-[6px]">
                  <div class="relative h-[100%] w-full">
                    <div class="h-[93%] w-full relative overflow-hidden">
                      <img
                        :src="Object.values(item)[0].sImgUrl"
                        alt=""
                        class="absolute w-[50%] right-[10%]" />
                    </div>
                    <div
                      class="absolute h-full w-[50%] -top-1 left-0 flex flex-col justify-around items-center text-[14px] text-primary mx-[6px]">
                      <div class="flex flex-col justify-center items-center">
                        <img
                          :src="Object.values(item)[0].sImgIcon"
                          alt=""
                          class="w-[60px]" />
                        <p class="font-bold mt-1">
                          {{ Object.values(item)[0].sTitle0 }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <p
                  class="text-primary font-bold shadow-md text-[42px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 absolute"
                  v-if="Object.values(item)[0].nActive === '0'">
                  {{ $t("Context.InMaintenance") }}
                </p> -->
                <img
                  src="@image/memberFunction/common/stamp.png"
                  alt=""
                  class="absolute z-[999] w-[60px] -translate-x-[0] -translate-y-[100%]"
                  v-if="Object.values(item)[0].nActive === '0'" />
                <img
                  :src="Object.values(item)[0].sCoverImgUrl"
                  alt=""
                  class="absolute z-[999] w-[45px] right-[-5px] top-0"
                  v-if="Object.values(item)[0].nCoverpic === '1'" />
                <img
                  :src="Object.values(item)[0].sCoverImgUrl"
                  alt=""
                  class="absolute z-[999] w-[45px] left-[-5px] top-[0px]"
                  v-if="Object.values(item)[0].nCoverpic === '2'" />
              </button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </template>
  </div>
</template>

<style>
.gamePlatform-scrollbar {
}
.gamePlatform-scrollbar::-webkit-scrollbar {
  /* width: 8px; */
  display: none;
}

/* Track */
.gamePlatform-scrollbar::-webkit-scrollbar-track {
  background: #21252927;
  border-radius: 20px;
}

/* Handle */
.gamePlatform-scrollbar::-webkit-scrollbar-thumb {
  background: #21252969;
  border-radius: 20px;
}

/* Handle on hover */
.gamePlatform-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #212529;
}

.platform-neon-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/01/game_company_s.png");
  -webkit-border-image: url("@image/memberFunction/01/game_company_s.png");
  -o-border-image: url("@image/memberFunction/01/game_company_s.png");
  border-image: url("@image/memberFunction/01/game_company_s.png") 1 fill;
}
</style>
