<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import { useLogin } from "@/stores/login";

const router = useRouter();
const memberService = useMemberService();
const store = useCounterStore();
const login = useLogin();

interface TransferAlertData {
  nId: string;
  money: string;
  pwd: string;
}

const { memberData } = storeToRefs(login);
const { transferPageMsg } = storeToRefs(memberService);
const transferAlert = ref<boolean>(false);
function closeUserList() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}
// 轉帳頁面
function openUserList() {
  transferAlert.value = false;
}
function openTransferAlert() {
  transferAlert.value = true;
}
const transferAlertForm = ref<null | any>(null);
async function submit(formValues: TransferAlertData) {
  await memberService.sendTransferPage(formValues);
  await transferAlertForm.value.resetForm();
}
</script>

<template>
  <div class="sticky top-[40%] -translate-y-1/2 mx-auto w-[95vw] md:w-[600px]">
    <div
      class="relative bg-[url('@image/memberFunction/08/detail_board.png')] bg-100">
      <h3
        class="absolute top-[20px] md:top-[24px] left-1/2 -translate-x-1/2 text-center text-secondary text-[20px] md:text-[24px] font-bold">
        {{ transferAlert ? "轉帳" : "會員資料" }}
      </h3>
      <!-- 會員資料 -->
      <div
        class="p-[3em] w-full md:w-[600px] h-full top-0 bg-100 text-primary flex flex-col text-[14px] md:text-[20px]"
        v-if="transferAlert === false">
        <div class="grid grid-cols-8 items-center mb-[12px] mt-[54px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.MemberList.MemberAccount") }}
          </p>
          <p>
            {{ memberService.UserListAlertContent.sAccount.replace(/\*/g, "") }}
          </p>
        </div>
        <!-- <div class="grid grid-cols-8 items-center mb-[12px] mt-[24px]">
          <p class="mr-[1em] col-span-3">會員名稱</p>
          <p>{{ memberService.UserListAlertContent.sName }}</p>
        </div> -->
        <div class="grid grid-cols-8 items-center mb-[12px] mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.MemberList.MemberIdentity") }}
          </p>
          <p>
            {{
              memberService.UserListAlertContent.nRank === "0"
                ? $t("MemberFunction.MemberList.Member")
                : memberService.UserListAlertContent.nRank === ""
                ? $t("MemberFunction.MemberList.Undefined")
                : $t("MemberFunction.MemberList.Proxy")
            }}
          </p>
        </div>
        <div
          class="grid grid-cols-8 items-center mb-[12px] mt-[24px]"
          v-if="memberService.UserListAlertContent.nRank !== '0'">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.MemberList.MemberRebate") }}
          </p>
          <p>{{ memberService.UserListAlertContent.nPer }}%</p>
        </div>
        <div class="grid grid-cols-8 items-center mb-[12px] mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.MemberList.Amount") }}
          </p>
          <p>
            {{
              store.getClearPotAndAddCommas(
                parseInt(memberService.UserListAlertContent.nMoney).toString()
              )
            }}
          </p>
        </div>
        <div class="flex justify-around mt-[24px]">
          <button
            class="bg-[url('@image/memberFunction/common/btn_n.png')] bg-100 px-[1em] py-[.5em] border-white/50"
            @click="closeUserList">
            {{ $t("MemberFunction.MemberList.ClosePage") }}
          </button>
        </div>
      </div>

      <!-- 轉帳頁面 -->
      <div
        class="p-[3em] w-full xl:w-[40vw] h-full top-0 bg-100 text-primary flex flex-col text-[14px] xl:text-[20px]"
        v-else>
        <VForm
          @submit="submit"
          ref="transferAlertForm"
          v-slot="{ isSubmitting }">
          <div
            class="text-primary grid grid-cols-1 grid-rows-1 lg:grid-rows-7 gap-1 lg:grid-flow-row">
            <label for="nId" class="opacity-0">
              <VField
                class=""
                name="nId"
                label="會員ID"
                type="text"
                disabled
                :rules="{ required: true }"
                :value="memberService.UserListAlertContent.sAccount" />
            </label>
            <div class="grid grid-cols-8 mt-[24px]">
              <p class="col-span-3">
                {{ $t("MemberFunction.MemberList.Account") }}
              </p>
              <p class="col-span-5">
                {{ memberService.UserListAlertContent.sAccount }}
              </p>
            </div>
            <div class="grid grid-cols-8 mt-[20px]">
              <p class="col-span-3">
                {{ $t("MemberFunction.MemberList.Amount") }}
              </p>
              <p class="col-span-5">
                {{ store.getClearPotAndAddCommas(memberData.nMoney) }}
              </p>
            </div>
            <label
              for="money"
              class="grid grid-cols-8 text-[14px] xl:text-[20px] mt-[20px] xl:mt-[1.8em]">
              <p class="col-span-3">
                {{ $t("MemberFunction.MemberList.TransferAmount") }}
              </p>
              <div class="flex flex-col items-end col-span-5">
                <VField
                  class="w-full bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md px-[10px]"
                  name="money"
                  :label="$t('MemberFunction.MemberList.TransferAmount')"
                  type="number"
                  :rules="{ required: true, min_value: 1 }" />
                <ErrorMessage
                  name="money"
                  class="text-red-400 md:ml-3 ml-0 text-[12px] text-center" />
              </div>
            </label>
            <label
              for="pwd"
              class="grid grid-cols-8 text-[14px] xl:text-[20px] mt-[20px] xl:mt-[1.8em]">
              <p class="col-span-3">
                {{ $t("MemberFunction.MemberList.TradePassword") }}
              </p>
              <div class="flex flex-col items-end col-span-5">
                <VField
                  class="w-full bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md px-[10px]"
                  name="pwd"
                  :label="$t('MemberFunction.MemberList.TradePassword')"
                  type="password"
                  :rules="{ required: true }" />
                <ErrorMessage
                  name="pwd"
                  class="text-red-400 md:ml-3 ml-0 text-[12px] text-center" />
              </div>
            </label>

            <p class="text-center text-red-400 mt-[20px]">
              {{ transferPageMsg }}
            </p>
          </div>
          <div class="flex justify-around mt-[1.5em]">
            <button
              class="w-[95px] bg-[url('@image/memberFunction/common/btn_n.png')] bg-100 px-[1em] py-[.5em] border-white/50"
              type="submit">
              {{ $t("MemberFunction.MemberList.Submit") }}
            </button>
            <button
              type="button"
              class="w-[95px] bg-[url('@image/memberFunction/common/btn_n.png')] bg-100 px-[1em] py-[.5em] border-white/50"
              @click="openUserList">
              {{ $t("MemberFunction.MemberList.Return") }}
            </button>
          </div>
        </VForm>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
