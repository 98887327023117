<script setup lang="ts">
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useCounterStore } from "@/stores/counter";
import { useMemberService } from "@/stores/memberFunction/memberService";

const store = useCounterStore();
const memberService = useMemberService();
const { NewJumpContent } = storeToRefs(memberService);

function closeNewsContentAlert() {
  store.closeNewsContentAlert();
}
</script>
<template>
  <div
    class="fixed top-[10%] left-1/2 -translate-x-1/2 w-[95vw] md:w-[500px] mx-auto"
  >
    <div
      class="relative bg-[url('@image/memberFunction/08/detail_board.png')] bg-100 h-[500px] md:h-[53dvh]"
    >
      <h3
        class="absolute top-[30px] md:top-[3.2vh] left-1/2 -translate-x-1/2 text-center text-secondary text-[20px] font-bold"
      >
        {{ $t("Context.NewsAlert.NewsDetails") }}
      </h3>
      <button
        class="absolute top-[30px] md:top-[3.2vh] right-[8%] -translate-x-1/2 text-center text-secondary text-[20px] font-bold"
        @click="closeNewsContentAlert"
        type="button"
      >
        X
      </button>

      <div
        class="w-[83.5vw] md:w-[440px] relative top-[15%] left-1/2 -translate-x-1/2 bg-100 text-primary flex flex-col text-[14px] md:text-[16px]"
      >
        <div class="py-[1vh] px-[2vw] flex flex-col">
          <p>{{ NewJumpContent.sTitle0 }}</p>
          <p class="text-[12px] md:text-[14px]">
            {{ NewJumpContent.sCreateTime }}
          </p>
        </div>
        <img
          src="@image/memberFunction/08/news_line.png"
          alt=""
          class="w-full"
        />
        <div
          class="overflow-y-auto news-scrollbar break-words mt-[1dvh] px-[2vw] h-[320px] md:h-[31.5dvh]"
        >
          <div v-html="NewJumpContent.sContent0"></div>
        </div>
        <!-- <div class="flex justify-center mt-[6px] md:mt-[1dvh]">
          <button
            @click="closeNewsContentAlert"
            type="button"
            class="btn-y-border"
          >
            <p
              class="text-[12px] md:text-[16px] text-secondary px-[2em] py-[.2em]"
            >
              {{ $t("Context.NewsAlert.Close") }}
            </p>
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<style>
.news-scrollbar {
}
.news-scrollbar::-webkit-scrollbar {
  width: 8px;
}
/* Track */
.news-scrollbar::-webkit-scrollbar-track {
  background: #21252927;
  border-radius: 20px;
}

/* Handle */
.news-scrollbar::-webkit-scrollbar-thumb {
  background: #ffffff69;
  border-radius: 20px;
}

/* Handle on hover */
.news-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #212529;
}
</style>
