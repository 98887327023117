<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

function loginBtn() {
  router.push({ name: "login" });
}

function registerBtn() {
  router.push({ name: "login" });
}
</script>

<template>
  <div class="text-primary flex">
    <button
      class="btn-n-border w-[5em] xl:w-[6em] mr-[1em]"
      type="submit"
      @click="loginBtn"
    >
      <p class="text-primary text-[14px] font-bold">
        {{ $t("Header.Login") }}
      </p>
    </button>
    <button class="btn-y-border w-[5em] xl:w-[6em]" @click="registerBtn">
      <p class="text-secondary text-[14px] font-bold py-1">
        {{ $t("Header.Register") }}
      </p>
    </button>
  </div>
</template>
