<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useGameIFrame } from "@/stores/gameIFrame";
import { useBgAudio } from "@/stores/bgAudio";

const router = useRouter();
const gameIFrame = useGameIFrame();
const bgAudio = useBgAudio();
const { gameUrl } = storeToRefs(gameIFrame);

// #region drag board Tools
const toolList = ref<HTMLElement | null>(null);
const checkMove = ref({
  clientX: 0,
  clientY: 0,
});
const drag = ref({
  flags: false,
  position: {
    x: 0,
    y: 0,
  },
  nx: 0,
  ny: 0,
  dx: 0,
  dy: 0,
  xPum: 0,
  yPum: 0,
});

const down = (event: MouseEvent | TouchEvent) => {
  let touches: any =
    event instanceof MouseEvent
      ? event
      : event instanceof TouchEvent
      ? event.touches[0]
      : null;
  drag.value.flags = true;
  drag.value.position.x = checkMove.value.clientX = touches.clientX;
  drag.value.position.y = checkMove.value.clientY = touches.clientY;
  drag.value.dx = toolList.value!.offsetLeft;
  drag.value.dy = toolList.value!.offsetTop;
};
const move = (event: MouseEvent | TouchEvent) => {
  // if (store.isMobile) return;
  let touches: any =
    event instanceof MouseEvent
      ? event
      : event instanceof TouchEvent
      ? event.touches[0]
      : null;
  if (drag.value.flags) {
    drag.value.nx = touches.clientX - drag.value.position.x;
    drag.value.ny = touches.clientY - drag.value.position.y;
    drag.value.xPum = drag.value.dx + drag.value.nx;
    drag.value.yPum = drag.value.dy + drag.value.ny;
    // const width = window.innerWidth - toolList.value!.offsetWidth;
    // const height = window.innerHeight - toolList.value!.offsetHeight;
    // drag.value.xPum < 0 && (drag.value.xPum = 0);
    // drag.value.yPum < 0 && (drag.value.yPum = 0);
    // drag.value.xPum > width && (drag.value.xPum = width);
    // drag.value.yPum > height && (drag.value.yPum = height);
    toolList.value!.style.left = drag.value.xPum + "px";
    toolList.value!.style.top = drag.value.yPum + "px";

    // document.addEventListener(
    //   "touchmove",
    //   () => {
    //     event.preventDefault();
    //   },
    //   false
    // );
  }
};
const end = (event: MouseEvent | TouchEvent) => {
  // if (store.isMobile) return;
  let touches: any =
    event instanceof MouseEvent
      ? event
      : event instanceof TouchEvent
      ? event.changedTouches[0]
      : null;
  if (
    checkMove.value.clientX === touches.clientX &&
    checkMove.value.clientY === touches.clientY
  ) {
    goHome();
  }
  drag.value.flags = false;
  const element = toolList.value;
  if (element) {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const elementRect = element.getBoundingClientRect();
    // 檢查左邊界
    if (elementRect.left < 0) {
      element.style.left = "0";
    }
    // 檢查右邊界
    if (elementRect.right > windowWidth - 30) {
      element.style.left = windowWidth - element.offsetWidth - 30 + "px";
    }
    // 檢查上邊界
    if (elementRect.top < 15) {
      element.style.top = "15px";
    }
    // 檢查下邊界
    if (elementRect.bottom > windowHeight) {
      element.style.top = windowHeight - element.offsetHeight + "px";
    }
  }
};
//#endregion

async function goHome() {
  await router.push({ name: "home" });
  bgAudio.reloadMusic();
  // location.reload();
}
</script>

<template>
  <div class="h-[100vh] w-[100vw]" @mousemove="move" @mouseup="end">
    <object
      type="text/html"
      :data="gameUrl"
      class="fixed top-0 left-0 w-full h-full z-[300] overflow-hidden"
      ref="ObjectHtml"
    />
    <div class="fixed flex z-[400] top-[20%]" ref="toolList">
      <button
        type="button"
        class="absolute rounded-full border-[3px] border-white bg-gradient-to-b from-[#f9e7a2] to-[#dab26a] w-[50px] h-[50px]"
        @mousedown.prevent="down"
        @touchstart.prevent="down"
        @touchmove.prevent="move"
        @touchend.prevent="end"
      >
        <p class="font-bold text-[12px] text-secondary">HOME</p>
      </button>
    </div>
  </div>
</template>
