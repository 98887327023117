<script setup lang="ts">
import { useCounterStore } from "@/stores/counter";
import { useMemberService } from "@/stores/memberFunction/memberService";
const store = useCounterStore();
const memberService = useMemberService();

function cancel() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
  memberService.getDeletePromotionId("");
}

function submit() {
  memberService.deletePromotion();
}
</script>

<template>
  <div
    class="absolute top-[30%] -translate-y-1/2 left-1/2 -translate-x-1/2 w-[75vw] lg:w-auto"
  >
    <div
      class="p-[12px] xl:p-[3em] w-full h-[250px] top-0 bg-100 text-primary flex flex-col items-center justify-center bg-[url('@image/memberFunction/common/search_fail.png')]"
    >
      <h3 class="text-center text-[20px] xl:text-[24px] font-bold">
        {{ $t("MemberFunction.Promote.DeleteLink") }}
      </h3>
      <div class="flex items-center mb-[12px] mt-[24px]">
        <p class="mr-[1em]">{{ $t("MemberFunction.Promote.LinkName") }}</p>
        <p>{{ memberService.PromotionAlertContent.sTitle }}</p>
      </div>
      <div class="flex items-center mb-[12px] mt-[24px]">
        <button
          @click="submit"
          class="font-bold border-2 border-white rounded-xl mr-[3em] px-[1em] py-[3px] hover:text-secondary hover:bg-white"
        >
          {{ $t("MemberFunction.Promote.Submit") }}
        </button>
        <button
          @click="cancel"
          class="font-bold border-2 border-red-400 rounded-xl px-[1em] py-[3px] text-red-400 hover:text-primary hover:bg-red-400"
        >
          {{ $t("MemberFunction.Promote.Cancel") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
