<script setup lang="ts">
import { useCounterStore } from "@/stores/counter";
import { useLogin } from "@/stores/login";
import { useMemberService } from "@/stores/memberFunction/memberService";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";
const store = useCounterStore();
const login = useLogin();
const memberService = useMemberService();
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-[1vw] pb-[10dvh] pt-0 md:pt-[3rem]"
  >
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9 relative web-gold-border rounded-[24px]">
      <div class="px-0 pt-0 md:px-[5vw] md:pt-[2dvh] relative z-10">
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.NewMessage.NewMessage") }}
        </p>
        <div
          class="py-5 md:py-[5vh] px-5 md:px-0 max-h-[80dvh] overflow-y-auto newMessage-scrollbar"
        >
          <div
            class="text-[#fff]"
            v-for="Content in Object.values(memberService.NewMes)"
            :key="Content.nId.toString()"
          >
            <div class="relative">
              <img
                class="w-[100px]"
                src="@image/memberFunction/common/new-message-icon.png"
                alt=""
              />
              <div class="absolute top-0 left-0 p-5 text-secondary font-bold">
                {{ $t("MemberFunction.NewMessage.NewMessage") }}
              </div>
            </div>

            <div class="mt-2">{{ Content.sTitle0 }}</div>

            <div v-html="Content.sContent0"></div>

            <div class="text-end">
              {{ Content.sCreateTime }}<br /><br />
              <hr />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.newMessage-scrollbar {
}
.newMessage-scrollbar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.newMessage-scrollbar::-webkit-scrollbar-track {
  background: #00000027;
  border-radius: 20px;
}

/* Handle */
.newMessage-scrollbar::-webkit-scrollbar-thumb {
  background: #00000069;
  border-radius: 20px;
}

/* Handle on hover */
.newMessage-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #212529;
}
</style>
