<script setup lang="ts">
import { onMounted } from "vue";
import { useLoadingPage } from "@/stores/loadingPage";
const LoadingPage = useLoadingPage();

onMounted(() => {
  LoadingPage.toGameUrl();
});
</script>
<template>
  <img
    src="@image/memberFunction/文字框/loading-page-2.png"
    alt=""
    class="object-cover h-[100vh]"
  />
</template>
