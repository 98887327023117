<script setup lang="ts">
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useLogin } from "@/stores/login";
import { useRoute } from "vue-router";
import AgreeRegisterPage from "@/components/login/agreeRegisterPage.vue";

//-----------------------------------
import { useMemberService } from "@/stores/memberFunction/memberService";
import MemberServiceContent from "@/components/menberFunction/memberServiceContent.vue";
const memberService = useMemberService();
//-----------------------------------
const login = useLogin();
const { isAgreeRegisterPage } = storeToRefs(login);

// 0: 開, 1: 關
const Mode = ref<number>(0);
function selectMode(mode: number) {
  Mode.value = mode;
}
// 0: 開, 1: 關
const Mode2 = ref<number>(0);
function selectMode2(mode: number) {
  Mode2.value = mode;
}

const emit = defineEmits(["selectMode"]);

// 輸入手機 取得驗證碼
const phoneNumber = ref<string>("");
async function Phonebtn(phoneNumber: string) {
  const sPhone = phoneNumber;
  const postData = {
    sPhone,
  };
  await login.getSendMsg(postData);
}

const agreeRegister = ref<boolean>(false);

interface ShareFormValues {
  sAccount: string;
  sPasswd: string;
  sPasswd2: string;
  sShareAccount: string;
  sPhone: string;
  sPhone_Vscode: string;
}
const loginFrom = ref<HTMLFormElement | null>(null);
async function submit(formValues: ShareFormValues) {
  const { sAccount, sPasswd, sPasswd2, sShareAccount, sPhone, sPhone_Vscode } =
    formValues;
  const postData = {
    sAccount,
    sPasswd,
    sPasswd2,
    sPhone,
    sPhone_Vscode,
    sShareAccount,
  };
  await login.ShareRegister(postData);
}
function openAgreeRegisterPage() {
  login.openAgreeRegisterPage();
}
function closeAgreeRegisterPage() {
  login.closeAgreeRegisterPage();
}
function hasAccount() {
  emit("selectMode", 0);
}

const route = useRoute();
const urlParam = ref<String | String[]>("");

onMounted(() => {
  // setTimeout(() => {
  //   login.getPhoneSwitch();
  // }, 1000);
  // 从路由中获取参数
  const queryString = route.fullPath.split("?")[1]; // 获取查询字符串部分
  if (queryString) {
    const params = new URLSearchParams(queryString);
    // 更改前值
    const userParam = params.get("user");

    if (userParam) {
      urlParam.value = userParam;
    }
  }
});
</script>

<template>
  <VForm
    @submit="submit"
    v-slot="{ meta, isSubmitting, errors }"
    ref="loginFrom"
    class="text-primary flex flex-col mx-auto w-[17em]"
  >
    <div class="mb-[12px]">
      <label for="帳號" class="flex flex-col relative">
        <img src="@image/memberFunction/07/info_bar.png" alt="" />
        <img
          src="@image/memberFunction/07/icon_id.png"
          alt=""
          class="absolute w-[15px] top-1/2 -translate-y-1/2 left-[10px]"
        />
        <VField
          id="sAccount"
          class="absolute w-full h-full top-0 bg-transparent bg-cover bg-center rounded-full text-center placeholder:text-primary placeholder:font-bold"
          label="帳號"
          name="sAccount"
          type="text"
          :rules="{
            regex: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{6,16}$/,
            required: true,
          }"
          placeholder="請輸入帳號 英文+數字"
        />
      </label>
      <ErrorMessage
        name="sAccount"
        class="text-primary text-[12px] mb-[12px]"
      />
    </div>

    <div class="mb-[12px]">
      <label for="密碼" class="flex flex-col relative">
        <img src="@image/memberFunction/07/info_bar.png" alt="" />
        <img
          src="@image/memberFunction/07/icon_pw.png"
          alt=""
          class="absolute w-[15px] top-1/2 -translate-y-1/2 left-[10px]"
        />
        <VField
          id="sPasswd"
          class="absolute w-full h-full top-0 bg-transparent bg-cover bg-center rounded-full text-center placeholder:text-primary placeholder:font-bold"
          label="密碼"
          name="sPasswd"
          :type="Mode === 0 ? 'password' : 'text'"
          :rules="{
            regex: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{6,16}$/,
            required: true,
          }"
          placeholder="請輸入密碼 英文+數字"
        />
        <img
          src="@image/memberFunction/07/eye_op.png"
          alt=""
          class="absolute w-[20px] h-[15px] top-1/2 -translate-y-1/2 right-[15px]"
          :class="Mode === 0 ? 'hidden' : 'block'"
          @click="selectMode(0)"
        />
        <img
          src="@image/memberFunction/07/eye_ed.png"
          alt=""
          class="absolute w-[20px] h-[15px] top-1/2 -translate-y-1/2 right-[15px]"
          :class="Mode === 1 ? 'hidden' : 'block'"
          @click="selectMode(1)"
        />
      </label>
      <ErrorMessage name="sPasswd" class="text-primary text-[12px] mb-[12px]" />
    </div>

    <div class="mb-[12px]">
      <label for="確認密碼" class="flex flex-col relative">
        <img src="@image/memberFunction/07/info_bar.png" alt="" />
        <img
          src="@image/memberFunction/07/icon_pw_e.png"
          alt=""
          class="absolute w-[15px] top-1/2 -translate-y-1/2 left-[10px]"
        />
        <VField
          id="sPasswd2"
          class="absolute w-full h-full top-0 bg-transparent bg-cover bg-center rounded-full text-center placeholder:text-primary placeholder:font-bold"
          label="確認密碼"
          name="sPasswd2"
          :type="Mode2 === 0 ? 'password' : 'text'"
          :rules="{
            regex: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{6,16}$/,
            required: true,
          }"
          placeholder="請確認密碼"
        />
        <img
          src="@image/memberFunction/07/eye_op.png"
          alt=""
          class="absolute w-[20px] h-[15px] top-1/2 -translate-y-1/2 right-[15px]"
          :class="Mode2 === 0 ? 'hidden' : 'block'"
          @click="selectMode2(0)"
        />
        <img
          src="@image/memberFunction/07/eye_ed.png"
          alt=""
          class="absolute w-[20px] h-[15px] top-1/2 -translate-y-1/2 right-[15px]"
          :class="Mode2 === 1 ? 'hidden' : 'block'"
          @click="selectMode2(1)"
        />
      </label>
      <ErrorMessage
        name="sPasswd2"
        class="text-primary text-[12px] mb-[12px]"
      />
    </div>

    <!-- login.phoneSwitch == 0 開啟  -->
    <div>
      <div class="mb-[12px]">
        <label for="手機號碼" class="flex flex-col relative">
          <img src="@image/memberFunction/07/info_bar.png" alt="" />
          <img
            src="@image/memberFunction/07/icon_ph_num01.png"
            alt=""
            class="absolute w-[12px] top-1/2 -translate-y-1/2 left-[11.5px]"
          />
          <VField
            id="sPhone"
            class="absolute w-full h-full top-0 bg-transparent bg-cover bg-center rounded-full pl-[36px] placeholder:text-primary placeholder:font-bold"
            label="手機號碼"
            name="sPhone"
            type="text"
            v-model="phoneNumber"
            :rules="{
              regex: /^[0-9]{10}$/,
              required: true,
            }"
            placeholder="請輸入手機號碼"
          />
          <button
            class="absolute right-1 top-1/2 -translate-y-1/2"
            @click="Phonebtn(phoneNumber)"
            type="button"
          >
            <div class="relative">
              <img
                class="w-[80px]"
                src="@image/memberFunction/07/phone_code.png"
                alt=""
              />
              <p
                class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[12px] w-[120%] text-[#000000] font-bold"
              >
                取得驗證碼
              </p>
            </div>
          </button>
        </label>
        <ErrorMessage
          name="sPhone"
          class="text-primary text-[12px] mb-[12px]"
        />
      </div>

      <div class="mb-[12px]">
        <label for="驗證號" class="flex flex-col relative">
          <img src="@image/memberFunction/07/info_bar.png" alt="" />
          <img
            src="@image/memberFunction/07/key.png"
            alt=""
            class="absolute w-[12px] top-1/2 -translate-y-1/2 left-[11.5px]"
          />
          <VField
            id="sPhone_Vscode"
            class="absolute w-full h-full top-0 bg-transparent bg-cover bg-center rounded-full text-center placeholder:text-primary placeholder:font-bold"
            label="驗證號"
            name="sPhone_Vscode"
            type="text"
            :rules="{
              required: true,
            }"
            placeholder="請輸入驗證號"
          />
        </label>
        <ErrorMessage
          name="sPhone_Vscode"
          class="text-primary text-[12px] mb-[12px]"
        />
      </div>
    </div>

    <div class="mb-[12px]">
      <label for="推薦人帳號" class="flex flex-col relative">
        <img src="@image/memberFunction/07/info_bar.png" alt="" />
        <img
          src="@image/memberFunction/07/icon_store.png"
          alt=""
          class="absolute w-[15px] top-1/2 -translate-y-1/2 left-[10px]"
        />
        <VField
          id="sShareAccount"
          class="absolute w-full h-full top-0 bg-transparent bg-cover bg-center rounded-full text-center placeholder:text-primary placeholder:font-bold"
          label="推薦人帳號"
          name="sShareAccount"
          type="text"
          :rules="{
            regex: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{6,16}$/,
          }"
          disabled
          v-model="urlParam"
          placeholder="請輸入推廣碼"
        />
      </label>
      <ErrorMessage
        name="sShareAccount"
        class="text-primary text-[12px] mb-[12px]"
      />
    </div>

    <div
      class="flex justify-between items-center mb-[16px] mt-[8px] text-[14px]"
    >
      <label for="agreeRegister" class="flex items-center cursor-pointer">
        <div class="relative mr-1">
          <img
            src="@image/memberFunction/07/v.png"
            alt=""
            class="absolute top-[40%] -translate-y-1/2 left-[60%] -translate-x-1/2"
            v-if="agreeRegister"
          />
          <img
            class="w-[25px]"
            src="@image/memberFunction/07/v_bg.png"
            alt=""
          />
        </div>
        <span class="text-red-400" v-if="errors.agreeRegister">*</span>
        <VField
          name="agreeRegister"
          id="agreeRegister"
          type="checkbox"
          class="hidden"
          :rules="{ required: true }"
          v-model="agreeRegister"
        />
        <span
          class="hover:text-primary/80"
          :class="{ 'text-red-400': errors.agreeRegister }"
          >我已年滿18歲並同意相關</span
        >
      </label>
      <button
        class="text-red-400 underline decoration-1 hover:text-red/80"
        type="button"
        @click="openAgreeRegisterPage"
      >
        開戶協議
      </button>
    </div>

    <p class="text-red-400 text-center">{{ login.loginMsg }}</p>
    <button
      class="relative"
      type="submit"
      :disabled="isSubmitting"
      :class="{ submitting: isSubmitting }"
    >
      <img
        src="@image/memberFunction/07/login_big_btn.png"
        alt=""
        class="w-full h-full"
      />
      <p
        class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[#342814] text-[16px] font-bold"
      >
        {{ isSubmitting ? "" : "註冊" }}
      </p>
    </button>

    <div
      class="flex justify-between items-center mb-[12px] mt-[18px] px-[12px] text-primary text-[14px]"
    >
      <button
        class="underline decoration-1 hover:text-primary/80"
        type="button"
        @click="hasAccount"
      >
        已有帳號
      </button>
      <span class="h-full w-[2px] bg-white"></span>
      <button
        class="underline decoration-1 hover:text-primary/80"
        @click="memberService.ToggleService"
        type="button"
      >
        在線客服
      </button>
    </div>
  </VForm>
  <div
    class="absolute top-0 left-0 bg-black/50 w-full h-full z-[60]"
    @click="closeAgreeRegisterPage"
    v-if="isAgreeRegisterPage"
  >
    <AgreeRegisterPage />
  </div>
</template>
