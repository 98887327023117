<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import { useTimeListLogic } from "@/stores/timeListLogic";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";

const { t } = useI18n();
const memberService = useMemberService();
const store = useCounterStore();
const timeListLogic = useTimeListLogic();
const { defaultMailBoxData } = storeToRefs(memberService);

function changeStartTime() {
  if (
    !defaultMailBoxData.value.sEndTime ||
    defaultMailBoxData.value.sEndTime <= defaultMailBoxData.value.sStartTime
  ) {
    const getStartTime = new Date(defaultMailBoxData.value.sStartTime);
    getStartTime.setMonth(getStartTime.getMonth() + 1);
    defaultMailBoxData.value.sEndTime = getStartTime.toISOString().slice(0, 10);
  }
}
function readAllMail() {
  memberService.ReadAllMail();
}

//#region 點擊查詢時間
interface TimeList {
  title: string;
  fn: Function;
  isSelect: boolean;
}
const timeLists = ref<TimeList[]>([
  {
    title: t("MemberFunction.MailBox.Yesterday"),
    fn: getYesterdayMail,
    isSelect: false,
  },
  {
    title: t("MemberFunction.MailBox.Today"),
    fn: getTodayMail,
    isSelect: false,
  },
  {
    title: t("MemberFunction.MailBox.LastWeek"),
    fn: getLastWeekMail,
    isSelect: false,
  },
  {
    title: t("MemberFunction.MailBox.ThisWeek"),
    fn: getThisWeekMail,
    isSelect: false,
  },
  {
    title: t("MemberFunction.MailBox.Last30Days"),
    fn: getThisMonthMail,
    isSelect: true,
  },
]);
function selectTime(index: number) {
  timeLists.value.forEach((time, timeIndex) => {
    time.isSelect = false;
    if (timeIndex === index) {
      time.isSelect = true;
    }
  });
}

const today = new Date();
const thirtyDaysAgo = new Date(today);
thirtyDaysAgo.setDate(today.getDate() - 31);
const formatDate = (date: any) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const minDate = ref(formatDate(thirtyDaysAgo));
const maxDate = ref(formatDate(today));

function getYesterdayMail() {
  const today = new Date();
  const day = today.getDay();
  defaultMailBoxData.value.sStartTime = timeListLogic.getDataDate(day - 1);
  defaultMailBoxData.value.sEndTime = timeListLogic.getDataDate(day - 1);

  selectTime(0);
  getMailBoxData();
}
function getTodayMail() {
  const today = new Date();
  const day = today.getDay();
  defaultMailBoxData.value.sStartTime = timeListLogic.getDataDate(day);
  defaultMailBoxData.value.sEndTime = timeListLogic.getDataDate(day);

  selectTime(1);
  getMailBoxData();
}
function getLastWeekMail() {
  defaultMailBoxData.value.sStartTime = timeListLogic.getDataDate(-6);
  defaultMailBoxData.value.sEndTime = timeListLogic.getDataDate(0);

  selectTime(2);
  getMailBoxData();
}
function getThisWeekMail() {
  defaultMailBoxData.value.sStartTime = timeListLogic.getDataDate(1);
  defaultMailBoxData.value.sEndTime = timeListLogic.getDataDate(7);

  selectTime(3);
  getMailBoxData();
}

function getThisMonthMail() {
  const today = new Date();
  const day = today.getDay();
  defaultMailBoxData.value.sStartTime = timeListLogic.getDataDate(day - 29);
  defaultMailBoxData.value.sEndTime = timeListLogic.getDataDate(day);

  selectTime(4);
  getMailBoxData();
}
//#endregion

function goMailBoxPage(page: number) {
  memberService.goMailBoxPage(page);
}

async function getMailBoxData() {
  await memberService.getMailBoxData();
}

function openMailBoxAlert(nId: string) {
  store.toggleAlertBg();
  store.openMailBoxAlert();
  memberService.getMailBoxAlertContent(nId);
  memberService.ReadMail(nId);
}

// 刪除信件
function openMailBoxAlert2(nId: string) {
  store.toggleAlertBg();
  store.openMailBoxAlert2();
  memberService.getDeleteAlertId(nId);
}
function deleteHaveReadMail() {
  memberService.DeleteHaveReadMail();
}
</script>
<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] gap-y-[1dvh] block px-[1em] pt-[1em] pb-[3rem] md:pt-[3rem]">
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <!-- APP -->
    <div class="col-span-9 relative">
      <div
        class="px-0 pt-0 pb-0 md:px-[5vw] md:pt-[2dvh] md:pb-[4dvh] relative z-10 web-gold-border rounded-[24px]">
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.MailBox.MailBox") }}
        </p>
        <div class="flex flex-col">
          <div class="flex flex-col md:flex-row items-center">
            <div class="flex justify-between items-center w-full col-span-3">
              <label
                for="startDate"
                class="search-date-label relative w-full md:w-[16vw]">
                <input
                  id="startDate"
                  @change="changeStartTime"
                  :min="minDate"
                  :max="maxDate"
                  type="date"
                  v-model="defaultMailBoxData.sStartTime"
                  class="search-date-border text-primary text-[12px] w-full h-[100%] md:h-[3dvh] bg-transparent text-center bg-cover bg-center placeholder:text-primary" />
              </label>
              <span class="text-primary text-[12px] md:text-[14px] m-[12px]">
                {{ $t("MemberFunction.MailBox.To") }}</span
              >
              <label
                for="memberNumber"
                class="search-date-label relative w-full md:w-[16vw]">
                <input
                  type="date"
                  v-model="defaultMailBoxData.sEndTime"
                  :min="minDate"
                  :max="maxDate"
                  class="search-date-border text-primary text-[12px] w-full h-[100%] md:h-[3dvh] bg-transparent text-center bg-cover bg-center placeholder:text-primary" />
              </label>
              <button @click="getMailBoxData" class="ml-[12px]">
                <img
                  src="@image/memberFunction/08/search_btn.png"
                  alt=""
                  class="h-auto md:h-[3dvh]" />
              </button>
            </div>
          </div>
          <ul
            class="flex justify-center md:justify-between items-center mt-[1dvh]">
            <li
              v-for="time in timeLists"
              :key="time.title + 'memberMail'"
              class="flex justify-center md:justify-start items-center">
              <button
                class="group relative w-[80%] md:w-[6em] xl:w-[8em] text-[12px] md:text-[14px] mr-0"
                @click="time.fn"
                :class="{
                  'text-primary': !time.isSelect,
                  'text-secondary': time.isSelect,
                }">
                <img
                  src="@image/memberFunction/common/會員選擇無.png"
                  alt=""
                  v-show="!time.isSelect" />
                <img
                  src="@image/memberFunction/common/會員選擇.png"
                  alt=""
                  v-show="time.isSelect" />
                <p
                  class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[110%]">
                  {{ time.title }}
                </p>
              </button>
            </li>
          </ul>
          <div class="flex justify-end my-[12px] md:hidden">
            <button
              type="button"
              class="relative h-[35px] mr-[12px]"
              @click="openMailBoxAlert2('all')">
              <img
                src="@image/memberFunction/08/email_del.png"
                alt=""
                class="h-full" />
              <p
                class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-primary text-[12px] lg:text-[16px]">
                {{ $t("MemberFunction.MailBox.DeleteMail") }}
              </p>
            </button>
            <button
              type="button"
              class="relative h-[35px]"
              @click="readAllMail">
              <img
                src="@image/memberFunction/08/email_del.png"
                alt=""
                class="h-full" />
              <p
                class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-primary text-[12px] lg:text-[16px]">
                {{ $t("MemberFunction.MailBox.ReadAll") }}
              </p>
            </button>
          </div>
        </div>
        <div
          class="relative member-bg-border mb-[2rem] mt-0 md:mt-[2dvh] py-[0px] px-[0] min-h-[60dvh] md:min-h-[60dvh]">
          <!-- main -->
          <div class="text-primary text-[12px] md:text-[14px] w-full px-0">
            <div class="overflow-auto">
              <table class="w-[100%] md:w-full table-auto">
                <thead>
                  <tr>
                    <th
                      class="w-[10%] h-[45px] text-left pl-[12px] lg:pl-[2em] bg-white/0 md:bg-white/20 rounded-tl-md"></th>
                    <th
                      class="w-[20%] h-[45px] text-left bg-white/0 md:bg-white/20">
                      {{ $t("MemberFunction.MailBox.Form") }}
                    </th>
                    <th
                      class="w-[25%] h-[45px] text-left bg-white/0 md:bg-white/20">
                      {{ $t("MemberFunction.MailBox.Title") }}
                    </th>
                    <th
                      class="h-[45px] text-right pr-[12px] bg-white/0 md:bg-white/20 rounded-tr-md">
                      {{ $t("MemberFunction.MailBox.Time") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in memberService.CurrentMailBox"
                    :key="item.nId"
                    @click="openMailBoxAlert(item.nId)"
                    class="cursor-pointer odd:bg-black/50 bg-white/10 hover:border relative first:after:h-[0px] after:absolute after:h-[1px] after:bg-white after:left-1/2 after:-translate-x-1/2 after:w-[calc(100%)]">
                    <td class="w-[10%] h-[45px] pl-[12px] lg:pl-[2em]">
                      {{ item.nRead === "0" ? "●" : "" }}
                    </td>
                    <td class="w-[20%] h-[45px]">
                      {{ item.nSendFrom }}
                    </td>
                    <td class="w-[40%] h-[45px]">
                      {{ item.sTitle0 }}
                    </td>
                    <td class="h-[45px] text-right pr-[12px]">
                      {{ item.sReceiveTime }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th class="h-[45px] text-center pl-[6px] lg:pl-[2em]"></th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div
              class="flex items-center justify-center absolute bottom-[10px] top-[unset] md:top-[100%] md:bottom-[0] w-full left-1/2 -translate-x-1/2 text-[12px]">
              <button
                type="button"
                :disabled="
                  memberService.MailBoxPage === 1 ||
                  memberService.TotalMailBoxPage === 0
                "
                @click="goMailBoxPage(1)"
                class="text-[10px] md:text-[16px] mr-[12px] text-primary"
                :class="{
                  'text-primary/50': memberService.MailBoxPage === 1,
                }"
                v-if="memberService.TotalMailBoxPage > 1">
                {{ $t("MemberFunction.MailBox.FirstPage") }}
              </button>
              <button
                type="button"
                :disabled="
                  memberService.MailBoxPage === 1 ||
                  memberService.TotalMailBoxPage === 0
                "
                @click="goMailBoxPage(memberService.MailBoxPage - 1)"
                class="w-[10px]">
                <img src="@image/memberFunction/common/頁面跳轉.png" alt="" />
              </button>
              <div
                class="text-primary bg-gray-600 border border-gray-50/80 rounded px-[12px] mx-[6px]">
                <p>
                  <span class="text-[14px] font-bold">
                    {{ memberService.MailBoxPage }}
                  </span>
                  <span class="text-[10px] opacity-50">
                    /
                    {{
                      memberService.TotalMailBoxPage
                        ? memberService.TotalMailBoxPage
                        : 1
                    }}
                  </span>
                </p>
              </div>
              <!-- <ul class="flex text-primary">
                <li
                  v-for="index in memberService.TotalMailBoxPage"
                  :key="`mailPage${index}`"
                  class="mx-[6px]"
                >
                  <button
                    @click="goMailBoxPage(index)"
                    class="font-bold"
                    :disabled="index === memberService.MailBoxPage"
                    :class="
                      index === memberService.MailBoxPage
                        ? 'text-primary'
                        : 'text-primary/50 hover:text-primary'
                    "
                  >
                    {{ index }}
                  </button>
                </li>
              </ul> -->
              <button
                type="button"
                :disabled="
                  memberService.MailBoxPage ===
                    memberService.TotalMailBoxPage ||
                  memberService.TotalMailBoxPage === 0
                "
                class="rotate-180 w-[10px]"
                @click="goMailBoxPage(memberService.MailBoxPage + 1)">
                <img src="@image/memberFunction/common/頁面跳轉.png" alt="" />
              </button>
              <button
                type="button"
                :disabled="
                  memberService.MailBoxPage ===
                    memberService.TotalMailBoxPage ||
                  memberService.TotalMailBoxPage === 0
                "
                @click="goMailBoxPage(memberService.TotalMailBoxPage)"
                class="text-[10px] md:text-[16px] ml-[12px] text-primary"
                :class="{
                  'text-primary/50':
                    memberService.MailBoxPage ===
                    memberService.TotalMailBoxPage,
                }"
                v-if="memberService.TotalMailBoxPage > 1">
                {{ $t("MemberFunction.MailBox.LastPage") }}
              </button>
            </div>
          </div>

          <!-- 沒有資料 -->
          <div
            class="absolute left-1/2 top-3/1 -translate-x-1/2"
            v-if="memberService.CurrentMailBox.length === 0">
            <img
              src="@image/memberFunction/文字框/no_file.png"
              alt=""
              class="w-[35px]" />
            <p class="text-primary text-[12px] text-center">
              {{ $t("MemberFunction.MailBox.NoData") }}
            </p>
          </div>
        </div>
        <div class="md:flex justify-end mt-[12px] hidden">
          <button
            class="relative h-[35px] mr-[12px]"
            @click="openMailBoxAlert2('all')">
            <img
              src="@image/memberFunction/08/email_del.png"
              alt=""
              class="h-full" />
            <p
              class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-primary text-[14px] w-[6em]">
              {{ $t("MemberFunction.MailBox.DeleteMail") }}
            </p>
          </button>
          <button class="relative h-[35px]" @click="readAllMail">
            <img
              src="@image/memberFunction/08/email_del.png"
              alt=""
              class="h-full" />
            <p
              class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-primary text-[14px] w-[6em]">
              {{ $t("MemberFunction.MailBox.ReadAll") }}
            </p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
</style>
