<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useLobbyList } from "@/stores/lobbyList";
const lobbyList = useLobbyList();
const { LobbyGameList, GameType } = storeToRefs(lobbyList);
function GameImage(key: string | number) {
  return `https://petimg.icv8888.net/GameLobbyImg/${GameType.value}/${key}.png`;
}

function ToGame(url: string) {
  lobbyList.ToGame(url);
}

function hasNew(key: string | number) {
  let boolean = false;
  if (GameType.value === "BB66") {
    switch (key) {
      case "GB5044":
      case "TA30":
      case "TA34":
      case "TB2":
      case "GB5042":
      case "TA10":
      case "TA3":
      case "TA1":
        boolean = true;
        break;
    }
  }
  return boolean;
}
</script>
<template>
  <div class="px-[1rem] max-w-[1366px] mx-auto">
    <picture v-if="GameType === 'BB69'">
      <source
        media="(min-width: 768px )"
        srcset="@image/memberFunction/15/DB_GameLobby_web_bg.png"
        class="w-full"
      />
      <img
        src="@image/memberFunction/15/DB_GameLobby_app_bg.png"
        alt=""
        class="w-full"
      />
    </picture>
    <picture v-else-if="GameType === 'BB80'">
      <source
        media="(min-width: 768px )"
        srcset="@image/memberFunction/15/CG_GameLobby_web_bg.png"
        class="w-full"
      />
      <img
        src="@image/memberFunction/15/CG_GameLobby_app_bg.png"
        alt=""
        class="w-full"
      />
    </picture>
    <picture v-else>
      <source
        media="(min-width: 768px )"
        srcset="@image/memberFunction/15/GameLobby_web_bg.png"
        class="w-full"
      />
      <img
        src="@image/memberFunction/15/GameLobby_app_bg.png"
        alt=""
        class="w-full"
      />
    </picture>
    <ul
      class="grid grid-cols-4 md:grid-cols-8 gap-[1rem] md:gap-[2rem] md:px-[2rem] px-[0rem] md:py-[2rem] py-[1rem] member-in-bg-border mb-[10vh]"
    >
      <li
        v-for="(value, key) in LobbyGameList"
        :key="key + '_GameLobby'"
        class="flex flex-col justify-center items-center"
      >
        <button
          type="button"
          class="group relative rounded-[26px] overflow-hidden after:hover:h-full after:absolute after:left-1/2 after:-translate-x-1/2 after:bottom-0 after:z-20 after:w-full after:h-[50%] after:bg-gradient-to-t after:bg-from-black/0 after:md:from-black/80 after:to-black/0"
          @click="ToGame(value.sUrl)"
        >
          <img
            src="@image/memberFunction/15/new.png"
            alt=""
            class="absolute z-20 w-[50%]"
            v-if="hasNew(key)"
          />
          <img
            src="@image/memberFunction/15/game_btn.png"
            alt=""
            class="z-10"
          />
          <img
            :src="GameImage(key)"
            alt=""
            class="absolute top-1/2 -translate-y-1/2 p-[.2em] z-10 rounded-[26px]"
          />
          <p
            class="absolute bottom-1 left-1/2 -translate-x-1/2 z-30 w-[120%] font-bold text-[12px] md:text-[16px] hidden md:block"
          >
            {{ value.sName }}
          </p>
          <p
            class="btn-n-border text-main absolute w-[80%] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-30 font-bold text-[12px] md:text-[16px] hidden group-hover:block"
          >
            {{ $t("Context.GameLobby.ToGame") }}
          </p>
        </button>
        <p
          class="w-[120%] font-bold text-center text-[12px] md:text-[16px] group-hover:hidden block md:hidden"
        >
          {{ value.sName }}
        </p>
      </li>
    </ul>
  </div>
</template>
<style>
.lobby-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/15/game_btn.png") 1;
  -webkit-border-image: url("@image/memberFunction/15/game_btn.png") 1;
  -o-border-image: url("@image/memberFunction/15/game_btn.png") 1;
  border-image: url("@image/memberFunction/15/game_btn.png") 1 fill;
}
</style>
