<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { useMemberService } from "@/stores/memberFunction/memberService";
const memberService = useMemberService();
interface AddStoreDepositFormData {
  sCode: string;
  sCounty: string;
  sShop: string;
  sStore: string;
}
const AddStoreForm = ref<any>(null);
const twzipcode = ref<string[]>([
  "台北市",
  "基隆市",
  "新北市",
  "連江縣",
  "宜蘭縣",
  "新竹市",
  "新竹縣",
  "桃園市",
  "苗栗縣",
  "台中市",
  "彰化縣",
  "南投縣",
  "嘉義市",
  "嘉義縣",
  "雲林縣",
  "台南市",
  "高雄市",
  "澎湖縣",
  "金門縣",
  "屏東縣",
  "台東縣",
  "花蓮縣",
]);
const storeName = ref<string[]>(["7-11", "全家", "萊爾富", "OK"]);
const emit = defineEmits(["changeDepositPage"]);
function changeDepositPage(page: number) {
  emit("changeDepositPage", page);
}
function submit(VForm: AddStoreDepositFormData) {
  AddStoreForm.value.resetForm();
  memberService.getAddMemberStore(VForm);
  changeDepositPage(4);
}
</script>
<template>
  <p class="text-[18px] font-bold text-center">
    {{ $t("MemberFunction.Deposit.BindStore") }}
  </p>
  <VForm @submit="submit" ref="AddStoreForm" class="px-[9vw]">
    <div class="text-primary">
      <label
        for="sStore"
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[16px] md:mt-[3dvh]"
      >
        <p class="mr-[1em] col-span-4">
          {{ $t("MemberFunction.Deposit.StoreType") }}
        </p>
        <div class="relative col-span-8">
          <div class="h-[26px] w-full">
            <img
              src="@image/memberFunction/common/triangle_icon.png"
              alt=""
              class="h-[12px] absolute right-[2.5%] md:right-[3.4%] top-[16%] z-20"
            />
          </div>
          <VField
            id="sStore"
            :label="$t('MemberFunction.Deposit.PaymentMethod')"
            name="sStore"
            v-slot="{ field }"
            class="relative"
            :rules="{ required: true }"
          >
            <select
              v-bind="field"
              class="absolute select-bg-border top-0 bg-transparent px-2 rounded w-full h-[26px] text-primary"
            >
              <option class="text-black" value="">
                {{ $t("MemberFunction.Deposit.StoreTypePlaceholder") }}
              </option>
              <option
                class="text-black"
                v-for="value in storeName"
                :key="value + 'onlineDepositOther'"
                :value="value"
              >
                {{ value }}
              </option>
            </select>
          </VField>
          <ErrorMessage
            name="sStore"
            class="text-primary text-[10px]"
            v-slot="{ message }"
          >
            <p class="text-primary text-[10px] mr-[6px]">
              {{
                message.includes("必填")
                  ? $t("MemberFunction.Deposit.Require")
                  : message
              }}
            </p>
          </ErrorMessage>
        </div>
      </label>
      <label
        for="sCounty"
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[16px] md:mt-[3dvh]"
      >
        <p class="mr-[1em] col-span-4">所在縣市</p>
        <div class="relative col-span-8">
          <div class="h-[26px] w-full">
            <img
              src="@image/memberFunction/common/triangle_icon.png"
              alt=""
              class="h-[12px] absolute right-[2.5%] md:right-[3.4%] top-[16%] z-20"
            />
          </div>
          <VField
            id="sCounty"
            :label="$t('MemberFunction.Deposit.PaymentMethod')"
            name="sCounty"
            v-slot="{ field }"
            class="relative"
            :rules="{ required: true }"
          >
            <select
              v-bind="field"
              class="absolute select-bg-border top-0 bg-transparent px-2 rounded w-full h-[26px] text-primary"
            >
              <option class="text-black" value="">請選擇所在縣市</option>
              <option
                class="text-black"
                v-for="value in twzipcode"
                :key="value + 'onlineDepositOther'"
                :value="value"
              >
                {{ value }}
              </option>
            </select>
          </VField>
          <ErrorMessage
            name="sCounty"
            class="text-primary text-[10px]"
            v-slot="{ message }"
          >
            <p class="text-primary text-[10px] mr-[6px]">
              {{
                message.includes("必填")
                  ? $t("MemberFunction.Deposit.Require")
                  : message
              }}
            </p>
          </ErrorMessage>
        </div>
      </label>
      <label
        for="sShop"
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[16px] md:mt-[3dvh]"
      >
        <p class="mr-[1em] col-span-4">
          {{ $t("MemberFunction.Deposit.StoreName") }}
        </p>
        <div class="relative inline-block col-span-8">
          <div class="h-[26px]">
            <img
              src="@image/memberFunction/文字框/文字框_長_A.png"
              alt=""
              class="h-[26px] w-full"
            />
          </div>
          <VField
            class="col-span-1 absolute h-[26px] top-0 rounded-md bg-transparent w-full px-2"
            id="sShop"
            :label="$t('MemberFunction.Deposit.DepositAmount')"
            name="sShop"
            type="string"
            :rules="{ required: true }"
          />
          <ErrorMessage
            name="sShop"
            class="text-primary text-[10px]"
            v-slot="{ message }"
          >
            <p class="text-primary text-[10px] mr-[6px]">
              {{
                message.includes("必填")
                  ? $t("MemberFunction.Deposit.Require")
                  : message
              }}
            </p>
          </ErrorMessage>
        </div>
      </label>
      <label
        for="sCode"
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[16px] md:mt-[3dvh]"
      >
        <p class="mr-[1em] col-span-4">
          {{ $t("MemberFunction.Deposit.StoreCode") }}
        </p>
        <div class="relative inline-block col-span-8">
          <div class="h-[26px]">
            <img
              src="@image/memberFunction/文字框/文字框_長_A.png"
              alt=""
              class="h-[26px] w-full"
            />
          </div>
          <VField
            class="col-span-1 absolute h-[26px] top-0 rounded-md bg-transparent w-full px-2"
            id="sCode"
            :label="$t('MemberFunction.Deposit.DepositAmount')"
            name="sCode"
            type="string"
            :rules="{ required: true }"
          />
          <ErrorMessage
            name="sCode"
            class="text-primary text-[10px]"
            v-slot="{ message }"
          >
            <p class="text-primary text-[10px] mr-[6px]">
              {{
                message.includes("必填")
                  ? $t("MemberFunction.Deposit.Require")
                  : message
              }}
            </p>
          </ErrorMessage>
        </div>
      </label>
    </div>

    <div class="mt-[3dvh] md:static flex flex-col justify-center items-center">
      <button type="submit" class="btn-y-border">
        <p
          class="text-[14px] md:text-[14px] font-bold text-secondary px-[2em] py-[.1em]"
        >
          {{ $t("MemberFunction.Deposit.Submit") }}
        </p>
      </button>
      <div class="mt-[3dvh]">
        <p class="mb-[1dvh]">
          {{ $t("MemberFunction.Deposit.CheckStoreCode") }}
        </p>
        <div class="grid grid-cols-4 gap-[12px]">
          <a
            href="https://www.ibon.com.tw/retail_inquiry.aspx#gsc.tab=0"
            target="_blank"
            class="col-span-1"
          >
            <img src="@image/memberFunction/12/7eleven-logo.png" alt="" />
          </a>
          <a
            href="https://www.family.com.tw/Marketing/storemap/inquiry_iceCream.aspx"
            target="_blank"
            class="col-span-1"
          >
            <img src="@image/memberFunction/12/family-Logo.png" alt="" />
          </a>
          <a
            href="https://www.okmart.com.tw/convenient_shopSearch"
            target="_blank"
            class="col-span-1"
          >
            <img src="@image/memberFunction/12/HiLife-Logo.png" alt="" />
          </a>
          <a
            href=" https://www.hilife.com.tw/storeInquiry_shopNo.aspx"
            target="_blank"
            class="col-span-1"
          >
            <img src="@image/memberFunction/12/OK-Logo.png" alt="" />
          </a>
        </div>
      </div>
      <div class="mt-[3dvh]">
        <div class="flex justify-center items-center leading-[36px]">
          <img
            src="@image/memberFunction/11/驚嘆號icon.png"
            alt=""
            class="w-[16px] mr-1"
          />
          <span class="text-red-600">
            {{ $t("MemberFunction.Deposit.StoreNotice") }}
          </span>
        </div>
        <div class="text-primary text-[14px] md:text-[16px] leading-[20px]">
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.StoreNoticeList1") }}
            </p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>{{ $t("MemberFunction.Deposit.StoreNoticeList2") }}</p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.StoreNoticeList3") }}
            </p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.StoreNoticeList4") }}
            </p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.StoreNoticeList5") }}
            </p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.StoreNoticeList6") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </VForm>
</template>
