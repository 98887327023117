import { ref, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { defineStore } from "pinia";

export const useLoadingPage = defineStore("LoadingPage", () => {
  const router = useRouter();
  const isLoadingPage = ref<boolean>(false); //這支給開頭主頁使用

  // GameLoading Page
  const GameUrl = ref<string>("");
  function toLoadingPage(url: string) {
    GameUrl.value = url;
    router.push({ name: "GameLoadingPage" });
  }
  function toGameUrl() {
    setTimeout(function () {
      if (!GameUrl.value) return;
      window.location.replace(GameUrl.value);
    }, 1000);
  }

  // Loading Page
  function openLoadingPage() {
    isLoadingPage.value = true;
  }
  function closeLoadingPage() {
    isLoadingPage.value = false;
  }

  return {
    toLoadingPage,
    toGameUrl,
    isLoadingPage,
    openLoadingPage,
    closeLoadingPage,
  };
});
