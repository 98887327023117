<script setup lang="ts">
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useErrorAlert } from "@/stores/errorAlert";
const memberService = useMemberService();
const errorAlert = useErrorAlert();

async function copyUrl(url: string) {
  // await navigator.clipboard.writeText(url);
  // if (navigator.clipboard && window.isSecureContext) {
  //   // navigator clipboard 向剪贴板写文本
  //   await navigator.clipboard.writeText(url);
  // } else {
  // 创建text area
  const textArea: HTMLInputElement = document.createElement("input");
  textArea.value = url;
  document.body.appendChild(textArea);
  textArea.select();
  // 执行复制命令并移除文本框
  document.execCommand("copy")
    ? errorAlert.AddErrorAlert("複製完成")
    : errorAlert.AddErrorAlert("複製失敗");

  document.body.removeChild(textArea);
}
// }
</script>

<template>
  <div class="sticky top-[40%] -translate-y-1/2 mx-auto w-[95vw] md:w-[450px]">
    <div
      class="relative bg-[url('@image/memberFunction/08/detail_board.png')] bg-100"
    >
      <h3
        class="absolute top-[16px] left-1/2 -translate-x-1/2 text-center text-secondary text-[20px] xl:text-[24px] font-bold"
      >
        {{ $t("MemberFunction.Promote.Details") }}
      </h3>
      <div
        class="p-[3em] w-full md:w-[420px] h-full top-0 bg-100 text-primary flex flex-col text-[14px] xl:text-[20px]"
      >
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Promote.LinkName") }}
          </p>
          <p class="col-span-5">
            {{ memberService.PromotionAlertContent.sTitle }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[12px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Promote.Layout") }}
          </p>
          <p class="col-span-5">
            {{
              memberService.PromotionAlertContent.nRank === "0"
                ? $t("MemberFunction.Promote.Member")
                : $t("MemberFunction.Promote.Proxy")
            }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[12px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Promote.Url") }}
          </p>
          <div class="col-span-5 flex">
            <p class="truncate basis-2/3">
              {{ memberService.PromotionAlertContent.sUrl }}
            </p>
            <button
              type="button"
              class="btn-n-border"
              @click="copyUrl(memberService.PromotionAlertContent.sUrl)"
            >
              <p class="text-[12px] px-[1em] py-[.1em]">
                {{ $t("MemberFunction.Promote.Copy") }}
              </p>
            </button>
          </div>
        </div>
        <div class="grid grid-cols-8 justify-center w-full mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Promote.QRcord") }}
          </p>
          <div class="col-span-5">
            <img
              :src="memberService.PromotionAlertContent.sQrImg2"
              class="w-[100px] h-[100px]"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
