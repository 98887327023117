<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";

import SubmitUSDTDeposit from "@/components/menberFunction/depositPage/SubmitUSDTDeposit.vue";
import USDTFormDeposit from "@/components/menberFunction/depositPage/USDTFormDeposit.vue";

const memberService = useMemberService();
const { hasUSDTForm } = storeToRefs(memberService);
</script>
<template>
  <SubmitUSDTDeposit v-if="hasUSDTForm" />
  <USDTFormDeposit v-else />
</template>
