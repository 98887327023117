<script setup lang="ts">
import { ref, computed, watchEffect } from "vue";
import { storeToRefs } from "pinia";

import { useMemberService } from "@/stores/memberFunction/memberService";

import ATMDeposit from "@/components/menberFunction/depositPage/ATMDeposit.vue";
import StoreDeposit from "@/components/menberFunction/depositPage/storeDeposit.vue";
import OnlineDeposit from "@/components/menberFunction/depositPage/onlineDeposit.vue";
import CompanyDeposit from "@/components/menberFunction/depositPage/companyDeposit.vue";
import UploadDeposit from "@/components/menberFunction/depositPage/uploadDeposit.vue";
import RecordDeposit from "@/components/menberFunction/depositPage/recordDeposit.vue";
import USDTDeposit from "@/components/menberFunction/depositPage/USDTDeposit.vue";
import SubmitUSDTDeposit from "@/components/menberFunction/depositPage/SubmitUSDTDeposit.vue";
import AddStoreDeposit from "@/components/menberFunction/depositPage/AddStoreDeposit.vue";

import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";

const memberService = useMemberService();
const {
  MemberCompanyDepositMsg,
  MemberCompanyDeposit,
  MemberUSDTDeposit,
  MemberRecharge2List,
} = storeToRefs(memberService);

const showDepositPage = ref<number>(99);
function changeDepositPage(page: number) {
  showDepositPage.value = page;
  MemberCompanyDepositMsg.value = "";
}

const CompanyInfo = computed(() => {
  if (
    MemberCompanyDeposit.value.length <= 0 ||
    JSON.stringify(MemberUSDTDeposit.value) === "{}"
  ) {
    return false;
  }
  return true;
});
const USDTInfo = computed(() => {
  if (JSON.stringify(MemberUSDTDeposit.value) === "{}") {
    return false;
  }
  return true;
});
const ATMInfo = computed(() => {
  const Info = MemberRecharge2List.value.find((item) => {
    return item.sName === "ATM";
  });
  if (!Info) return Info;
  return Info;
});
const CreditCardInfo = computed(() => {
  const Info = MemberRecharge2List.value.find((item) => {
    return item.sName === "信用卡";
  });
  if (!Info) return Info;
  return Info;
});

const StoreInfo = computed(() => {
  const Info = MemberRecharge2List.value.find((item) => {
    return item.sName === "超商付款";
  });
  if (!Info) return Info;
  return Info;
});
watchEffect(() => {
  // console.log(USDTInfo.value, CompanyInfo.value);
  let number = 99;
  if (StoreInfo.value) {
    number = 4;
  }
  if (CreditCardInfo.value) {
    number = 7;
  }
  if (ATMInfo.value) {
    number = 3;
  }
  if (CompanyInfo.value) {
    number = 2;
  }
  if (USDTInfo.value) {
    number = 6;
  }
  changeDepositPage(number);
});
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-[1vw] pb-[10dvh] pt-0 md:pt-[3rem]"
  >
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9 relative">
      <div
        class="px-0 pt-0 pb-0 md:px-[5vw] md:pt-[2dvh] md:pb-[4dvh] relative z-10 web-gold-border rounded-[24px]"
      >
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.Deposit.Deposit") }}
        </p>
        <div class="flex justify-center items-center mt-5 mx-3">
          <!-- <button
            class="group relative flex justify-center items-center mr-[1vw]"
            @click="changeDepositPage(1)"
            :class="{
              'text-secondary': showDepositPage === 1,
              'text-primary hover:text-secondary': showDepositPage !== 1,
            }"
          >
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn_p.png"
              alt=""
              :class="{
                block: showDepositPage === 1,
                'hidden group-hover:block': showDepositPage !== 1,
              }"
            />
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn.png"
              alt=""
              :class="{
                hidden: showDepositPage === 1,
                'block group-hover:hidden': showDepositPage !== 1,
              }"
            />
            <div class="absolute text-[14px] font-bold">線上儲值</div>
          </button> -->
          <button
            class="group relative flex justify-center items-center mr-[1vw]"
            @click="changeDepositPage(2)"
            v-if="CompanyInfo"
            :class="{
              'text-secondary': showDepositPage === 2,
              'text-primary hover:text-secondary': showDepositPage !== 2,
            }"
          >
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn_p.png"
              alt=""
              :class="{
                block: showDepositPage === 2,
                'hidden group-hover:block': showDepositPage !== 2,
              }"
            />
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn.png"
              alt=""
              :class="{
                hidden: showDepositPage === 2,
                'block group-hover:hidden': showDepositPage !== 2,
              }"
            />

            <div class="absolute text-[14px] font-bold">
              {{ $t("MemberFunction.Deposit.BankDeposit") }}
            </div>
          </button>
          <button
            class="group relative flex justify-center items-center mr-[1vw]"
            @click="changeDepositPage(7)"
            v-if="!!CreditCardInfo"
            :class="{
              'text-secondary': showDepositPage === 7,
              'text-primary hover:text-secondary': showDepositPage !== 7,
            }"
          >
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn_p.png"
              alt=""
              :class="{
                block: showDepositPage === 7,
                'hidden group-hover:block': showDepositPage !== 7,
              }"
            />
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn.png"
              alt=""
              :class="{
                hidden: showDepositPage === 7,
                'block group-hover:hidden': showDepositPage !== 7,
              }"
            />
            <div class="absolute text-[14px] font-bold">
              {{ $t("MemberFunction.Deposit.CreditCardDeposit") }}
            </div>
          </button>
          <button
            class="group relative flex justify-center items-center mr-[1vw]"
            @click="changeDepositPage(3)"
            v-if="!!ATMInfo"
            :class="{
              'text-secondary': showDepositPage === 3,
              'text-primary hover:text-secondary': showDepositPage !== 3,
            }"
          >
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn_p.png"
              alt=""
              :class="{
                block: showDepositPage === 3,
                'hidden group-hover:block': showDepositPage !== 3,
              }"
            />
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn.png"
              alt=""
              :class="{
                hidden: showDepositPage === 3,
                'block group-hover:hidden': showDepositPage !== 3,
              }"
            />
            <div class="absolute text-[14px] font-bold">
              {{ $t("MemberFunction.Deposit.ATMDeposit") }}
            </div>
          </button>
          <button
            class="group relative flex justify-center items-center mr-[1vw]"
            @click="changeDepositPage(4)"
            v-if="!!StoreInfo"
            :class="{
              'text-secondary': showDepositPage === 4 || showDepositPage === 98,
              'text-primary hover:text-secondary':
                showDepositPage !== 4 && showDepositPage !== 98,
            }"
          >
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn_p.png"
              alt=""
              :class="{
                block: showDepositPage === 4 || showDepositPage === 98,
                'hidden group-hover:block':
                  showDepositPage !== 4 && showDepositPage !== 98,
              }"
            />
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn.png"
              alt=""
              :class="{
                hidden: showDepositPage === 4 || showDepositPage === 98,
                'block group-hover:hidden':
                  showDepositPage !== 4 && showDepositPage !== 98,
              }"
            />
            <div class="absolute text-[14px] font-bold">
              {{ $t("MemberFunction.Deposit.StoreDeposit") }}
            </div>
          </button>
          <button
            class="group relative flex justify-center items-center mr-[1vw]"
            @click="changeDepositPage(6)"
            v-if="USDTInfo"
            :class="{
              'text-secondary': showDepositPage === 6,
              'text-primary hover:text-secondary': showDepositPage !== 6,
            }"
          >
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn_p.png"
              alt=""
              :class="{
                block: showDepositPage === 6,
                'hidden group-hover:block': showDepositPage !== 6,
              }"
            />
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn.png"
              alt=""
              :class="{
                hidden: showDepositPage === 6,
                'block group-hover:hidden': showDepositPage !== 6,
              }"
            />
            <div class="absolute text-[14px] font-bold">
              {{ $t("MemberFunction.Deposit.USDTDeposit") }}
            </div>
          </button>
          <button
            class="group relative flex justify-center items-center"
            @click="changeDepositPage(99)"
            :class="{
              'text-secondary': showDepositPage === 99,
              'text-primary hover:text-secondary': showDepositPage !== 99,
            }"
          >
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn_p.png"
              alt=""
              :class="{
                block: showDepositPage === 99,
                'hidden group-hover:block': showDepositPage !== 99,
              }"
            />
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn.png"
              alt=""
              :class="{
                hidden: showDepositPage === 99,
                'block group-hover:hidden': showDepositPage !== 99,
              }"
            />
            <div class="absolute text-[14px] font-bold">
              {{ $t("MemberFunction.Deposit.DepositRecord") }}
            </div>
          </button>
        </div>
        <div class="relative member-in-bg w-full h-full">
          <!-- main -->
          <template v-if="Object.values(MemberRecharge2List).length > 0">
            <OnlineDeposit v-if="showDepositPage === 1" />
            <CompanyDeposit v-else-if="showDepositPage === 2" />
            <ATMDeposit v-else-if="showDepositPage === 7" :Info="CreditCardInfo" />
            <ATMDeposit v-else-if="showDepositPage === 3" :Info="ATMInfo" />
            <StoreDeposit
              v-else-if="showDepositPage === 4"
              :Info="StoreInfo"
              @changeDepositPage="changeDepositPage"
            />
            <UploadDeposit v-else-if="showDepositPage === 5" />
            <USDTDeposit v-else-if="showDepositPage === 6" />
            <AddStoreDeposit
              v-else-if="showDepositPage === 98"
              @changeDepositPage="changeDepositPage"
            />
            <RecordDeposit v-show="showDepositPage === 99" />
          </template>
          <div v-else class="relative submitting"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
@/components/menberFunction/depositPage/storeDeposit.vue
