<script setup lang="ts">
import { ref, watchEffect } from "vue";
import { RouterView } from "vue-router";
import { storeToRefs } from "pinia";

import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import { useLogin } from "@/stores/login";

import HeaderWeb from "./header/headerWeb.vue";
// import ContentWeb from './content/contentWeb.vue';
import FooterWeb from "./footer/footerWeb.vue";
import FooterAPP from "./footer/footerAPP.vue";

import LogoutAlert from "@/components/menberFunction/logoutAlert.vue";
import ContentRankingListDateAlert from "@/components/content/RankingList/contentRankingListDateAlert.vue";
import ContentRankingListTypeAlert from "@/components/content/RankingList/contentRankingListTypeAlert.vue";
import ContentDiscountAlert from "@/components/content/Discount/contentDiscountAlert.vue";

import ChangPasswordAlert from "@/components/menberFunction/changePasswordAlert.vue";
import ChangeTradePasswordAlert from "@/components/menberFunction/changeTradePasswordAlert.vue";
import MailBoxAlert from "@/components/menberFunction/mailBoxAlert.vue";
import MailBoxAlert2 from "@/components/menberFunction/mailBoxAlert2.vue";
import BetRecordAlert from "@/components/menberFunction/betRecordAlert.vue";
import TradeRecordAlert from "@/components/menberFunction/tradeRecordAlert.vue";
import MemberListAlert from "@/components/menberFunction/memberListAlert.vue";

import MemberAddBankAlert from "@/components/menberFunction/memberAddBankAlert.vue";
import MemberAddBankAlert2 from "@/components/menberFunction/memberAddBankAlert2.vue";
import MemberAddBankAlert3 from "@/components/menberFunction/memberAddBankAlert3.vue";

import PromoteDeleteAlert from "@/components/menberFunction/promoteDeleteAlert.vue";
import PromoteAddAlert from "@/components/menberFunction/promoteAddAlert.vue";
import PromotionAlert from "@/components/menberFunction/promotionAlert.vue";

import WithdrawPageAlert from "@/components/menberFunction/withdrawPageAlert.vue";
import DelBankAlert from "@/components/menberFunction/DelBankAlert.vue";
import BankVerifiedAlert from "@/components/menberFunction/bankVerifiedAlert.vue";

import NewsAlert from "@/components/content/NewsAlert/newsAlert.vue";
import NewsContentAlert from "@/components/content/NewsAlert/newsContentAlert.vue";
import LoginAlert from "@/components/login/loginAlert.vue";

const memberService = useMemberService();
const store = useCounterStore();
const login = useLogin();
const { isLoginAlert } = storeToRefs(login);
const {
  isShowAlert,
  isNewsAlert,
  isNewsContentAlert,
  isLogoutAlert,
  isContentRankingListDateAlert,
  isContentRankingListTypeAlert,
  isContentDiscountAlertAlert,

  // member
  isChangePasswordAlert,
  isChangeTradePasswordAlert,
  isMailBoxAlert,
  isMailBoxAlert2,
  isBetRecordAlert,
  isTradeRecordAlert,
  isUserListAlert,

  isAddBankAlert,
  isAddBankAlert2,
  isAddBankAlert3,
  isPromoteDeleteAlert,
  isPromoteAddAlert,
  isPromotionAlert,
  isWithdrawPageAlert,
  isDeleteBankAlert,
  isBankVerifiedAlert,
} = storeToRefs(store);

function toggleAlert() {
  store.toggleAlertBg();
  store.hiddenAllAlert();
}

const alertAnimate = ref<boolean>(false);
function openLoginAlert() {
  setTimeout(() => {
    alertAnimate.value = true;
  }, 300);
  setTimeout(() => {
    alertAnimate.value = false;
  }, 1500);
}
watchEffect(() => {
  if (isLoginAlert.value) {
    openLoginAlert();
  }
});

function closeHeaderPhotoNavbar() {
  store.closeHeaderPhotoNavbar();
}
</script>

<template>
  <div
    class="relative bg-[url('@image/memberFunction/01/bg_01.png')] min-h-[100dvh] bg-scroll bg-cover bg-repeat bg-top"
    @click="closeHeaderPhotoNavbar">
    <div
      class="absolute top-0 left-0 bg-black/50 w-full h-full z-[80]"
      @click.stop="toggleAlert"
      v-if="isShowAlert">
      <NewsAlert @click.stop class="z-20" v-if="isNewsAlert" />
      <NewsContentAlert @click.stop class="z-20" v-if="isNewsContentAlert" />
      <ContentRankingListDateAlert
        @click.stop
        class="z-20"
        v-if="isContentRankingListDateAlert" />
      <ContentRankingListTypeAlert
        @click.stop
        class="z-20"
        v-if="isContentRankingListTypeAlert" />
      <ContentDiscountAlert
        @click.stop
        class="z-20"
        v-if="isContentDiscountAlertAlert" />
      <LogoutAlert @click.stop class="z-20" v-if="isLogoutAlert" />

      <ChangPasswordAlert
        @click.stop
        class="z-50"
        @toggleAlert="toggleAlert"
        v-if="isChangePasswordAlert" />
      <ChangeTradePasswordAlert
        @click.stop
        class="z-50"
        @toggleAlert="toggleAlert"
        v-if="isChangeTradePasswordAlert" />
      <MailBoxAlert @click.stop class="z-50" v-if="isMailBoxAlert" />
      <MailBoxAlert2 @click.stop class="z-50" v-if="isMailBoxAlert2" />

      <BetRecordAlert @click.stop class="z-50" v-if="isBetRecordAlert" />
      <TradeRecordAlert @click.stop class="z-50" v-if="isTradeRecordAlert" />

      <MemberListAlert @click.stop class="z-50" v-if="isUserListAlert" />
      <MemberAddBankAlert @click.stop class="z-50" v-if="isAddBankAlert" />
      <MemberAddBankAlert2 @click.stop class="z-50" v-if="isAddBankAlert2" />
      <MemberAddBankAlert3 @click.stop class="z-50" v-if="isAddBankAlert3" />

      <PromoteDeleteAlert
        @click.stop
        class="z-50"
        v-if="isPromoteDeleteAlert" />
      <PromoteAddAlert @click.stop class="z-50" v-show="isPromoteAddAlert" />
      <PromotionAlert @click.stop class="z-50" v-if="isPromotionAlert" />

      <WithdrawPageAlert @click.stop class="z-50" v-if="isWithdrawPageAlert" />

      <DelBankAlert @click.stop class="z-50" v-if="isDeleteBankAlert" />
      <BankVerifiedAlert @click.stop class="z-50" v-if="isBankVerifiedAlert" />
    </div>

    <div
      class="absolute top-0 left-0 bg-black/50 w-full h-full z-[999]"
      v-if="isLoginAlert">
      <transition name="zoom" mode="out-in">
        <div v-if="alertAnimate">
          <LoginAlert />
        </div>
      </transition>
    </div>
    <HeaderWeb />
    <RouterView />
    <!-- <ContentWeb /> -->
    <FooterWeb class="hidden md:flex" />
    <FooterAPP class="z-30 block md:hidden" />
  </div>
</template>

<style scoped>
.zoom-enter-active {
  transform: scale(0);
  transition: all 0.1s ease-out;
}
.zoom-leave-active {
  transition: all 0.1s ease-in; /* 过渡动画的属性和持续时间 */
}

.zoom-leave-to {
  transform: scale(0);
}

.zoom-enter-to {
  transform: scale(1);
}
</style>
