<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import { useLogin } from "@/stores/login";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";

interface TransferPageData {
  nId: string;
  money: string;
  pwd: string;
}

const route = useRoute();

const memberService = useMemberService();
const store = useCounterStore();
const login = useLogin();

const { transferPageMsg } = storeToRefs(memberService);
const { isWithdrawPageAlert } = storeToRefs(store);
const { memberData } = storeToRefs(login);

const transferPageForm = ref<null | any>(null);
const FormsAccount = ref<string>("");
const FormnId = ref<string>("");
async function submit(formValues: TransferPageData) {
  await memberService.sendTransferPage(formValues);
  // await transferPageForm.value.resetForm();
}
onMounted(() => {
  FormnId.value = route.query.nId ? route.query.nId.toString() : "";
  FormsAccount.value = route.query.sAccount
    ? route.query.sAccount.toString()
    : "";
  // route.query
});
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] gap-y-[1dvh] block px-[1em] pb-0 md:pb-[3rem] pt-[1em] md:pt-[3rem]"
  >
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9">
      <div
        class="px-0 pt-0 md:px-[5vw] md:pt-[2dvh] relative z-10 web-gold-border rounded-[24px]"
      >
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.TradeRecord.MemberTransfer") }}
        </p>
        <div class="px-[1em] pt-[1em] pb-[25dvh] md:pb-0">
          <div
            class="relative member-bg-border w-full h-full py-[2em] px-[1em] md:py-[3em] md:px-[3em]"
          >
            <div
              v-show="memberData.nRank === '0'"
              class="relative submitting"
            ></div>
            <VForm
              v-show="memberData.nRank !== '0'"
              @submit="submit"
              ref="transferPageForm"
              v-slot="{ isSubmitting }"
            >
              <div class="text-primary grid grid-cols-1 grid-rows-1 gap-1">
                <div class="flex text-[14px] md:text-[20px] mt-[.5em]">
                  <p class="mr-[1em]">
                    {{ $t("MemberFunction.TradeRecord.MemberAccount") }}
                  </p>
                  <p>{{ FormsAccount }}</p>
                </div>
                <label
                  for="nId"
                  class="flex-col items-start text-[14px] md:text-[20px] mt-[.5em] hidden"
                >
                  <p class="mr-[1em]">
                    {{ $t("MemberFunction.TradeRecord.MemberId") }}
                  </p>
                  <VField
                    class="bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md px-[10px]"
                    name="nId"
                    v-model="FormnId"
                    :label="$t('MemberFunction.TradeRecord.MemberId')"
                    type="text"
                    :rules="{ required: true }"
                    disabled
                  />
                  <ErrorMessage
                    name="nId"
                    class="text-primary md:ml-3 ml-0 text-[12px] text-center"
                  />
                </label>
                <label
                  for="money"
                  class="flex flex-col items-start text-[14px] md:text-[20px] mt-[.5em]"
                >
                  <p class="mr-[1em]">
                    {{ $t("MemberFunction.TradeRecord.TransferAmount") }}
                  </p>
                  <VField
                    class="bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md px-[10px]"
                    name="money"
                    :label="$t('MemberFunction.TradeRecord.TransferAmount')"
                    type="number"
                    :rules="{ required: true, min_value: 1 }"
                  />
                  <p class="text-[12px] md:text-[14px]">
                    {{ $t("MemberFunction.TradeRecord.Balance") }}:
                    {{
                      store.getClearPotAndAddCommas(
                        parseInt(memberData.nMoney).toString()
                      )
                    }}
                  </p>
                  <ErrorMessage
                    name="money"
                    class="text-red-600 md:ml-3 ml-0 text-[12px] text-center"
                  />
                </label>

                <label
                  for="pwd"
                  class="flex flex-col items-start text-[14px] md:text-[20px] mt-[.5em]"
                >
                  <p class="mr-[1em]">
                    {{ $t("MemberFunction.TradeRecord.TradePassword") }}
                  </p>
                  <VField
                    class="bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md px-[10px]"
                    name="pwd"
                    :label="$t('MemberFunction.TradeRecord.TradePassword')"
                    type="password"
                    :rules="{ required: true }"
                  />
                  <ErrorMessage
                    name="pwd"
                    class="text-red-600 md:ml-3 ml-0 text-[12px] text-center"
                  />
                </label>

                <p class="text-center text-red-400 mt-[12px]">
                  {{ transferPageMsg }}
                </p>
              </div>
              <div
                class="absolute md:static bottom-[-30%] md:bottom-[0] left-1/2 mt-[2em] -translate-x-1/2 md:translate-x-0 flex items-center justify-center"
              >
                <button
                  type="submit"
                  class="btn-y-border px-[2em] py-[.2em]"
                  :class="{ submitting: isSubmitting }"
                  :disabled="isSubmitting"
                >
                  <p class="text-[12px] md:text-[16px] text-secondary">
                    {{
                      isSubmitting
                        ? ""
                        : $t("MemberFunction.TradeRecord.Submit")
                    }}
                  </p>
                </button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
