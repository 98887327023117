<template>
  <footer
    class="bg-[url('@image/memberFunction/02_web/ground.png')] flex-col justify-center items-center"
  >
    <div class="flex flex-col justify-center items-center mt-[3dvh]">
      <img
        src="@image/memberFunction/02_web/foot_logo.png"
        alt=""
        class="max-w-[80%]"
      />
    </div>
    <div class="w-[80vw] h-[3px] bg-white my-[3dvh]"></div>
    <ul class="flex items-center justify-center mb-[3dvh]">
      <li class="mr-[32px]">
        <button
          class="text-[16px] text-primary/80 relative after:absolute after:h-full after:w-[1px] after:bg-white after:-right-[25%] after:-translate-x-1/2"
          disabled
        >
          {{ $t("Footer.Terms") }}
        </button>
      </li>
      <li class="mr-[32px]">
        <button
          class="text-[16px] text-primary/80 relative after:absolute after:h-full after:w-[1px] after:bg-white after:-right-[25%] after:-translate-x-1/2"
          disabled
        >
          {{ $t("Footer.Privacy") }}
        </button>
      </li>
      <li class="mr-[32px]">
        <button
          class="text-[16px] text-primary/80 relative after:absolute after:h-full after:w-[1px] after:bg-white after:-right-[25%] after:-translate-x-1/2"
          disabled
        >
          {{ $t("Footer.Disclaimer") }}
        </button>
      </li>
      <li class="mr-[32px]">
        <button
          class="text-[16px] text-primary/80 relative after:absolute after:h-full after:w-[1px] after:bg-white after:-right-[25%] after:-translate-x-1/2"
          disabled
        >
          {{ $t("Footer.TechnicalIssues") }}
        </button>
      </li>
      <li class="mr-[32px]">
        <button
          class="text-[16px] text-primary/80 relative after:absolute after:h-full after:w-[1px] after:bg-white after:-right-[25%] after:-translate-x-1/2"
          disabled
        >
          {{ $t("Footer.Issues") }}
        </button>
      </li>
      <li>
        <button class="text-[16px] text-primary/80 relative" disabled>
          {{ $t("Footer.ContactUs") }}
        </button>
      </li>
    </ul>
    <p class="text-center text-[16px] text-primary/80 mb-[3dvh]">
      版權所有 ©2020-2021 Demo Casino 保留所有權
    </p>
  </footer>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
