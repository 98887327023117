<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useCounterStore } from "@/stores/counter";
import { useContentInfo } from "@/stores/contentInfo";
import { useLogin } from "@/stores/login";
import { useErrorAlert } from "@/stores/errorAlert";

import VIPFlagImage1 from "@image/memberFunction/common/VIP1.png";
import VIPFlagImage2 from "@image/memberFunction/common/VIP2.png";
import VIPFlagImage3 from "@image/memberFunction/common/VIP3.png";
import VIPFlagImage4 from "@image/memberFunction/common/VIP4.png";
import VIPFlagImage5 from "@image/memberFunction/common/VIP5.png";

import image1 from "@image/memberFunction/01/app_shop.png";
import image2 from "@image/memberFunction/01/app_download.png";
import image3 from "@image/memberFunction/01/app_deposit.png";
import image4 from "@image/memberFunction/01/app_pick.png";
import image5 from "@image/memberFunction/01/app_pick.png";
import image6 from "@image/memberFunction/01/app_lv.png";
import image7 from "@image/memberFunction/01/app_money.png";
const router = useRouter();
const { t } = useI18n();

const login = useLogin();
const store = useCounterStore();
const contentInfoStore = useContentInfo();
const errorAlert = useErrorAlert();
const { memberData, isLogin, isLoadingLogin, isCashMember } = storeToRefs(login);
const { UserAgentWebview } = storeToRefs(store);

const headerInfoLists = computed(() => {
  if (isLogin.value) {
    if (isCashMember.value) {
      return [
        t("Context.Store"),
        t("Context.Application"),
        t("Context.Deposit"),
        t("Context.Withdraw"),
        t("Context.Wallet"),
        t("Context.Rank"),
      ];
    } else {
      return [
        "none",
        "none",
        "none",
        "none",
        t("Context.Store"),
        t("Context.Application"),
      ];
    }

  } else {
    return [
      t("Context.Store"),
      t("Context.Application"),
      t("Context.Deposit"),
      t("Context.Withdraw"),
      t("Context.Wallet"),
      t("Context.Rank"),
    ];
  }
});
const imageFile = (title: string) => {
  let getImage;
  switch (title) {
    case t("Context.Store"):
      getImage = image1;
      break;
    case t("Context.Application"):
      getImage = image2;
      break;
    case t("Context.Deposit"):
      getImage = image3;
      break;
    case t("Context.Withdraw"):
      getImage = image4;
      break;
    case t("Context.Transfer"):
      getImage = image5;
      break;
    case t("Context.Rank"):
      getImage = image6;
      break;
    case t("Context.Wallet"):
      getImage = image7;
      break;
    default:
      getImage = "";
      break;
  }
  return getImage;
};
const UserAgent = computed(() => {
  const navigator: any = window.navigator;

  const standalone = navigator.standalone;
  const userAgent = navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  return `${standalone}, ${safari}, ${userAgent}`;
});

function clickInfoLists(title: string) {
  switch (title) {
    case t("Context.Store"):
      //@ts-ignore
      // errorAlert.AddErrorAlert(UserAgent.value + "UserAgentWebview");
      errorAlert.AddErrorAlert(t("Context.ComingSoon"));
      // router.push({ name: "GameLobby" });
      break;
    case t("Context.Application"):
      if (store.UserAgent === "android") {
        window.open(contentInfoStore.AppDownloadUrl, "_blank");
      } else {
        router.push({ name: "APPDownload" });
      }
      break;
    case t("Context.Deposit"):
      if (!isLogin.value) return router.push({ name: "login" });
      router.push({ name: "DepositPage" });
      break;
    case t("Context.Withdraw"):
      if (!isLogin.value) return router.push({ name: "login" });
      router.push({ name: "WithdrawPage" });
      break;
    case t("Context.Transfer"):
      if (!isLogin.value) return router.push({ name: "login" });
      router.push({ name: "TransferPage" });
      break;
    case t("Context.Rank"):
      router.push({ name: "RankingList" });
      break;
    case t("Context.Wallet"):
      if (!isLogin.value) return router.push({ name: "login" });
      router.push({ name: "TakeOverCoin" });
      break;
    default:
      console.log("default");
      break;
  }
}

function loginBtn() {
  router.push({ name: "login" });
}
function registerBtn() {
  router.push({ name: "login" });
}
const getVIPIconImage = (level: string) => {
  let image;
  switch (level) {
    case "1":
    case "0":
      // image = VIPIconImage1;
      image = VIPFlagImage1;
      break;
    case "2":
      // image = VIPIconImage2;
      image = VIPFlagImage2;
      break;
    case "3":
      // image = VIPIconImage3;
      image = VIPFlagImage3;
      break;
    case "4":
      // image = VIPIconImage4;
      image = VIPFlagImage4;
      break;
    case "5":
      // image = VIPIconImage5;
      image = VIPFlagImage5;
      break;
    default:
      image = "";
      break;
  }
  return image;
};
function toMemberManagement() {
  router.push({ name: "MemberManagement" });
}
function toVIPPrivilege() {
  router.push({ name: "VIPPrivilege" });
}
function toTakeOverCoin() {
  router.push({ name: "TakeOverCoin" });
}
</script>

<template>
  <div class="grid grid-cols-12">
    <template v-if="!isLoadingLogin">
      <!-- 登入 -->
      <div
        v-if="isLogin"
        class="flex flex-col items-center justify-start md:justify-center col-span-4 mt-1">
        <div class="grid grid-cols-2 gap-[6px]">
          <button class="relative" @click="toMemberManagement">
            <div
              class="z-10 flex justify-center items-center absolute w-[60px] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
              <img
                src="@image/memberFunction/01/id_01.png"
                alt=""
                class="inline h-[12px]" />
              <span class="text-primary ml-1 mb-[1px] text-[12px]">{{
                t("Context.My")
              }}</span>
            </div>
            <img src="@image/memberFunction/01/my_btn.png" alt="" class="z-0" />
          </button>
          <button
            type="button"
            v-if="
              (JSON.stringify(memberData.aVipData) !== '{}' ||
              !memberData.aVipData) && isCashMember
            "
            @click="toVIPPrivilege"
            class="pr-[6px]">
            <div class="relative top-[-3px]" v-if="memberData.aVipData?.nVipLv">
              <img
                :src="getVIPIconImage(memberData.aVipData.nVipLv)"
                alt=""
                class="w-full" />
            </div>
          </button>
        </div>
        <button
          class="coin-bg-neon-border text-center w-[90%] mr-auto flex justify-start items-center mt-2"
          @click="toTakeOverCoin">
          <img
            src="@image/memberFunction/14/coin_icon.png"
            alt=""
            class="w-[20px] relative left-1" />
          <p class="text-primary text-center w-full">
            {{ store.getClearPotAndAddCommas(memberData.nMoney).toString() }}
          </p>
        </button>
      </div>

      <!-- 未登入 -->
      <div
        v-else
        class="flex flex-col justify-center items-center col-span-4 mt-1">
        <button @click="loginBtn" class="relative">
          <img
            src="@image/memberFunction/common/btn_n.png"
            alt=""
            class="object-cover w-[90px]" />
          <p
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-primary">
            {{ t("Context.Login") }}
          </p>
        </button>
        <button @click="registerBtn" class="relative mt-1">
          <img
            src="@image/memberFunction/common/btn_y.png"
            alt=""
            class="object-cover w-[90px]" />
          <p
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-secondary font-bold">
            {{ t("Context.Register") }}
          </p>
        </button>
      </div>
    </template>

    <ul class="grid grid-cols-6 gap-1 col-span-8 mt-2">
      <li
        class="flex flex-col justify-center items-center col-span-1"
        :class="isLogin ? '' : ''"
        v-for="item in headerInfoLists"
        :key="item">
        <template v-if="item === 'none'">
        </template>
        <template v-else>
        <button @click="clickInfoLists(item)">
          <div class="hover:opacity-50">
            <img
              :src="imageFile(item)"
              alt=""
              class="object-contain w-[9vw] h-[9vw] md:w-[64px] md:h-[64px]" />
          </div>
        </button>
        <p class="text-[12px] text-center text-primary font-bold mx-auto">
          {{ item }}
        </p>
        </template>
      </li>
    </ul>
  </div>
</template>
<style>
.bg-100 {
  background-size: 100% 100%;
}

.coin-bg-neon-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/01/coin_bg.png") 1;
  -webkit-border-image: url("@image/memberFunction/01/coin_bg.png") 1;
  -o-border-image: url("@image/memberFunction/01/coin_bg.png") 1;
  border-image: url("@image/memberFunction/01/coin_bg.png") 1 fill;
}
</style>
