import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { defineStore } from "pinia";

export const useCounterStore = defineStore("counter", () => {
  const router = useRouter();

  // headerPhotoNavbar
  const isHeaderPhotoNavbar = ref<boolean>(false);
  function toggleHeaderPhotoNavbar() {
    isHeaderPhotoNavbar.value = !isHeaderPhotoNavbar.value;
  }
  function closeHeaderPhotoNavbar() {
    isHeaderPhotoNavbar.value = false;
  }

  const isShowAlert = ref<boolean>(false);
  function toggleAlertBg() {
    isShowAlert.value = !isShowAlert.value;
  }
  function hiddenAlertBg() {
    isShowAlert.value = false;
  }
  function hiddenAllAlert() {
    isNewsAlert.value = false;
    isLogoutAlert.value = false;

    isChangePasswordAlert.value = false;
    isChangeTradePasswordAlert.value = false;
    isTradeRecordAlert.value = false;
    isBetRecordAlert.value = false;
    isUserListAlert.value = false;
    isMailBoxAlert.value = false;
    isMailBoxAlert2.value = false;
    isAddBankAlert.value = false;
    isAddBankAlert2.value = false;
    isAddBankAlert3.value = false;

    isPromoteDeleteAlert.value = false;
    isPromoteAddAlert.value = false;
    isPromotionAlert.value = false;

    isWithdrawPageAlert.value = false;
    isDeleteBankAlert.value = false;
    isBankVerifiedAlert.value = false;

    isContentRankingListDateAlert.value = false;
    isContentRankingListTypeAlert.value = false;

    isContentDiscountAlertAlert.value = false;
  }

  const isNewsAlert = ref<boolean>(false);
  // state = 1顯示 2不顯示
  function saveTodayNewsCookies(state: string) {
    const expirationDate = new Date(+new Date() + 8 * 3600 * 1000);
    expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 6000); // 1 小时的毫秒数
    const expires = expirationDate.toUTCString();
    document.cookie = `today_news_cookie=${state}; expires=${expires}`;
  }
  // 取得cookie
  const TodayNewsCookies = () => {
    let Cookie = "";

    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim(); // 去除首尾空格
      const parts = cookie.split("=");
      const name = parts[0];
      const value = parts[1];

      // 找到了名为 "today_news_cookie" 的 Cookie
      if (name === "today_news_cookie") {
        Cookie = value;
        break;
      }
    }
    return Cookie;
  };
  function openNewsAlert() {
    isNewsAlert.value = true;
  }

  const isNewsContentAlert = ref<boolean>(false);
  function openNewsContentAlert() {
    isNewsContentAlert.value = true;
  }
  function closeNewsContentAlert() {
    isNewsContentAlert.value = false;
  }

  const isLogoutAlert = ref<boolean>(false);
  function openLogoutAlert() {
    isLogoutAlert.value = true;
  }

  const isChangePasswordAlert = ref<boolean>(false);
  function openChangePasswordAlert() {
    isChangePasswordAlert.value = true;
  }
  const isChangeTradePasswordAlert = ref<boolean>(false);
  function openChangeTradePasswordAlert() {
    isChangeTradePasswordAlert.value = true;
  }
  const isTradeRecordAlert = ref<boolean>(false);
  function openTradeRecordAlert() {
    isTradeRecordAlert.value = true;
  }
  const isBetRecordAlert = ref<boolean>(false);
  function openBetRecordAlert() {
    isBetRecordAlert.value = true;
  }
  const isUserListAlert = ref<boolean>(false);
  function openUserListAlert() {
    isUserListAlert.value = true;
  }
  const isMailBoxAlert = ref<boolean>(false);
  function openMailBoxAlert() {
    isMailBoxAlert.value = true;
  }
  const isMailBoxAlert2 = ref<boolean>(false);
  function openMailBoxAlert2() {
    isMailBoxAlert2.value = true;
  }
  const isAddBankAlert = ref<boolean>(false);
  function openAddBankAlert() {
    isAddBankAlert.value = true;
  }
  const isAddBankAlert2 = ref<boolean>(false);
  function openAddBankAlert2() {
    isAddBankAlert2.value = true;
  }
  const isAddBankAlert3 = ref<boolean>(false);
  function openAddBankAlert3() {
    isAddBankAlert3.value = true;
  }

  const isPromoteDeleteAlert = ref<boolean>(false);
  function openPromoteDeleteAlert() {
    isPromoteDeleteAlert.value = true;
  }
  const isPromoteAddAlert = ref<boolean>(false);
  function openPromoteAddAlert() {
    isPromoteAddAlert.value = true;
  }
  const isPromotionAlert = ref<boolean>(false);
  function openPromotionAlert() {
    isPromotionAlert.value = true;
  }

  const isWithdrawPageAlert = ref<boolean>(false);
  function openWithdrawPageAlert() {
    isWithdrawPageAlert.value = true;
  }

  const isDeleteBankAlert = ref<boolean>(false);
  function openDeleteBankAlert() {
    isDeleteBankAlert.value = true;
  }
  const isBankVerifiedAlert = ref<boolean>(false);
  function openBankVerifiedAlert() {
    isBankVerifiedAlert.value = true;
  }

  const isContentRankingListDateAlert = ref<boolean>(false);
  function openContentRankingListDateAlert() {
    isContentRankingListDateAlert.value = true;
  }

  const isContentRankingListTypeAlert = ref<boolean>(false);
  function openContentRankingListTypeAlert() {
    isContentRankingListTypeAlert.value = true;
  }

  const isContentDiscountAlertAlert = ref<boolean>(false);
  function openContentDiscountAlertAlert() {
    isContentDiscountAlertAlert.value = true;
  }

  // 去除小數點
  const getClearPot = (sMoney: string | undefined | number, pot: number) => {
    if (typeof sMoney === "undefined") return "";
    const getNumber = typeof sMoney === "string" ? parseFloat(sMoney) : sMoney;
    const multiplier = 10 ** pot;
    const truncatedNumber = Math.floor(getNumber * multiplier) / multiplier;
    return truncatedNumber.toString();
  };
  // 千分號
  const addCommas = (numString: string) => {
    if (!numString) return "0";
    return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // 去除小數點後, 千分號
  const getClearPotAndAddCommas = (sMoney: string | undefined) => {
    const Money = sMoney ? sMoney : "0";
    return addCommas(getClearPot(Money, 1));
  };

  // 確認使用者裝置
  const UserAgent = computed(() => {
    const ua = navigator.userAgent;
    const android = ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1; // android
    const iOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios
    if (android === true) {
      return "android";
    } else if (iOS === true) {
      return "ios";
    } else {
      return "web";
    }
  });

  const UserAgentWebview = computed(() => {
    const navigator: any = window.navigator;

    const standalone = navigator.standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    return ios ? standalone && safari : userAgent.includes("wv");
  });

  return {
    // headerPhotoNavbar
    isHeaderPhotoNavbar,
    toggleHeaderPhotoNavbar,
    closeHeaderPhotoNavbar,

    isShowAlert,
    toggleAlertBg,
    hiddenAlertBg,
    saveTodayNewsCookies,
    TodayNewsCookies,
    openNewsAlert,
    openNewsContentAlert,
    closeNewsContentAlert,
    openLogoutAlert,
    openPromoteAddAlert,
    openChangePasswordAlert,
    openChangeTradePasswordAlert,
    openTradeRecordAlert,
    openBetRecordAlert,
    openUserListAlert,
    openMailBoxAlert,
    openMailBoxAlert2,
    openAddBankAlert,
    openAddBankAlert2,
    openAddBankAlert3,
    openPromotionAlert,
    openPromoteDeleteAlert,
    openWithdrawPageAlert,
    openDeleteBankAlert,
    openBankVerifiedAlert,
    openContentRankingListDateAlert,
    openContentRankingListTypeAlert,
    openContentDiscountAlertAlert,
    hiddenAllAlert,
    isNewsAlert,
    isNewsContentAlert,
    isLogoutAlert,
    isPromoteAddAlert,
    isChangePasswordAlert,
    isChangeTradePasswordAlert,
    isTradeRecordAlert,
    isBetRecordAlert,
    isUserListAlert,
    isMailBoxAlert,
    isMailBoxAlert2,
    isAddBankAlert,
    isAddBankAlert2,
    isAddBankAlert3,
    isPromotionAlert,
    isPromoteDeleteAlert,
    isWithdrawPageAlert,
    isDeleteBankAlert,
    isBankVerifiedAlert,
    isContentRankingListDateAlert,
    isContentRankingListTypeAlert,
    isContentDiscountAlertAlert,
    getClearPot,
    addCommas,
    getClearPotAndAddCommas,
    UserAgent,
    UserAgentWebview,
  };
});
