<template>
  <div class="sticky top-[10%] mx-auto w-[95vw] md:w-[600px]">
    <div
      class="relative bg-[url('@image/memberFunction/08/detail_board.png')] bg-100 h-[500px] md:h-[650px]"
    >
      <h3
        class="absolute top-[30px] md:top-[42px] left-1/2 -translate-x-1/2 text-center text-secondary text-[20px] font-bold"
      >
        {{ $t("MemberFunction.BankVerified.VerifiedExample") }}
      </h3>
      <div
        class="px-[1em] w-[80vw] md:w-[500px] h-[350px] md:h-[450px] relative top-[20%] left-1/2 -translate-x-1/2 bg-100 text-primary flex flex-col text-[14px] overflow-y-auto"
      >
        <p class="text-green-600 text-[16px] font-bold">
          {{ $t("MemberFunction.BankVerified.CorrectExample") }}
        </p>
        <img
          src="@image/memberFunction/10/05.jpg"
          alt=""
          class="w-full mt-[1dvh]"
        />
        <img
          src="@image/memberFunction/10/04.jpg"
          alt=""
          class="w-full mt-[1dvh]"
        />
        <p class="py-[12px] text-[14px] text-primary">
          {{ $t("MemberFunction.BankVerified.ExampleNotice") }}
        </p>
        <!-- <p class="text-red-600 text-[16px] font-bold">
          {{ $t("MemberFunction.BankVerified.ErrorExample") }}
        </p> -->

        <div
          class="absolute left-1/2 -translate-x-1/2 bottom-[2rem] flex justify-center items-center w-full"
        ></div>
      </div>
    </div>
  </div>
</template>
