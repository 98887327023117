import { ref, computed, watchEffect } from "vue";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import { getMUserC } from "@/assets/API/API";

export const useGameIFrame = defineStore("GameIFrame", () => {
  const router = useRouter();
  const gameUrl = ref<string>("");

  async function ToGameIFrame(url: string) {
    gameUrl.value = url;
    router.push({ name: "GameIFramePage" });
  }

  // 檢查自己是否被嵌套
  function checkSelfInIframe() {
    if (self !== top) {
      parent.postMessage("reload");
    }
  }
  function IFrameMessageOn() {
    window.addEventListener("message", receiveMessage);
  }
  function IFrameMessageOff() {
    window.removeEventListener("message", receiveMessage);
  }
  function receiveMessage(e: any) {
    if (
      e.origin ===
      window.location.protocol + "//" + window.location.hostname
    ) {
      if (e.data === "reload") {
        router.push({ name: "home" });
        return;
      }
    }
  }
  return {
    gameUrl,
    ToGameIFrame,
    IFrameMessageOn,
    IFrameMessageOff,
    checkSelfInIframe,
  };
});
