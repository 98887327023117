<script setup lang="ts">
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";

const store = useCounterStore();
const memberService = useMemberService();

function openPromoteDeleteAlert(nId: string) {
  store.toggleAlertBg();
  store.openPromoteDeleteAlert();
  memberService.getDeletePromotionId(nId);
}

function openPromoteAddAlert() {
  store.toggleAlertBg();
  store.openPromoteAddAlert();
}

function openPromotionAlert(nId: string) {
  store.toggleAlertBg();
  store.openPromotionAlert();
  memberService.getPromotionAlertContent(nId);
}

function goPromotionPage(page: number) {
  memberService.goPromotionPage(page);
}
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-[1vw] pb-[10dvh] pt-0 md:pt-[3rem]"
  >
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9">
      <div
        class="px-0 pt-0 md:px-[5vw] md:pt-[2dvh] relative z-10 web-gold-border rounded-[24px]"
      >
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.Promote.Promote") }}
        </p>
        <div class="px-[1em] pt-[1em] pb-[10dvh]">
          <div
            class="relative member-bg-border mb-[2rem] py-[0px] px-[0] min-h-[60dvh] md:min-h-[60dvh]"
          >
            <!-- main -->
            <div class="text-primary text-[12px] md:text-[16px] w-full px-0">
              <div class="overflow-auto">
                <table class="w-[100%] md:w-full table-auto">
                  <thead class="border-b">
                    <tr>
                      <th
                        class="w-[25%] h-[45px] md:h-[50px] pl-[12px] text-left"
                      >
                        {{ $t("MemberFunction.Promote.LinkName") }}
                      </th>
                      <th class="w-[25%] h-[45px] md:h-[50px] text-left">
                        {{ $t("MemberFunction.Promote.Layout") }}
                      </th>
                      <th
                        class="h-[45px] md:h-[50px] text-end pr-[12px]"
                        colspan="2"
                      >
                        {{ $t("MemberFunction.Promote.Setting") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="relative odd:bg-black/50 bg-white/10 last:after:h-[0px] after:absolute after:h-[1px] after:bg-white after:left-1/2 after:-translate-x-1/2 after:w-[calc(100%)]"
                      v-for="item in memberService.CurrentPromotion"
                      :key="'promotion' + item.nId"
                    >
                      <td class="h-[45px] md:h-[50px] pl-[12px]">
                        {{ item.sTitle }}
                      </td>
                      <td class="h-[45px] md:h-[50px]">
                        {{
                          item.nRank === "0"
                            ? $t("MemberFunction.Promote.Member")
                            : $t("MemberFunction.Promote.Proxy")
                        }}
                      </td>
                      <td
                        class="h-[45px] md:h-[50px] flex justify-end pr-[12px]"
                      >
                        <button
                          type="button"
                          class="relative"
                          @click="openPromotionAlert(item.nId)"
                        >
                          <img
                            src="@image/memberFunction/common/btn_n.png"
                            alt=""
                            class="h-[60%] md:h-[70%] mx-auto"
                          />
                          <p
                            class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[12px] md:text-[14px]"
                          >
                            {{ $t("MemberFunction.Promote.Details") }}
                          </p>
                        </button>
                        <button
                          type="button"
                          class="relative"
                          @click="openPromoteDeleteAlert(item.nId)"
                        >
                          <img
                            src="@image/memberFunction/13/新增推廣連結按鈕_02.png"
                            alt=""
                            class="h-[20px] md:h-[25px] mx-1 md:ml-[1vw]"
                          />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th
                        class="h-[45px] md:h-[50px] text-center pl-[6px] lg:pl-[2em]"
                      ></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div
                class="flex items-center justify-center absolute bottom-[10px] left-1/2 -translate-x-1/2 text-[12px]"
              >
                <button
                  type="button"
                  :disabled="memberService.PromotionPage === 1"
                  @click="goPromotionPage(memberService.PromotionPage - 1)"
                  class="w-[10px] md:w-[14px]"
                >
                  <img src="@image/memberFunction/common/頁面跳轉.png" alt="" />
                </button>
                <ul class="flex text-primary">
                  <li
                    v-for="index in memberService.TotalPromotionPage"
                    :key="`page${index}`"
                    class="mx-[6px] text-[12px] md:text-[14px]"
                  >
                    <button
                      @click="goPromotionPage(index)"
                      class="font-bold"
                      :class="
                        index === memberService.PromotionPage
                          ? 'text-primary'
                          : 'text-primary/50 hover:text-primary'
                      "
                      :disabled="index === memberService.PromotionPage"
                    >
                      {{ index }}
                    </button>
                  </li>
                </ul>
                <button
                  type="button"
                  class="rotate-180 w-[10px] md:w-[14px]"
                  :disabled="
                    memberService.PromotionPage ===
                    memberService.TotalPromotionPage
                  "
                  @click="goPromotionPage(memberService.PromotionPage + 1)"
                >
                  <img src="@image/memberFunction/common/頁面跳轉.png" alt="" />
                </button>
              </div>
              <div
                class="absolute -bottom-[3.5rem] left-1/2 -translate-x-1/2 flex justify-center items-center text-[12px] md:text-[14px] w-[110%]"
              >
                <button
                  type="button"
                  class="btn-y-border"
                  @click.stop="openPromoteAddAlert"
                >
                  <p
                    class="z-10 text-secondary font-bold px-[2em] py-[.2em] text-[16px] md:text-[18px]"
                  >
                    {{ $t("MemberFunction.Promote.AddPromoteLink") }}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
</style>
