<script setup lang="ts">
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import * as APItypes from "@/assets/API/APItype";
import { useCounterStore } from "@/stores/counter";
import { useLogin } from "@/stores/login";
import { useMemberService } from "@/stores/memberFunction/memberService";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";
const store = useCounterStore();
const login = useLogin();
const memberService = useMemberService();
const router = useRouter();
const { t } = useI18n();

const nRank = ref<string>("");
const sAccount = ref<string>("");

const getAccount = ref<string>("");

interface TypeList {
  title: string;
  type: string;
  isSelect: boolean;
}
const typeList = ref<TypeList[]>([
  {
    title: t("MemberFunction.MemberList.All"),
    type: "2",
    isSelect: true,
  },
  {
    title: t("MemberFunction.MemberList.Proxy"),
    type: "1",
    isSelect: false,
  },
  {
    title: t("MemberFunction.MemberList.Member"),
    type: "0",
    isSelect: false,
  },
]);

function selectRank(rank: string, index: number) {
  nRank.value = rank;
  sAccount.value = "";
  getAccount.value = "";
  getUserListData();
  selectRankBtn(index);
}
function selectRankBtn(index: number) {
  typeList.value.forEach((type, typeIndex) => {
    type.isSelect = false;
    if (typeIndex === index) {
      type.isSelect = true;
    }
  });
}

function findAccount() {
  if (getAccount.value === "" || getAccount.value === sAccount.value) return;
  sAccount.value = getAccount.value;
  nRank.value = "";
  getUserListData();
}

const postData = (): APItypes.UserListData => {
  const Data: APItypes.UserListData = {
    m_user_c: login.CookieMUC(),
  };
  if (nRank.value !== "") Data.nRank = nRank.value;
  if (sAccount.value !== "") {
    getAccount.value = sAccount.value;
    Data.sAccount = sAccount.value;
  }
  return Data;
};

function goUserListPage(page: number) {
  memberService.goUserListPage(page, postData());
}

function getUserListData() {
  memberService.getUserListData(postData());
}

function openUserListAlert(nId: string) {
  store.toggleAlertBg();
  store.openUserListAlert();
  memberService.getUserListAlertContent(nId);
}

function toTransferPage(nId: string, sAccount: string) {
  router.push({ name: "TransferPage", query: { nId, sAccount } });
}

// 轉帳
// function openUserListAlert2(nId: string) {
//   store.toggleAlertBg();
//   store.openUserListAlert2();
//   memberService.getUserListAlert2Content(nId);
// }
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] gap-y-[1dvh] block px-[1em] pb-0 md:pb-[3rem] pt-[1em] md:pt-[3rem]">
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9">
      <div
        class="px-0 pt-0 md:px-[5vw] md:pt-[2dvh] relative z-10 web-gold-border rounded-[24px]">
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.MemberList.MemberList") }}
        </p>
        <div class="flex flex-col mb-[1dvh]">
          <div
            class="flex justify-between text-[12px] items-center w-full z-30">
            <ul
              class="flex justify-center md:justify-start items-center mt-[1dvh]">
              <li
                class="flex justify-center md:justify-start items-center"
                v-for="(item, index) in typeList"
                :key="item.title + 'memberList'">
                <button
                  class="group relative w-[80%] md:w-[6em] xl:w-[7em] text-[12px] md:text-[14px] mr-0 md:mr-[1vw]"
                  @click="selectRank(item.type, index)"
                  :class="{
                    'text-primary': !item.isSelect,
                    'text-secondary': item.isSelect,
                  }">
                  <img
                    src="@image/memberFunction/common/會員選擇無.png"
                    alt=""
                    v-show="!item.isSelect" />
                  <img
                    src="@image/memberFunction/common/會員選擇.png"
                    alt=""
                    v-show="item.isSelect" />
                  <p
                    class="absolute top-1/2 -translate-y-1/2 left-[50%] -translate-x-1/2">
                    {{ item.title }}
                  </p>
                </button>
              </li>
            </ul>
            <label for="memberNumber" class="relative ml-1">
              <button
                @click="findAccount"
                class="h-[30px] w-[30px] absolute z-10 p-2">
                <img src="@image/memberFunction/09/搜尋icon.png" alt="" />
              </button>
              <img
                src="@image/memberFunction/09/搜尋框.png"
                alt=""
                class="opacity-1 h-[30px]" />
              <input
                v-model="getAccount"
                type="text"
                class="absolute bg-transparent bg-100 w-full h-full top-0 left-0 rounded-[6px] text-center text-primary placeholder:text-primary"
                placeholder="會員編號" />
            </label>
          </div>
        </div>
        <div
          class="relative member-bg-border mb-[2rem] mt-0 md:mt-[2dvh] py-[0px] px-[0] min-h-[60dvh] md:min-h-[70dvh]">
          <!-- main -->
          <div class="text-primary text-[12px] md:text-[14px] w-full px-0">
            <div class="overflow-auto">
              <table class="w-[100%] md:w-full table-auto">
                <thead>
                  <tr>
                    <th
                      class="w-[20%] h-[45px] text-start pl-[6px] ml-[6px] bg-white/0 md:bg-white/20 rounded-tl-md">
                      {{ $t("MemberFunction.MemberList.MemberNumber") }}
                    </th>
                    <th
                      class="w-[15%] h-[45px] text-start bg-white/0 md:bg-white/20"></th>
                    <th
                      class="w-[25%] h-[45px] text-start bg-white/0 md:bg-white/20">
                      {{ $t("MemberFunction.MemberList.Amount") }}
                    </th>
                    <th
                      class="h-[45px] text-center pr-[6px] bg-white/0 md:bg-white/20 rounded-tr-md">
                      {{ $t("MemberFunction.MemberList.Setting") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="relative bg-black/50 last:after:h-[0px] after:absolute after:h-[1px] after:bg-white after:left-1/2 after:-translate-x-1/2 after:w-[calc(100%-12px)]"
                    v-for="item in memberService.CurrentUserList"
                    :key="'UserList' + item.nId">
                    <td class="h-[45px] pl-[6px]">
                      {{ item.sAccount.replace(/\*/g, "") }}
                    </td>
                    <td class="h-[45px]">
                      {{
                        item.nRank === "0"
                          ? $t("MemberFunction.MemberList.Member")
                          : item.nRank === ""
                          ? $t("MemberFunction.MemberList.Undefined")
                          : $t("MemberFunction.MemberList.Proxy")
                      }}
                    </td>
                    <td class="h-[45px]">
                      {{
                        store.getClearPotAndAddCommas(
                          parseInt(item.nMoney).toString()
                        )
                      }}
                    </td>
                    <td
                      class="h-[45px] flex justify-center items-center pr-[6px]">
                      <button
                        type="button"
                        class="btn-n-border mr-1"
                        @click="toTransferPage(item.nId, item.sAccount)">
                        <p class="px-[1em] md:px-[2em] py-[.1em] text-[12px]">
                          {{ $t("MemberFunction.MemberList.Transfer") }}
                        </p>
                      </button>
                      <button
                        type="button"
                        class="btn-n-border"
                        @click="openUserListAlert(item.nId)">
                        <p class="px-[1em] md:px-[2em] py-[.1em] text-[12px]">
                          {{ $t("MemberFunction.MemberList.Details") }}
                        </p>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th class="h-[45px] text-center pl-[6px]"></th>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div
              class="flex items-center justify-center absolute bottom-[10px] w-full left-1/2 -translate-x-1/2 text-[12px]">
              <button
                type="button"
                :disabled="
                  memberService.UserListPage === 1 ||
                  memberService.TotalUserListPage === 0
                "
                @click="goUserListPage(1)"
                class="text-[10px] mr-[12px] text-primary"
                :class="{
                  'text-primary/50': memberService.UserListPage === 1,
                }"
                v-if="memberService.TotalUserListPage > 1">
                {{ $t("MemberFunction.MemberList.FirstPage") }}
              </button>
              <button
                type="button"
                :disabled="
                  memberService.UserListPage === 1 ||
                  memberService.TotalUserListPage === 0
                "
                @click="goUserListPage(memberService.UserListPage - 1)"
                class="w-[10px]">
                <img src="@image/memberFunction/common/頁面跳轉.png" alt="" />
              </button>
              <div
                class="text-primary bg-gray-600 border border-gray-50/80 rounded px-[12px] mx-[6px]">
                <p>
                  <span class="text-[14px] font-bold">
                    {{ memberService.UserListPage }}
                  </span>
                  <span class="text-[10px] opacity-50">
                    /
                    {{
                      memberService.TotalUserListPage
                        ? memberService.TotalUserListPage
                        : 1
                    }}
                  </span>
                </p>
              </div>
              <!-- <ul class="flex text-primary">
              <li
                v-for="index in memberService.TotalUserListPage"
                :key="`page${index}`"
                class="mx-[6px]"
              >
                <button
                  @click="goUserListPage(index)"
                  class="font-bold"
                  :class="
                    index === memberService.UserListPage
                      ? 'text-primary'
                      : 'text-primary/50 hover:text-primary'
                  "
                  :disabled="index === memberService.UserListPage"
                >
                  {{ index }}
                </button>
              </li>
            </ul> -->
              <button
                type="button"
                class="rotate-180 w-[10px]"
                :disabled="
                  memberService.UserListPage ===
                    memberService.TotalUserListPage ||
                  memberService.TotalUserListPage === 0
                "
                @click="goUserListPage(memberService.UserListPage + 1)">
                <img src="@image/memberFunction/common/頁面跳轉.png" alt="" />
              </button>
              <button
                type="button"
                :disabled="
                  memberService.UserListPage ===
                    memberService.TotalUserListPage ||
                  memberService.TotalUserListPage === 0
                "
                @click="goUserListPage(memberService.TotalUserListPage)"
                class="text-[10px] ml-[12px] text-primary"
                :class="{
                  'text-primary/50':
                    memberService.UserListPage ===
                    memberService.TotalUserListPage,
                }"
                v-if="memberService.TotalUserListPage > 1">
                {{ $t("MemberFunction.MemberList.LastPage") }}
              </button>
            </div>
          </div>
          <!-- 沒有資料 -->
          <div
            class="absolute left-1/2 top-3/1 -translate-x-1/2"
            v-if="memberService.CurrentUserList.length === 0">
            <img
              src="@image/memberFunction/文字框/no_file.png"
              alt=""
              class="w-[35px]" />
            <p class="text-primary text-[12px] text-center">
              {{ $t("MemberFunction.MemberList.NoData") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
