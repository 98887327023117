<script setup lang="ts">
import { onMounted, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css/bundle";
import "swiper/css";

import { useContentInfo } from "@/stores/contentInfo";
const contentInfo = useContentInfo();

const modules = [Pagination, Autoplay];

//處理輪播圖秒數用
const modValue = ref<number | undefined>(3000);
const modState = ref(false);

function checkPictureSeconds() {
  if (contentInfo.PictureSeconds !== undefined) {
    modValue.value = contentInfo.PictureSeconds;
    modState.value = true;
  } else {
    // 如果值仍然是 undefined，等待1秒後再次檢查
    setTimeout(checkPictureSeconds, 1000); // 1000毫秒等於1秒
  }
}
onMounted(() => {
  // 開始檢查
  checkPictureSeconds();
});
const onSlideChange = () => {
  console.log("check", modValue.value);
};
</script>
<template>
  <div
    class="w-full px-1"
    v-if="modState"
  >
    <Swiper
      cssMode: true
      :slides-per-view="1"
      :space-between="25"
      :modules="modules"
      loop
      :autoplay="{
        delay: modValue,
        disableOnInteraction: false,
      }"
      :pagination="{
        clickable: false,
        clickableClass: '',
      }"
      :breakpoints="{ 
        768: {
          pagination: {
            clickable: true,
            clickableClass: 'content-swiper-pagination',
          }
        }
      }"
      class="h-full"
      @slideChange="onSlideChange"
    >
      <SwiperSlide
        v-for="item in contentInfo.contentInfo.aIndexBannerPic"
        :key="item.nId"
      >
        <a :href="item.sUrl">
          <img
            :src="item.sImgUrlPhone"
            alt=""
            class="w-full block md:hidden"
          />
        </a>
        <!-- <a :href="item.sUrl">
          <img
            src="@image/memberFunction/文字框/223909272305282023.png"
            alt=""
            class="w-full h-full object-cover block md:hidden opacity-0"
          />
        </a> -->
        <a :href="item.sUrl" class="h-full w-full">
          <img
            :src="item.sImgUrl"
            alt=""
            class="w-full h-full object-cover md:block hidden"
          />
        </a>
      </SwiperSlide>
    </Swiper>
  </div>
</template>
<style>
.content-swiper-pagination {
  position: relative !important;
  bottom: 2em !important;
  transform: none !important;
}
.swiper-pagination-bullet {
  display: inline-block !important;
  border-radius: 100%;
  margin: 10px !important;
  width: 10px !important;
  height: 10px !important;
  background-color: rgb(247, 247, 247) !important;
}

/* change color of previous bullet to white*/

.swiper-pagination-bullet-active {
  border-radius: 12px !important;
  width: 24px !important;
  height: 10px !important;
  background-color: white !important;
}

@media (max-width: 768px) {
  .content-swiper-pagination {

  }
  .swiper-pagination {
    display: none !important;
    position: static !important;
    display: flex;
  }
  .swiper-pagination-bullet-active {
    /* border-radius: 12px !important; */
    width: 18px !important;
    height: 6px !important;
    /* background-color: white !important; */
  }
  .swiper-pagination-bullet {
    display: inline-block !important;
    border-radius: 3px;
    margin: 3px !important;
    width: 10px !important;
    height: 6px !important;
    background-color: rgb(247, 247, 247) !important;
  }
}
</style>
