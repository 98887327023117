<script setup lang="ts">
import { onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useLogin } from "@/stores/login";
import { useCounterStore } from "@/stores/counter";
import { useContentInfo } from "@/stores/contentInfo";
import { useMemberService } from "@/stores/memberFunction/memberService";
const router = useRouter();
const login = useLogin();
const memberService = useMemberService();
const contentInfoStore = useContentInfo();
const store = useCounterStore();

const { isLogin } = storeToRefs(login);
const { AppQrCode, AppDownloadUrl } = storeToRefs(contentInfoStore);
function toLogin() {
  if (isLogin.value) return;
  router.push({ name: "login" });
}
function toService(device: string) {
  if (device === "web") {
    memberService.ToggleService(true);
  } else {
    router.push({ name: "Service" });
  }
}
function InstallAPPDownload() {
  window.open(AppDownloadUrl.value, "_blank");
}
onMounted(() => {
  contentInfoStore.toAPPDownload();
});

const BuildName = computed(() => {
  const mode = import.meta.env.MODE;
  let name = "";
  switch (mode) {
    case "TTBtest":
    case "TTB":
      name = "TTB娛樂城";
      break;
    case "MT":
    case "MTtest":
      name = "搖錢樹娛樂城";
      break;
    case "MM":
    case "MMtest":
      name = "Ming娛樂城";
      break;
    case "ICVtest":
    case "ICV":
      name = "ICV國際";
      break;
    case "ICVCNtest":
    case "ICVCN":
      name = "ICV國際";
      break;
    case "white03test":
      name = "ICV國際";
      break;
    case "N12":
      name = "昊陽娛樂";
      break;
    case "sinhao":
      name = "鑫濠娛樂";
      break;
    default:
      name = "ICV國際";
      break;
  }
  return name;
});
const BuildNickName = computed(() => {
  const mode = import.meta.env.MODE;
  let name = "";
  switch (mode) {
    case "TTBtest":
    case "TTB":
      name = "TTB";
      break;
    case "MTtest":
    case "MT":
      name = "搖錢樹";
      break;
    case "MMtest":
    case "MM":
      name = "Ming";
      break;
    case "ICVtest":
    case "ICV":
      name = "ICV";
      break;
    case "ICVCNtest":
    case "ICVCN":
      name = "ICV";
      break;
    case "white03test":
      name = "ICV";
      break;
    case "N12":
      name = "昊陽娛樂";
      break;
    case "sinhao":
      name = "鑫濠娛樂";
      break;
    default:
      name = "ICV";
      break;
  }
  return name;
});
</script>

<template>
  <div class="" v-if="store.UserAgent !== 'ios'">
    <div class="overflow-hidden h-[34vh] relative">
      <img
        src="@image/memberFunction/app_download/banner.png"
        alt=""
        class="absolute object-cover h-full w-full" />
      <content
        class="absolute w-full max-w-[1366px] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
        <div class="w-full mx-auto px-[5vw] lg:mx-0 lg:px-0 lg:w-[50%]">
          <h2 class="text-[28px] font-bold">{{ BuildName }}APP下載</h2>
          <p class="mt-[2vh] text-[14px] lg:text-[16px]">
            「{{
              BuildName
            }}APP下載」為您帶來極致上賭娛樂。提供多款刺激賭場遊戲,包括老虎機、真人百家樂、輪盤、彩投注、彩票等。下載{{
              BuildNickName
            }}APP應用式,隨時地與遊戲,簡單方便.{{
              BuildNickName
            }}優惠及體驗金,讓您更容易贏得豐富獎金。現在就下載「{{
              BuildName
            }}APP」、開啟您的賭場之旅,感受無限娛樂和刺激!
          </p>
          <button
            type="button"
            @click="toLogin"
            class="app-download-btn-border mt-2 px-[1em] py-[.5em]">
            立即下載
          </button>
        </div>
      </content>
    </div>

    <div class="w-full bg-white text-black">
      <div class="w-full max-w-[1366px] mx-auto py-[10vh] px-[5vw] lg:px-0">
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <div class="items-center flex">
              <img
                src="@image/memberFunction/03/title_line.png"
                alt=""
                class="h-[30px] mr-[6px]" />
              <p class="text-[24px] font-bold">如何取得{{ BuildName }}App？</p>
            </div>
            <ul class="list-disc ml-[32px]">
              <li class="mt-[18px]">
                <p class="font-bold">Step1：搜尋開啟{{ BuildNickName }}網頁</p>
                <p class="font-bold">
                  開啟網頁版【<a href="#" class="text-blue-400">{{
                    BuildName
                  }}</a
                  >】
                </p>
              </li>
              <li class="mt-[18px]">
                <p class="font-bold">Step2：點選APP下載</p>
                <p class="font-bold">
                  點選{{ BuildNickName }}網頁上方【下載app】即可進行安装。
                </p>
              </li>
              <li class="mt-[18px]">
                <p class="font-bold">Step3：開始安裝</p>
                <p class="font-bold">
                  跳轉後點選【安装】,並點選【允許】操作下載描述檔。
                </p>
              </li>
              <li class="mt-[18px]">
                <p class="font-bold">Step4：蘋果描述檔設置</p>
                <p class="font-bold">
                  到手機設置>一般>裝置管理>設定描述檔({{
                    BuildNickName
                  }})>安装,即可完成安装。
                </p>
                <p class="font-bold">(使用IPHONE手機須完成到第4點操作)</p>
              </li>
            </ul>
          </div>

          <div class="mt-[5vh] md:mt-0">
            <div class="items-center flex">
              <img
                src="@image/memberFunction/03/title_line.png"
                alt=""
                class="h-[30px] mr-[6px]" />
              <p class="text-[24px] font-bold">掃描QRCode下載APP</p>
            </div>
            <div class="mt-[18px] relative inline-block">
              <img
                src="@image/memberFunction/app_download/qrcode_DL.png"
                alt="" />
              <img
                :src="AppQrCode"
                alt=""
                class="absolute w-[30%] top-[5%] left-[30%] -translate-x-1/2" />
            </div>
          </div>
        </div>
        <div class="mt-[5vh]">
          <div class="items-center flex">
            <img
              src="@image/memberFunction/03/title_line.png"
              alt=""
              class="h-[30px] mr-[6px]" />
            <p class="text-[24px] font-bold">安卓/蘋果手機版步驟教學</p>
          </div>

          <div>
            <p class="my-[1vh] ml-[18px] font-bold">▼安卓系統(APK下載)</p>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-[1em]">
              <img
                src="@image/memberFunction/app_download/adr_step1.png"
                alt=""
                class="mx-auto" />
              <img
                src="@image/memberFunction/app_download/adr_step2.png"
                alt=""
                class="mx-auto" />
              <img
                src="@image/memberFunction/app_download/adr_step3.png"
                alt=""
                class="mx-auto" />
            </div>
          </div>

          <div>
            <p class="my-[1vh] ml-[18px] font-bold">▼蘋果系統(描述檔下載)</p>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-[1em]">
              <img
                src="@image/memberFunction/app_download/ios_step1.png"
                alt=""
                class="mx-auto" />
              <img
                src="@image/memberFunction/app_download/ios_step2.png"
                alt=""
                class="mx-auto" />
              <img
                src="@image/memberFunction/app_download/ios_step3.png"
                alt=""
                class="mx-auto" />
              <img
                src="@image/memberFunction/app_download/ios_step4.png"
                alt=""
                class="mx-auto" />
              <img
                src="@image/memberFunction/app_download/ios_step5.png"
                alt=""
                class="mx-auto" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="w-full bg-gray-100 text-black font-bold h-[30vh] flex justify-center items-center">
      若有APP下載相關問題，請
      <button
        type="button"
        class="hidden md:block text-blue-400 hover:text-blue-600 ml-1"
        @click="toService('web')">
        聯絡客服
      </button>
      <button
        type="button"
        class="md:hidden block text-blue-400 hover:text-blue-600 ml-1"
        @click="toService('app')">
        聯絡客服
      </button>
    </div>
  </div>
  <div class="" v-else>
    <div class="py-[2vh] relative">
      <div class="flex justify-start items-center absolute ml-5">
        <img
          src="@image/memberFunction/app_download/logo.png"
          alt=""
          class="w-[35%]" />
        <div class="w-full ml-[2em]">
          <p class="text-[28px]">{{ BuildNickName }}</p>
          <p class="text-[20px] text-white/80">{{ BuildNickName }} Casino</p>
          <button
            type="button"
            class="app-download-app-btn-border text-[20px] py-[.3em] px-[2em] mt-2"
            @click="InstallAPPDownload"
            :disabled="!AppDownloadUrl">
            {{ AppDownloadUrl ? "開始安裝" : "請稍後.." }}
          </button>
        </div>
      </div>
      <img
        src="@image/memberFunction/app_download/down.png"
        alt=""
        class="w-full h-full" />
    </div>
  </div>
</template>
<style>
.app-download-btn-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/app_download/button.png") 1;
  -webkit-border-image: url("@image/memberFunction/app_download/button.png") 1;
  -o-border-image: url("@image/memberFunction/app_download/button.png") 1;
  border-image: url("@image/memberFunction/app_download/button.png") 1 fill;
}
.app-download-app-btn-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/app_download/download_btn.png")
    1;
  -webkit-border-image: url("@image/memberFunction/app_download/download_btn.png")
    1;
  -o-border-image: url("@image/memberFunction/app_download/download_btn.png") 1;
  border-image: url("@image/memberFunction/app_download/download_btn.png") 1
    fill;
}
</style>
