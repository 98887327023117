import { createI18n } from "vue-i18n";
//@ts-ignore
import zh from "./plugins/zh-CH.json";
//@ts-ignore
import en from "./plugins/en-US.json";
//@ts-ignore
import tw from "./plugins/zh-TW.json";
// import ja from "./ja-JP.json";
// import my from "./my-MM.json";
type MessageSchema = typeof tw;
const i18n = createI18n<[MessageSchema], "zh-CH" | "en-US" | "zh-TW">({
  // legacy: false, // 要把 legacy 設為 false，才可以使用 Composition API
  locale: "zh-TW",
  fallbackLocale: "zh-TW",
  globalInjection: true,
  messages: {
    "zh-TW": tw,
    "zh-CH": zh,
    "en-US": en,
  },
});

export default i18n;
export const i18nGlobal = i18n.global;