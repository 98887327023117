<script setup lang="ts">
import { useLanguagesStore } from "@/stores/languages";
const languages = useLanguagesStore();
</script>

<template>
  <div class="flex items-center justify-center">
    <button
      class="flex items-center justify-center"
      @click="languages.toggleLanguageLists"
    >
      <img :src="languages.nowLanguage?.image" class="w-[26px]" alt="" />
      <p class="text-primary ml-1 hidden xl:block">
        {{ languages.nowLanguage?.title }}
      </p>
    </button>
    <ul
      class="absolute right-0 top-[6dvh] lg:top-[10dvh] translate-y-[0%] w-[100px] py-[10px] bg-black/70"
      v-show="languages.isShowLanguageLists"
    >
      <li
        class="hover:bg-black py-1"
        v-for="item in languages.languageLists"
        :key="item.type + 'LanguageBtn'"
      >
        <button
          class="flex items-center justify-center w-full py-[3px]"
          @click="languages.ChangeLanguage(item.type)"
        >
          <img :src="item.image" class="w-[20px] xl:w-[26px]" alt="" />
          <p class="text-primary ml-1 text-[12px] xl:text-[14px]">
            {{ item.title }}
          </p>
        </button>
      </li>
    </ul>
  </div>
</template>
