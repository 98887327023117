<script setup lang="ts">
import { getCurrentInstance } from "vue";
import type { ComponentInternalInstance } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useErrorAlert } from "@/stores/errorAlert";
import { useContentInfo } from "@/stores/contentInfo";
import { useLogin } from "@/stores/login";
const { t } = useI18n();
const errorAlert = useErrorAlert();
const contentInfo = useContentInfo();
const login = useLogin();
const { isLogin } = storeToRefs(login);

const { LiveLink } = storeToRefs(contentInfo);
const { appContext } = getCurrentInstance() as ComponentInternalInstance;

const router = useRouter();
function toPath(name: string) {
  switch (name) {
    case "Store":
      errorAlert.AddErrorAlert(t("Context.ComingSoon"));

      break;
    case "LIVE":
      if (!isLogin.value) return router.push({ name: "login" });
      if (!LiveLink.value) {
        appContext.config.globalProperties.$mitt.emit(
          "scroll-to-bet-area-list"
        );
      } else {
        window.open(LiveLink.value, "_self");
      }
      break;
    default:
      router.push({ name: name });
      break;
  }
}
</script>
<template>
  <div class="pb-[10vh]">
    <div class="flex items-center justify-center">
      <img
        src="@image/memberFunction/02_web/discount_title.png"
        alt=""
        class="w-[80%]"
      />
    </div>
    <div class="flex justify-center items-center mt-[15vh]">
      <button
        type="button"
        class="relative scale-[125%] hover:scale-[130%] transition duration-300 ease-in-out"
        @click="toPath('LIVE')"
      >
        <img src="@image/memberFunction/02_web/discount_LIVE.png" alt="" />
      </button>
      <button
        type="button"
        class="relative scale-[125%] hover:scale-[130%] transition duration-300 ease-in-out"
        @click="toPath('VIPPrivilege')"
      >
        <img src="@image/memberFunction/02_web/discount_VIP.png" alt="" />
      </button>
      <button
        type="button"
        class="relative scale-[125%] hover:scale-[130%] transition duration-300 ease-in-out"
        @click="toPath('RankingList')"
      >
        <img src="@image/memberFunction/02_web/discount_rank.png" alt="" />
      </button>
      <button
        type="button"
        class="relative scale-[125%] hover:scale-[130%] transition duration-300 ease-in-out"
        @click="toPath('Store')"
      >
        <img src="@image/memberFunction/02_web/discount_shop.png" alt="" />
      </button>
    </div>
  </div>
</template>
