<script setup lang="ts">
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
const memberService = useMemberService();
const store = useCounterStore();

function closeMailBoxAlert() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}
async function deleteMailBox(Id: string) {
  await memberService.deleteMailBoxAlert(Id);
  closeMailBoxAlert();
}

function openMailBoxAlert2(nId: string) {
  // store.toggleAlertBg();
  store.openMailBoxAlert2();
  memberService.getDeleteAlertId(nId);
}
</script>

<template>
  <div class="sticky top-[10%] mx-auto w-[95vw] md:w-[520px]">
    <div
      class="relative bg-[url('@image/memberFunction/08/detail_board.png')] bg-100 h-[500px] md:h-[530px]"
    >
      <h3
        class="absolute top-[32px] left-1/2 -translate-x-1/2 text-center text-secondary text-[20px] md:text-[20px] font-bold"
      >
        {{ $t("MemberFunction.MailBox.MailContent") }}
      </h3>
      <div
        class="p-[3em] w-full h-full top-0 bg-100 text-primary flex flex-col text-[14px] md:text-[16px]"
      >
        <div class="grid grid-cols-8 mt-[52px]">
          <p class="col-span-2 font-bold">
            {{ $t("MemberFunction.MailBox.Title") }}
          </p>
          <p class="col-span-6">
            {{ memberService.MailBoxAlertContent.sTitle0 }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="col-span-2 font-bold">
            {{ $t("MemberFunction.MailBox.Sender") }}
          </p>
          <p class="col-span-6">
            {{ memberService.MailBoxAlertContent.nSendFrom }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="col-span-2 font-bold">
            {{ $t("MemberFunction.MailBox.Content") }}
          </p>
          <div
            class="h-[230px] md:h-[220px] col-span-6 break-words overflow-y-auto discount-scrollbar"
            v-html="memberService.MailBoxAlertContent.sContent0"
          />
        </div>
        <div
          class="absolute left-1/2 -translate-x-1/2 bottom-[3rem] flex justify-center items-center w-full"
        >
          <button
            class="btn-y-border text-secondary bg-100 mr-[10vw] md:mr-[4vw] px-[1em] py-[.2em]"
            @click="openMailBoxAlert2(memberService.MailBoxAlertContent.nId)"
          >
            {{ $t("MemberFunction.MailBox.DeleteMail") }}
          </button>
          <button
            class="btn-y-border text-secondary bg-100 mr-[12px] px-[1em] py-[.2em]"
            @click="closeMailBoxAlert"
          >
            {{ $t("MemberFunction.MailBox.Close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bgLinearMailBox {
  background: linear-gradient(45deg, #2c343e, #576879);
  border-style: solid;
  border-image: linear-gradient(45deg, #563d2a, #edbe7b) 1;
  border-image-slice: 1 1 1 1;
}
.bg-linear-mail-box-title {
  background: linear-gradient(#624380, #472161);
}
.bg-100 {
  background-size: 100% 100%;
}
</style>
