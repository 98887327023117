<script setup lang="ts">
import { useCounterStore } from "@/stores/counter";
const store = useCounterStore();

function closeLoginAlert() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}
</script>

<template>
  <div class="fixed top-[12em] w-full">
    <img
      class="w-[285px] mx-auto"
      src="@image/memberFunction/07/login_success.png"
      alt=""
    />
    <img class="w-[285px]" src="" alt="" />
  </div>
</template>

<style>
.bgLinearMailBox {
  background: linear-gradient(45deg, #2c343e, #576879);
  border-style: solid;
  border-image: linear-gradient(45deg, #563d2a, #edbe7b) 1;
  border-image-slice: 1 1 1 1;
}
.bg-linear-mail-box-title {
  background: linear-gradient(#624380, #472161);
}
.bg-100 {
  background-size: 100% 100%;
}
</style>
