<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useCounterStore } from "@/stores/counter";
import { useLogin } from "@/stores/login";
import { useMemberService } from "@/stores/memberFunction/memberService";
const store = useCounterStore();
const login = useLogin();
const memberService = useMemberService();
const { DeleteAlertId } = storeToRefs(memberService);

function cancel() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}

function submit() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
  if (DeleteAlertId.value === "all") {
    memberService.DeleteHaveReadMail();
  } else {
    memberService.deleteMailBoxAlert(DeleteAlertId.value);
  }
}
</script>

<template>
  <div
    class="absolute md:sticky top-[40%] -translate-y-1/2 left-1/2 -translate-x-1/2 md:translate-x-0 w-[95vw] lg:w-auto"
  >
    <div
      class="text-primary flex flex-col items-center justify-center relative"
    >
      <img
        class="w-[300px]"
        src="@image/memberFunction/common/search_fail.png"
        alt=""
      />
      <div class="absolute">
        <h3 class="text-center text-[20px] xl:text-[24px] font-bold">
          {{ $t("MemberFunction.MailBox.DeleteTitle")
          }}{{ DeleteAlertId === "all" ? "所有已讀" : "" }}
        </h3>
        <div class="flex items-center mb-[12px] mt-[24px] gap-x-9">
          <button
            @click="submit"
            class="relative flex justify-center items-center font-bold"
          >
            <img
              class="w-[80px]"
              src="@image/memberFunction/common/btn_bb.png"
              alt=""
            />
            <div class="absolute">
              {{ $t("MemberFunction.MailBox.Submit") }}
            </div>
          </button>
          <button
            @click="cancel"
            class="relative flex justify-center items-center font-bold border-[#bda669]"
          >
            <img
              class="w-[80px]"
              src="@image/memberFunction/common/btn_bb.png"
              alt=""
            />
            <div class="absolute">
              {{ $t("MemberFunction.MailBox.Cancel") }}
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
