<script setup lang="ts">
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
const memberService = useMemberService();
const store = useCounterStore();

function closeBetRecord() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}
</script>

<template>
  <div
    class="fixed top-0 md:top-[10%] w-[95vw] md:w-[600px] left-1/2 -translate-x-1/2"
  >
    <div
      class="relative bg-[url('@image/memberFunction/08/detail_board.png')] bg-100"
    >
      <h3
        class="absolute top-[42px] md:top-[46px] left-1/2 -translate-x-1/2 text-center text-secondary text-[20px] md:text-[24px] font-bold"
      >
        {{ $t("MemberFunction.BetRecord.BetRecord") }}
      </h3>
      <div
        class="p-[3em] w-full md:w-[600] h-full top-0 bg-100 text-primary flex flex-col text-[14px] xl:text-[20px]"
      >
        <div class="grid grid-cols-8 mt-[54px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.BetRecord.MemberAccount") }}
          </p>
          <p class="col-span-5">
            {{ memberService.BetRecordAlertContent["0"] }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.BetRecord.BetId") }}
          </p>
          <div class="col-span-5" style="word-wrap: break-word">
            <p>
              {{ memberService.BetRecordAlertContent["1"] }}
            </p>
          </div>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.BetRecord.BetAmount") }}
          </p>
          <p class="col-span-5">
            {{
              memberService.BetRecordAlertContent["9"]
                ? store.getClearPotAndAddCommas(
                    memberService.BetRecordAlertContent["9"].toString()
                  )
                : ""
            }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.BetRecord.GameType") }}
          </p>
          <p class="col-span-5">
            {{ memberService.BetRecordAlertContent["2"] }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.BetRecord.DrawPeriod") }}
          </p>
          <p class="col-span-5">
            {{ memberService.BetRecordAlertContent["4"] }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.BetRecord.BetTime") }}
          </p>
          <p class="col-span-5">
            {{ memberService.BetRecordAlertContent["5"] }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.BetRecord.BetWinnings") }}
          </p>
          <p class="col-span-5">
            {{
              store
                .getClearPotAndAddCommas(
                  memberService.BetRecordAlertContent["6"]?.toString()
                )
                .toString()
            }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.BetRecord.BetResult") }}
          </p>
          <p
            class="col-span-5"
            :class="{
              'text-primary':
                memberService.BetRecordAlertContent['13'] &&
                parseInt(memberService.BetRecordAlertContent['13']) === 0,
              'text-red-600':
                memberService.BetRecordAlertContent['13'] &&
                parseInt(memberService.BetRecordAlertContent['13']) < 0,
              'text-green-600':
                memberService.BetRecordAlertContent['13'] &&
                parseInt(memberService.BetRecordAlertContent['13']) > 0,
            }"
          >
            {{
              store
                .getClearPotAndAddCommas(
                  memberService.BetRecordAlertContent["13"]?.toString()
                )
                .toString()
            }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.BetRecord.BetStatus") }}
          </p>
          <p
            class="col-span-5"
            :class="{
              'text-yellow-300':
                memberService.BetRecordAlertContent['11'] ===
                $t('MemberFunction.BetRecord.Winning'),
            }"
          >
            {{ memberService.BetRecordAlertContent["11"] }}
          </p>
        </div>
        <div class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.BetRecord.Odds") }}
          </p>
          <p class="col-span-5">
            {{ memberService.BetRecordAlertContent["14"] }}
          </p>
        </div>
        <div class="flex justify-around mb-[24px]">
          <button
            class="btn-y-border px-[1em] py-[.2em] text-secondary"
            @click="closeBetRecord"
          >
            {{ $t("MemberFunction.BetRecord.ClosPage") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bgLinearMailBox {
  background: linear-gradient(45deg, #2c343e, #576879);
  border-style: solid;
  border-image: linear-gradient(45deg, #563d2a, #edbe7b) 1;
  border-image-slice: 1 1 1 1;
}
.bg-linear-mail-box-title {
  background: linear-gradient(#624380, #472161);
}
.bg-100 {
  background-size: 100% 100%;
}
</style>
