import { ref, computed, watchEffect } from "vue";
import { defineStore } from "pinia";

export const useTimeListLogic = defineStore("TimeListLogic", () => {
  function DateString(Date: Date) {
    const year = Date.getFullYear();
    const month = String(Date.getMonth() + 1).padStart(2, "0"); // 月份从 0 开始，所以需要加 1
    const day = String(Date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function getDataDate(Day: number) {
    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - todayDate.getDay() + Day);
    // const DateDate = todayDate.toISOString().split("T")[0];
    return DateString(todayDate);
  }

  function getMonthFirthDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // 加1是因為getMonth()返回的是0到11

    return `${year}-${month}-01`;
  }

  function getMonthLastDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const lastDayOfMonth = new Date(year, month, 0);

    // const formattedLastDay = lastDayOfMonth.toISOString().split("T")[0];

    return DateString(lastDayOfMonth);
  }

  function getTodayDay() {
    const today = new Date();
    return today.getDay();
  }

  return {
    DateString,
    getDataDate,
    getMonthFirthDate,
    getMonthLastDate,
    getTodayDay,
  };
});
