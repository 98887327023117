import { ref, computed, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRouter } from "vue-router";

import { useLanguagesStore } from "@/stores/languages";

import { useMaintain } from "@/stores/maintain";
import { useLogin } from "@/stores/login";
import { useErrorAlert } from "@/stores/errorAlert";
import { useGameIFrame } from "@/stores/gameIFrame";
import { useLobbyList } from "@/stores/lobbyList";
import { useBgAudio } from "@/stores/bgAudio";

import { postIndexInfo, postaSeconds, getSale, getSaleUrl } from "@/assets/API/API";
import * as APItypes from "@/assets/API/APItype";
import * as APIs from "@/assets/API/API";

import AllIconOffImage from "@image/memberFunction/03_web/全部_icon_p.png";
import AllIconOnImage from "@image/memberFunction/03_web/全部_icon.png";
import LoadingBgImage from "@image/memberFunction/common/loading_page.jpg";
import LoadingWebBgImage from "@image/memberFunction/common/loading_BG.png";
import LoadingWebMainImage from "@image/memberFunction/common/loading_main.png";

export const useContentInfo = defineStore("contentInfo", () => {
  const router = useRouter();
  const languages = useLanguagesStore();
  const login = useLogin();
  const errorAlert = useErrorAlert();
  const gameIFrame = useGameIFrame();
  const lobbyList = useLobbyList();
  const bgAudio = useBgAudio();
  const maintain = useMaintain();

  const contentInfo = ref<APItypes.IndexInfoResponse>(
    {} as APItypes.IndexInfoResponse
  );
  // 依照aGameKind 排序 gameDataList
  const gamePlatformList = computed(() => {
    if (JSON.stringify(contentInfo.value) === "{}") return [];

    const gameDataList = contentInfo.value.aGameDataOrder;
    const aGameKindMaps = contentInfo.value.aGameKind.map((item) => {
      const itemKey = Object.keys(item)[0];
      return Object.prototype.hasOwnProperty.call(gameDataList, itemKey)
        ? gameDataList[itemKey]
        : {};
    });
    return aGameKindMaps;
  });

  function getContentListInfo(type: string) {
    return contentInfo.value.aGameDataOrder[type];
  }
  const gameBoxType = computed(() => {
    if (JSON.stringify(contentInfo.value) === "{}") return "1";
    return contentInfo.value.nGameBoxType;
  });

  // APP首頁遊戲輪播 向左向右滑動
  const slidesTipState = ref<string>("none");

  // 直播頁面連結
  const LiveLink = computed(() => {
    if (JSON.stringify(contentInfo.value) === "{}") return "";
    const aGameKindAry = Object.values(contentInfo.value.aGameKind);

    const url = aGameKindAry.find((item) => {
      return (
        Object.values(item)[0].sTitle0 === "直播" ||
        Object.values(item)[0].sTitle0 === "LIVE" ||
        Object.values(item)[0].sTitle0 === "Live"
      );
    });

    //@ts-ignore
    const LivenBkid = Object.values(url)[0].nBKid;
    const LiveUrl = Object.values(contentInfo.value.gameDataList[LivenBkid])[0]
      .sUrl;

    return LiveUrl || "";
  });

  // 檢查進入遊戲
  async function CheckLoginGame(sGameType: string) {
    const m_user_c = login.CookieMUC();
    const getData = {
      m_user_c,
      sGameType,
    };
    const res = await APIs.postLotteryGameLoginCheck(getData);
    return res;
  }

  // 進入遊戲
  async function goUrl(itemInfo: APItypes.IndexInfoGameDataListInfo) {
    if (!login.isLogin) {
      router.push({ name: "login" });
      return;
    }
    const newWindow = window.open("about:blank", "_blank");
    let writeHTML = "";
    if (window.innerWidth > 768) {
      writeHTML = `
      <!DOCTYPE html>
      <html lang="zh">
      <head>
          <meta charset="UTF-8">
          <title>載入中</title>
          <style>
          body {
            overflow: hidden; 
            background-image: url('${LoadingWebBgImage}');
            background-size: cover;
          }
            div {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            img {
              height: 80vh;
            }
          </style>
      </head>
      <body>
        <div>
          <img src="${LoadingWebMainImage}">
        </div>
      </body>
      </html>
  `;
    } else {
      writeHTML = `
      <!DOCTYPE html>
      <html lang="zh">
      <head>
          <meta charset="UTF-8">
          <title>載入中</title>
          <style>
          body {
          margin: 0;
          }
          div {
            width: 100vw;
            height: 100vh;
            background-image: url('${LoadingBgImage}');
            background-size: cover;
            background-position: center;
          }
          </style>
      </head>
      <body>
      <div></div>
      </body>
      </html>
  `;
    }
    newWindow?.document.write(writeHTML);

    const isCheckLoginGame = await CheckLoginGame(itemInfo.sGameType);
    if (isCheckLoginGame.type > 0) {
      newWindow?.close();
      errorAlert.AddErrorAlert(isCheckLoginGame.sMsg.replace("\\n", "\n"));
      return;
    }

    // console.log(itemInfo.sGameType);
    // router.push({
    //   name: "GameLobby",
    //   params: { GameName: itemInfo.sTitle0 },
    // });
    if (itemInfo.nNeedLobby === "1") {
      newWindow?.close();
      router.push({
        name: "GameLobby",
        params: { GameName: itemInfo.sTitle0 },
      });
      lobbyList.CanIframe = itemInfo.nCanIframe;
      lobbyList.GameType = itemInfo.sGameType;
      return;
    }

    await login.getMoneyBack();
    if (itemInfo.nCanIframe === "0") {
      // const newWindow = window.open(
      //   itemInfo.sUrl.replace("_OpenWinApi", "_OpenWinApi_test"),
      //   "_blank"
      // );
      newWindow!.location.href = itemInfo.sUrl.replace(
        "_OpenWinApi",
        "_OpenWinApi_test"
      );
    } else {
      newWindow!.close();
      gameIFrame.ToGameIFrame(
        itemInfo.sUrl.replace("_OpenWinApi", "_OpenWinApi_test")
      );
      bgAudio.stopMusic();
    }

    // LoadingPage.toLoadingPage(url);

    // window.open(url, "_blank");
    // window.location.href = url;
  }

  // 背景音樂
  const BgMusic = computed(() => {
    if (JSON.stringify(contentInfo.value) === "{}") return "";
    return contentInfo.value.sMusic;
  });
  // APP下載QRCode
  const AppQrCode = computed(() => {
    if (JSON.stringify(contentInfo.value) === "{}") return "";
    return contentInfo.value.sAppQrCode;
  });
  const AppDownloadUrl = computed(() => {
    if (JSON.stringify(contentInfo.value) === "{}") return "";
    return contentInfo.value.sAppDownload;
  });

  //#region APP下載頁
  function toAPPDownload() {
    login.CheckMUC(successAPPDownload, failAPPDownload);
  }
  async function successAPPDownload() {
    CheckLogin();
    return;
  }
  async function failAPPDownload() {
    CheckLogin();
    return;
  }
  //#endregion

  //#region 優惠頁
  interface SaleTypesListType {
    isSelect: boolean;
    data: APItypes.SaleData[];
    id: string;
    title: string;
    sIconOn: string;
    sIconOff: string;
  }
  const Sale = ref<APItypes.SaleResponseList>({} as APItypes.SaleResponseList);
  const SaleDataAllList = ref<APItypes.SaleData[]>([]);
  const SaleBanner = ref<string>("");
  function toGetSale() {
    login.CheckMUC(successgetSale, failgetSale);
  }
  async function successgetSale() {
    getSaleData();
    CheckLogin();
    return;
  }
  async function failgetSale() {
    getSaleData();
    CheckLogin();
    return;
  }
  async function getSaleData() {
    const m_user_c = login.CookieMUC();
    const getData = {
      m_user_c,
      nLang: languages.LanguageType,
    };
    const res = await getSale(getData);
    switch (res.type) {
      case "0":
        Sale.value = res.dataList;
        SaleDataAllList.value = res.dataListAll;
        SaleBanner.value = res.sBannerImg;
        break;
    }
  }
  async function getSaleUrlData(url: string) {
    const m_user_c = login.CookieMUC();
    const getData = {
      m_user_c,
      nLang: languages.LanguageType,
    };
    const res = await getSaleUrl(url, getData);
    errorAlert.AddErrorAlert(res.sMsg);
  }
  const SaleTypesList = ref<SaleTypesListType[]>([]);
  watch(
    () => Sale.value,
    () => {
      const SaleAry = Object.values(Sale.value);
      const defaultType = [
        {
          isSelect: true,
          data: SaleDataAllList.value,
          id: "all",
          title: "全部",
          sIconOn: AllIconOnImage,
          sIconOff: AllIconOffImage,
        },
      ];
      const types = SaleAry.map((items) => {
        return {
          isSelect: false,
          data: items.aData,
          id: items.nBKid,
          title: items.sTitle0,
          sIconOn: items.sIconOn,
          sIconOff: items.sIconOff,
        };
      });
      SaleTypesList.value = defaultType.concat(types);
    }
  );
  function SelectType(id: string) {
    SaleTypesList.value.forEach((item) => {
      item.isSelect = false;
      if (item.id === id) {
        item.isSelect = true;
      }
    });
  }

  const SaleList = computed(() => {
    const findSelect = SaleTypesList.value.find((item) => {
      return item.isSelect;
    });
    return findSelect ? findSelect.data : [];
  });

  const SelectDiscountData = ref<APItypes.SaleData>({} as APItypes.SaleData);
  function SelectDiscount(item: APItypes.SaleData) {
    SelectDiscountData.value = item;
  }
  //#endregion

  //#region 跑馬燈
  const contentInfoAMarquee = computed(() => {
    if (!contentInfo.value.aMarquee) return "";
    const aMarqueeAry = Object.values(contentInfo.value.aMarquee);
    const aMarquee = ref<string>("");
    aMarqueeAry.forEach((item) => {
      aMarquee.value = aMarquee.value + item.sTitle0 + "　";
    });
    return aMarquee.value;
  });

  // 拿跑馬燈&輪播圖秒數
  const MarqueeSeconds = ref<number>();
  const PictureSeconds = ref<number>();

  async function getSeconds() {
    const data = {
      m_user_c: login.CookieMUC(),
    };
    const res = await postaSeconds(data);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    MarqueeSeconds.value = res.dataList.nMarqueeSeconds;
    PictureSeconds.value = res.dataList.nPictureSeconds;
  }
  //#endregion

  // 最新消息

  async function getContentInfo() {
    const data = {
      m_user_c: login.CookieMUC(),
      sLang: languages.LanguageType,
    };
    const res = await postIndexInfo(data);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;

    contentInfo.value = res;
  }

  function checkSuccess() {
    // getContentInfo();
    getSeconds();
  }
  function checkFail() {
    // getContentInfo();
    getSeconds();
  }

  async function CheckLogin() {
    await login.CheckMUC(checkSuccess, checkFail);
  }

  return {
    CheckLogin,
    contentInfo,
    getContentListInfo,
    gamePlatformList,
    contentInfoAMarquee,
    slidesTipState,
    gameBoxType,
    goUrl,
    BgMusic,
    AppQrCode,
    AppDownloadUrl,

    // 拿跑馬燈&輪播圖秒數
    MarqueeSeconds,
    PictureSeconds,

    // APP下載頁
    toAPPDownload,

    // 優惠頁
    toGetSale,
    Sale,
    getSaleUrlData,
    SaleDataAllList,
    SaleBanner,
    SaleTypesList,
    SelectType,
    SaleList,
    SelectDiscount,
    SelectDiscountData,

    // 主頁資訊
    LiveLink,
    getContentInfo,
  };
});
