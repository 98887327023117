<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
const memberService = useMemberService();
const store = useCounterStore();
const { nDataType, nDay } = storeToRefs(memberService);

function changeDataType(Day: string) {
  const postData = {
    nDataType: nDataType.value,
    nDay: Day,
  };
  memberService.changeDataType(postData);
  closeBetRecord();
}

function closeBetRecord() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}
</script>

<template>
  <div class="sticky top-[30%] -translate-y-1/2 w-[300px] md:w-[400px] mx-auto">
    <div class="relative h-[220px] md:h-[255px]">
      <div
        class="absolute w-full h-full top-[13.5%] bg-100 text-primary text-[14px] md:text-[18px]"
      >
        <button
          type="button"
          class="group w-full relative"
          :class="{
            'rank-date-up-bg': nDay !== '1',
            'rank-date-up-on-bg': nDay === '1',
          }"
          @click="changeDataType('1')"
        >
          <p
            class="text-center py-[7px]"
            :class="{
              'group-hover:text-secondary': nDay !== '1',
              'text-secondary': nDay === '1',
            }"
          >
            {{ $t("Context.RankingList.Today") }}
          </p>
        </button>
        <button
          type="button"
          class="group w-full relative top-[-2px]"
          :class="{
            'rank-date-center-bg': nDay !== '2',
            'rank-date-center-on-bg': nDay === '2',
          }"
          @click="changeDataType('2')"
        >
          <p
            class="text-center py-[7px]"
            :class="{
              'group-hover:text-secondary': nDay !== '2',
              'text-secondary': nDay === '2',
            }"
          >
            {{ $t("Context.RankingList.Yesterday") }}
          </p>
        </button>
        <button
          type="button"
          class="group w-full relative top-[-4px]"
          :class="{
            'rank-date-center-bg': nDay !== '3',
            'rank-date-center-on-bg': nDay === '3',
          }"
          @click="changeDataType('3')"
        >
          <p
            class="text-center py-[7px]"
            :class="{
              'group-hover:text-secondary': nDay !== '3',
              'text-secondary': nDay === '3',
            }"
          >
            {{ $t("Context.RankingList.ThisWeek") }}
          </p>
        </button>
        <button
          type="button"
          class="group w-full relative top-[-6px]"
          :class="{
            'rank-date-center-bg': nDay !== '4',
            'rank-date-center-on-bg': nDay === '4',
          }"
          @click="changeDataType('4')"
        >
          <p
            class="text-center py-[7px]"
            :class="{
              'group-hover:text-secondary': nDay !== '4',
              'text-secondary': nDay === '4',
            }"
          >
            {{ $t("Context.RankingList.LastWeek") }}
          </p>
        </button>
        <button
          type="button"
          class="group w-full relative top-[-8px]"
          :class="{
            'rank-date-down-bg': nDay !== '5',
            'rank-date-down-on-bg': nDay === '5',
          }"
          @click="changeDataType('5')"
        >
          <p
            class="text-center py-[7px]"
            :class="{
              'group-hover:text-secondary': nDay !== '5',
              'text-secondary': nDay === '5',
            }"
          >
            {{ $t("Context.RankingList.ThisMonth") }}
          </p>
        </button>
      </div>
    </div>
  </div>
</template>

<style>
.bgLinearMailBox {
  background: linear-gradient(45deg, #2c343e, #576879);
  border-style: solid;
  border-image: linear-gradient(45deg, #563d2a, #edbe7b) 1;
  border-image-slice: 1 1 1 1;
}
.bg-linear-mail-box-title {
  background: linear-gradient(#624380, #472161);
}
.bg-100 {
  background-size: 100% 100%;
}
</style>
