<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
const memberService = useMemberService();
const store = useCounterStore();
const { RankingMemberList, RankingMemberUpdateTime, nDay, nDataType } =
  storeToRefs(memberService);

function changeDataType(Type: string) {
  const postData = {
    nDataType: Type,
    nDay: nDay.value,
  };
  memberService.changeDataType(postData);
  closeBetRecord();
}

function closeBetRecord() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}
</script>

<template>
  <div class="sticky top-[30%] -translate-y-1/2 w-[85vw] md:w-[400px] mx-auto">
    <div class="relative">
      <div
        class="w-full h-full top-0 bg-100 text-primary text-[14px] md:text-[18px]"
      >
        <button
          type="button"
          class="w-[calc(100%)] relative"
          @click="changeDataType('1')"
          :class="{
            'rank-date-up-bg': nDataType !== '1',
            'rank-date-up-on-bg': nDataType === '1',
          }"
        >
          <p
            class="text-center py-[10px] relative z-10"
            :class="{
              'group-hover:text-secondary': nDataType !== '1',
              'text-secondary': nDataType === '1',
            }"
          >
            {{ $t("Context.RankingList.WageringRank") }}
          </p>
        </button>
        <button
          type="button"
          class="group w-[calc(100%)] relative top-[-2px]"
          :class="{
            'rank-date-down-bg': nDataType !== '2',
            'rank-date-down-on-bg': nDataType === '2',
          }"
          @click="changeDataType('2')"
        >
          <p
            class="text-center py-[10px]"
            :class="{
              'group-hover:text-secondary': nDataType !== '2',
              'text-secondary': nDataType === '2',
            }"
          >
            {{ $t("Context.RankingList.DepositRank") }}
          </p>
        </button>
      </div>
    </div>
  </div>
</template>

<style>
.bgLinearMailBox {
  background: linear-gradient(45deg, #2c343e, #576879);
  border-style: solid;
  border-image: linear-gradient(45deg, #563d2a, #edbe7b) 1;
  border-image-slice: 1 1 1 1;
}
.bg-linear-mail-box-title {
  background: linear-gradient(#624380, #472161);
}
.bg-100 {
  background-size: 100% 100%;
}
</style>
