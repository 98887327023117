<script setup lang="ts">
import { useMemberService } from "@/stores/memberFunction/memberService";
const memberService = useMemberService();
function ToLineLink() {
  memberService.getWebTalkLineService();
}
</script>

<template>
  <div class="p-5 text-[#fff] mb-5">
    <button type="button">
      <img src="@image/memberFunction/04/24hour.png" alt="" class="w-[60%]" />
    </button>
    <div class="flex justify-center items-center mb-5">
      <img src="@image/memberFunction/04/cs_photo.png" alt="" />
    </div>

    <!-- 1 -->
    <div class="relative flex justify-around items-center mb-5">
      <img src="@image/memberFunction/04/cs_add_bg.png" alt="" />
      <div
        class="absolute flex justify-evenly gap-x-10 items-center text-[20px] text-[#fff]"
      >
        <div class="flex justify-around items-center gap-5">
          <img
            class="w-[35px]"
            src="@image/memberFunction/04/line_icon.png"
            alt=""
          />
          {{ $t("Context.Service.LineService") }}
        </div>
        <button
          class="flex justify-center items-center btn-y-border w-[120px] h-[36px]"
          @click="ToLineLink"
        >
          <div class="text-[16px] text-secondary">
            {{ $t("Context.Service.Join") }}
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<style></style>
