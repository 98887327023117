<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useCounterStore } from "@/stores/counter";
import { useLogin } from "@/stores/login";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useErrorAlert } from "@/stores/errorAlert";

import ShareMoney from "@/components/menberFunction/ShareMakeFortunePage/ShareMoney.vue";
import ShareRecord from "@/components/menberFunction/ShareMakeFortunePage/ShareRecord.vue";
import ShareFeedback from "@/components/menberFunction/ShareMakeFortunePage/ShareFeedback.vue";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";
const store = useCounterStore();
const login = useLogin();
const memberService = useMemberService();
const errorAlert = useErrorAlert();

const isSharePage = ref<boolean>(false);
const SharePage = ref<number>(0);

function toSharePage() {
  isSharePage.value = true;
}
function SelectSharePage(page: number) {
  SharePage.value = page;
}

const textToCopy = ref<String>();
function checksAccount() {
  if (login.memberData.sAccount !== undefined) {
    textToCopy.value =
      "https://www.icv8888.net/Project/t_icv00/ClientTest/ICV/#/PromotionReg?user=" +
      login.memberData.sAccount;
  } else {
    // 如果值仍然是 undefined，等待1秒後再次檢查
    setTimeout(checksAccount, 1000); // 1000毫秒等於1秒
  }
}

onMounted(async () => {
  checksAccount();
});
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] mt-[1dvh] px-[1vw] pb-[10dvh] pt-0 md:pt-[5dvh]"
  >
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9 relative">
      <div
        class="px-0 pt-0 pb-0 md:px-[5vw] md:pt-[2dvh] md:pb-[4dvh] relative z-10 web-gold-border rounded-[24px]"
      >
        <div>
          <div
            class="z-20 relative member-bg-border md:border-0 rounded-[12px] pb-[12px] ms-2 me-2 mb-[5em] min-h-[50dvh]"
            v-show="!isSharePage"
          >
            <img src="@image/memberFunction/03/share_dm.png" alt="" />
            <p
              class="text-[16px] md:text-[28px] text-primary font-bold text-center py-[6px] md:py-[3dvh]"
            >
              {{ $t("MemberFunction.ShareMakeFortune.EasyThreeSteps") }}
            </p>
            <div class="grid grid-cols-5 px-[12px]">
              <img
                src="@image/memberFunction/03/share.png"
                alt=""
                class="h-[40px] md:h-[80px] col-span-1 relative top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
              />

              <img
                src="@image/memberFunction/03/大於.png"
                alt=""
                class="h-[16px] col-span-1 relative top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
              />
              <img
                src="@image/memberFunction/03/sure.png"
                alt=""
                class="h-[40px] md:h-[80px] col-span-1 relative top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
              />
              <img
                src="@image/memberFunction/03/大於.png"
                alt=""
                class="h-[16px] col-span-1 relative top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
              />
              <img
                src="@image/memberFunction/03/signin.png"
                alt=""
                class="h-[40px] md:h-[80px] col-span-1 relative top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
              />
              <p
                class="text-primary text-center text-[12px] md:text-[18px] mt-0 md:mt-[1vh]"
              >
                {{ $t("MemberFunction.ShareMakeFortune.GotoShare") }}<br />{{
                  $t("MemberFunction.ShareMakeFortune.ForFriends")
                }}
              </p>
              <div></div>
              <p
                class="text-primary text-center text-[12px] md:text-[18px] mt-0 md:mt-[1vh]"
              >
                {{ $t("MemberFunction.ShareMakeFortune.FriendAccepted")
                }}<br />{{
                  $t("MemberFunction.ShareMakeFortune.YourInvitation")
                }}
              </p>
              <div></div>
              <p
                class="text-primary text-center text-[12px] md:text-[18px] mt-0 md:mt-[1vh]"
              >
                {{ $t("MemberFunction.ShareMakeFortune.FriendRegistration")
                }}<br />{{
                  $t("MemberFunction.ShareMakeFortune.AndFirstDeposit")
                }}
              </p>
              <div class="col-span-5 self-center mx-auto my-[3vh]">
                <button
                  class="share-make-neon-border rounded-[30px] px-[2em] md:px-[6vw] py-0 md:py-[1em]"
                  @click="toSharePage"
                >
                  <p
                    class="text-secondary font-bold text-[24px] text-center tracking-[10px] mr-[-10px]"
                  >
                    {{ $t("MemberFunction.ShareMakeFortune.GotoShare") }}
                  </p>
                </button>
              </div>
            </div>
          </div>

          <div v-show="isSharePage">
            <p
              class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block"
            >
              {{ $t("MemberFunction.ShareMakeFortune.ShareProsperity") }}
            </p>
            <div class="flex justify-center items-center mt-5 mx-3">
              <button
                class="group relative flex justify-center items-center mr-[1vw]"
                @click="SelectSharePage(0)"
                :class="{
                  'text-secondary': SharePage === 0,
                  'text-primary hover:text-secondary': SharePage !== 0,
                }"
              >
                <img
                  class="w-[100px]"
                  src="@image/memberFunction/common/top_btn_p.png"
                  alt=""
                  :class="{
                    block: SharePage === 0,
                    'hidden group-hover:block': SharePage !== 0,
                  }"
                />
                <img
                  class="w-[100px]"
                  src="@image/memberFunction/common/top_btn.png"
                  alt=""
                  :class="{
                    hidden: SharePage === 0,
                    'block group-hover:hidden': SharePage !== 0,
                  }"
                />
                <div class="absolute text-[14px] font-bold">
                  {{ $t("MemberFunction.ShareMakeFortune.ShareEarn") }}
                </div>
              </button>
              <button
                class="group relative flex justify-center items-center mr-[1vw]"
                @click="SelectSharePage(1)"
                :class="{
                  'text-secondary': SharePage === 1,
                  'text-primary hover:text-secondary': SharePage !== 1,
                }"
              >
                <img
                  class="w-[100px]"
                  src="@image/memberFunction/common/top_btn_p.png"
                  alt=""
                  :class="{
                    block: SharePage === 1,
                    'hidden group-hover:block': SharePage !== 1,
                  }"
                />
                <img
                  class="w-[100px]"
                  src="@image/memberFunction/common/top_btn.png"
                  alt=""
                  :class="{
                    hidden: SharePage === 1,
                    'block group-hover:hidden': SharePage !== 1,
                  }"
                />
                <div class="absolute text-[14px] font-bold">
                  {{ $t("MemberFunction.ShareMakeFortune.ShareRecords") }}
                </div>
              </button>
              <button
                class="group relative flex justify-center items-center mr-[1vw]"
                @click="SelectSharePage(2)"
                :class="{
                  'text-secondary': SharePage === 2,
                  'text-primary hover:text-secondary': SharePage !== 2,
                }"
              >
                <img
                  class="w-[100px]"
                  src="@image/memberFunction/common/top_btn_p.png"
                  alt=""
                  :class="{
                    block: SharePage === 2,
                    'hidden group-hover:block': SharePage !== 2,
                  }"
                />
                <img
                  class="w-[100px]"
                  src="@image/memberFunction/common/top_btn.png"
                  alt=""
                  :class="{
                    hidden: SharePage === 2,
                    'block group-hover:hidden': SharePage !== 2,
                  }"
                />
                <div class="absolute text-[14px] font-bold">
                  {{ $t("MemberFunction.ShareMakeFortune.ShareFeedback") }}
                </div>
              </button>
            </div>

            <!-- 內容 -->
            <div
              class="z-20 relative member-in-bg rounded-[12px] pt-[1em] px-[1em] min-h-[50dvh]"
            >
              <ShareMoney v-if="SharePage === 0" />
              <ShareRecord v-if="SharePage === 1" />
              <ShareFeedback v-if="SharePage === 2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  -->
</template>

<style>
.share-make-neon-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/03/check_btn.png") 1;
  -webkit-border-image: url("@image/memberFunction/03/check_btn.png") 1;
  -o-border-image: url("@image/memberFunction/03/check_btn.png") 1;
  border-image: url("@image/memberFunction/03/check_btn.png") 1 fill;
}
</style>
