import { ref, computed, watchEffect } from "vue";
import { defineStore } from "pinia";

interface Tip {
  msg: string;
  id: string;
}
export const useErrorAlert = defineStore("errorAlert", () => {
  let count = 0;
  const tips = ref<Tip[]>([]);
  const isImg = ref<boolean>(false);
  let isPopping = false; // 添加标志变量

  function AddErrorAlert(msg: string, getImg: boolean = false) {
    count++;
    const tip = {
      msg,
      id: count + "ErrorTip",
    };
    isImg.value = getImg;
    tips.value.unshift(tip);
    setTimeout(() => {
      if (!isPopping && tips.value.length > 0) {
        isPopping = true;
        tips.value.pop();
        isPopping = false; // 在操作完成后重置标志变量
      }
    }, 2000);
  }
  watchEffect(() => {
    if (tips.value.length > 2) {
      tips.value.pop();
    }
  });
  return {
    tips,
    isImg,
    AddErrorAlert,
  };
});
