<script setup lang="ts">
import {
  ref,
  computed,
  getCurrentInstance,
  watch,
  reactive,
  onMounted,
  onBeforeUnmount,
} from "vue";
import type { ComponentInternalInstance } from "vue";
import type { FormContext, Form } from "vee-validate";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import * as APItypes from "@/assets/API/APItype";

import DefaultImage from "@image/memberFunction/11/pankcard01_bg.png";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";

interface AddBankFormData {
  sPay_num: string;
  nPay_card: string;
  sPay_address: string;
  sBranchCode: string;
  nBankType: number;
}
const { appContext } = getCurrentInstance() as ComponentInternalInstance;
const { t } = useI18n();
const memberService = useMemberService();
const store = useCounterStore();

const {
  openAdminBankList,
  AdminBankList,
  addMemberCardMsg,
  keepCardFormValues,
} = storeToRefs(memberService);
const { isWithdrawPageAlert } = storeToRefs(store);

const addBankForm = ref<null | InstanceType<typeof Form>>(null);
const nBankType = ref<number>(1);
// 銀行卡帳本
const File0ImageInput = ref<HTMLInputElement | null>(null);
const File1ImageInput = ref<HTMLInputElement | null>(null);
const Images = reactive({
  defaultFile0Image: DefaultImage,
  defaultFile1Image: DefaultImage,
});
const ImagesState = reactive({
  File0ImageState: false,
  File1ImageState: false,
});
async function handleFileUpload(event: Event) {
  const target = event.target as HTMLInputElement;
  const file = (target.files as FileList)[0];
  if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
    const reader = new FileReader();

    reader.onload = async (e) => {
      switch (target.id) {
        case "File0":
          if (!e.target) return (Images.defaultFile0Image = "");
          if (!e.target.result) return (Images.defaultFile0Image = "");
          Images.defaultFile0Image = e.target.result.toString();
          ImagesState.File0ImageState = true;
          break;
        case "File1":
          if (!e.target) return (Images.defaultFile1Image = "");
          if (!e.target.result) return (Images.defaultFile1Image = "");
          Images.defaultFile1Image = e.target.result.toString();
          ImagesState.File1ImageState = true;
          break;

        default:
          break;
      }
    };
    reader.readAsDataURL(file); // 讀取文件
  } else {
    switch (target.id) {
      case "File0":
        Images.defaultFile0Image = "";
        ImagesState.File0ImageState = false;
        break;
      case "File1":
        Images.defaultFile1Image = "";
        ImagesState.File1ImageState = false;
        break;

      default:
        break;
    }
    alert(t("MemberFunction.AddBank.SelectCorrectImage"));
  }
}
const clearInput = (type: string) => {
  switch (type) {
    case "File0":
      if (!File0ImageInput.value) return;

      File0ImageInput.value.value = "";
      Images.defaultFile0Image = DefaultImage;
      ImagesState.File0ImageState = false;
      break;
    case "File1":
      if (!File1ImageInput.value) return;

      File1ImageInput.value.value = "";
      Images.defaultFile1Image = DefaultImage;
      ImagesState.File1ImageState = false;
      break;

    default:
      break;
  }
};
watch(nBankType, () => {
  clearInput("File0");
  clearInput("File1");
});

// 開戶銀行類別
const AdminBankId = ref<string>("");
const AdminBankKey = ref<string>("");
const getAdminBankKeyName = ref<string>("");
// const getAdminBankKeyName = computed(() => {
//   const KeyName = AdminBankList.value.find((item) => {
//     return item.sBankId === AdminBankKey.value;
//   });
//   return KeyName ? KeyName.sBank : "";
// });
const getAdminBankList = computed(() => {
  const KeyList = AdminBankList.value.filter((item) => {
    return item.sBank.includes(AdminBankKey.value);
  });
  return KeyList;
});
const isAdminBankKey = ref<boolean>(false);
function openAdminBankKey() {
  isAdminBankKey.value = true;
}
function closeAdminBankKey() {
  isAdminBankKey.value = false;
}
function selectAdminBankKey(item: APItypes.AdminBankList) {
  closeAdminBankKey();
  console.log(item);
  getAdminBankKeyName.value = item.sBank;
  AdminBankKey.value = item.sBankId;
  AdminBankId.value = item.nId;
}

// 發送
const Msg = ref<string>("");
async function openAddBankAlert(formValues: AddBankFormData) {
  Msg.value = "";
  const { sPay_num, nPay_card, sPay_address, sBranchCode, nBankType } =
    formValues;
  if (nBankType === 1) {
    // 一般存簿
    if (!File0ImageInput.value)
      return (Msg.value = t("MemberFunction.AddBank.CheckBankBook"));
    if (!File0ImageInput.value.files)
      return (Msg.value = t("MemberFunction.AddBank.CheckBankBook"));
    if (!File0ImageInput.value.files[0])
      return (Msg.value = t("MemberFunction.AddBank.CheckBankBook"));
  }
  if (nBankType === 2) {
    // 數位存簿
    if (!File0ImageInput.value)
      return (Msg.value = t("MemberFunction.AddBank.CheckBankCardFront"));
    if (!File1ImageInput.value)
      return (Msg.value = t("MemberFunction.AddBank.CheckDigitalAccountInfo"));
    if (!File0ImageInput.value.files)
      return (Msg.value = t("MemberFunction.AddBank.CheckBankCardFront"));
    if (!File1ImageInput.value.files)
      return (Msg.value = t("MemberFunction.AddBank.CheckDigitalAccountInfo"));
    if (!File0ImageInput.value.files[0])
      return (Msg.value = t("MemberFunction.AddBank.CheckBankCardFront"));
    if (!File1ImageInput.value.files[0])
      return (Msg.value = t("MemberFunction.AddBank.CheckDigitalAccountInfo"));
  }

  const postData = {
    sPay_num,
    nPay_card: AdminBankId.value,
    sPay_address,
    sBranchCode,
    nBankType: 1,
    sFile0: null,
    sFile1: null,
  };
  keepCardFormValues.value = postData;
  store.toggleAlertBg();
  store.openAddBankAlert();
}
async function submit() {
  if (!addBankForm.value) return;
  await memberService.sendAddCardMemberBank(keepCardFormValues.value);
  getAdminBankKeyName.value = "";
  AdminBankKey.value = "";
  AdminBankId.value = "";
  addBankForm.value.resetForm();
  // clearInput("File0");
  // clearInput("File1");
}

// 開啟範例
function openAddBankAlert3() {
  store.toggleAlertBg();
  store.openAddBankAlert3();
}

onMounted(() => {
  appContext.config.globalProperties.$mitt.all.delete(
    "submit-add-bank",
    submit
  );
  appContext.config.globalProperties.$mitt.on("submit-add-bank", submit);
});
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-[1vw] pb-[3rem] pt-0 md:pt-[3rem]">
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9 relative">
      <div
        class="px-0 py-0 md:px-[5vw] md:py-[2dvh] relative z-10 web-gold-border rounded-[24px]">
        <p
          class="mt-[1dvh] md:mt-[2dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.AddBank.AddBankCard") }}
        </p>

        <div
          class="px-0 pt-0 md:px-[1em] md:pt-[1em] pb-[25dvh] lg:pb-0"
          v-if="!isWithdrawPageAlert">
          <!-- APP -->

          <div
            class="relative member-bg-border mt-[1vh] w-full h-full py-[2em] px-[1em] md:px-[3vw]">
            <div v-if="!openAdminBankList" class="relative submitting"></div>
            <VForm
              @submit="openAddBankAlert"
              ref="addBankForm"
              v-slot="{ isSubmitting }">
              <div class="text-primary grid grid-cols-1 grid-rows-1 gap-1">
                <label
                  for="sPay_num"
                  class="grid grid-cols-8 text-[14px] md:text-[18px] mt-[1dvh] md:mt-[2dvh]">
                  <p class="mr-[1em] col-span-2">
                    {{ $t("MemberFunction.AddBank.BankAccount") }}
                  </p>
                  <div class="col-span-6">
                    <VField
                      class="bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md px-[10px] w-full"
                      name="sPay_num"
                      :label="$t('MemberFunction.AddBank.BankAccount')"
                      type="text"
                      :rules="{ required: true }" />
                    <ErrorMessage
                      name="sPay_num"
                      class="text-red-600 md:ml-3 ml-0 text-[12px]" />
                  </div>
                </label>
                <label
                  for="nPay_card"
                  class="grid grid-cols-8 text-[14px] md:text-[18px] mt-[1dvh] md:mt-[2dvh]">
                  <p class="mr-[1em] col-span-2">
                    {{ $t("MemberFunction.AddBank.OpeningBank") }}
                  </p>
                  <div class="col-span-6 relative">
                    <VField
                      class="bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md px-[10px] w-full"
                      name="nPay_card"
                      :label="$t('MemberFunction.AddBank.OpeningBank')"
                      type="text"
                      :rules="{ required: true }"
                      :placeholder="$t('MemberFunction.AddBank.InputBankCode')"
                      @keyup="openAdminBankKey"
                      v-model="AdminBankKey" />
                    <ul
                      class="absolute z-[80] w-full max-h-[25dvh] overflow-y-auto bg-black rounded mt-[1dvh] md:mt-[2vh]"
                      v-if="isAdminBankKey">
                      <li
                        class="text-primary px-[2vw] py-[1dvh]"
                        v-if="getAdminBankList.length <= 0">
                        {{ $t("MemberFunction.AddBank.NoFindBankCode") }}
                      </li>
                      <li
                        v-for="item in getAdminBankList"
                        :key="item.sBankId + item.nId"
                        class="border-b border-white/50 last:border-b-0">
                        <button
                          type="button"
                          class="text-primary px-[2vw] py-[1dvh]"
                          @click.stop="selectAdminBankKey(item)">
                          {{ item.sBank }}
                        </button>
                      </li>
                    </ul>
                    <p class="text-primary/60 md:ml-3 ml-0 text-[12px]">
                      {{ getAdminBankKeyName }}
                    </p>
                    <ErrorMessage
                      name="nPay_card"
                      class="text-red-600 md:ml-3 ml-0 text-[12px]" />
                  </div>
                </label>

                <label
                  for="sPay_address"
                  class="grid grid-cols-8 text-[14px] md:text-[18px] mt-[1dvh] md:mt-[2dvh]">
                  <p class="mr-[1em] col-span-2">
                    {{ $t("MemberFunction.AddBank.OpeningBranch") }}
                  </p>
                  <div class="col-span-6">
                    <VField
                      class="col-span-1 bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md px-[10px] w-full"
                      name="sPay_address"
                      :label="$t('MemberFunction.AddBank.OpeningBranch')"
                      type="text"
                      :rules="{ required: true }" />
                    <ErrorMessage
                      name="sPay_address"
                      class="text-red-600 md:ml-3 ml-0 text-[12px]" />
                  </div>
                </label>
                <label
                  for="sBranchCode"
                  class="grid grid-cols-8 text-[14px] md:text-[18px] mt-[1dvh] md:mt-[2dvh]">
                  <p class="mr-[1em] col-span-2">
                    {{ $t("MemberFunction.AddBank.BranchCode") }}
                  </p>
                  <div class="col-span-6">
                    <VField
                      class="col-span-1 bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md px-[10px] w-full"
                      name="sBranchCode"
                      :label="$t('MemberFunction.AddBank.BranchCode')"
                      type="string"
                      :placeholder="
                        $t('MemberFunction.AddBank.InputBranchCode')
                      "
                      :rules="{ required: true, min: 4, max: 4 }" />
                    <a
                      href="https://www.fisc.com.tw/TC/Service?CAID=51254999-5d15-4ddf-8e54-4b2cdb2a8399"
                      target="blank"
                      class="underline text-[12px] text-blue-500 hover:text-blue-600 cursor-pointer"
                      >查詢代碼</a
                    >
                    <ErrorMessage
                      name="sBranchCode"
                      class="text-red-600 md:ml-3 ml-0 text-[12px]" />
                  </div>
                </label>

                <!-- <div class="grid grid-cols-2">
                  <label
                    :for="$t('MemberFunction.AddBank.BasicBook')"
                    class="flex items-center justify-center text-[12px] md:text-[16px] mt-[2em] mr-[12px]"
                  >
                    <div class="h-[12px] w-[12px] relative">
                      <VField
                        class="bg-[url('@image/memberFunction/文字框/文字框_長.png')] absolute bg-100 rounded-md px-[10px] z-10 opacity-0"
                        :label="$t('MemberFunction.AddBank.BasicBook')"
                        id="nBankType"
                        name="nBankType"
                        :value="1"
                        v-model="nBankType"
                        type="radio"
                        :rules="{ required: true }"
                      />
                      <img
                        src="@image/memberFunction/10/password_btn.png"
                        alt=""
                        class="absolute h-full w-full"
                      />
                      <img
                        src="@image/memberFunction/10/password_s_btn.png"
                        alt=""
                        class="absolute h-full w-full"
                        v-if="nBankType === 1"
                      />
                    </div>
                    <p class="ml-[12px]">
                      {{ $t("MemberFunction.AddBank.BasicBook") }}
                    </p>
                  </label>
                  <label
                    :for="$t('MemberFunction.AddBank.DigitalBook')"
                    class="flex items-center justify-center text-[12px] md:text-[16px] mt-[2em] mr-[12px]"
                  >
                    <div class="h-[12px] w-[12px] relative">
                      <VField
                        class="bg-[url('@image/memberFunction/文字框/文字框_長.png')] absolute bg-100 rounded-md px-[10px] z-10 opacity-0"
                        :label="$t('MemberFunction.AddBank.DigitalBook')"
                        id="nBankType"
                        name="nBankType"
                        :value="2"
                        v-model="nBankType"
                        type="radio"
                        :rules="{ required: true }"
                      />
                      <img
                        src="@image/memberFunction/10/password_btn.png"
                        alt=""
                        class="absolute h-full w-full"
                      />
                      <img
                        src="@image/memberFunction/10/password_s_btn.png"
                        alt=""
                        class="absolute h-full w-full"
                        v-if="nBankType === 2"
                      />
                    </div>
                    <p class="ml-[12px]">
                      {{ $t("MemberFunction.AddBank.DigitalBook") }}
                    </p>
                  </label>
                </div> -->
                <!-- 
                <div class="grid grid-cols-8 gap-y-[2dvh] mt-[2dvh]">
                  <p
                    class="text-[14px] md:text-[18px] my-[1dvh] col-span-3 md:col-span-2"
                  >
                    {{
                      nBankType === 1
                        ? $t("MemberFunction.AddBank.BankBook")
                        : $t("MemberFunction.AddBank.BankCardFront")
                    }}
                  </p>
                  <div class="relative bg-black col-span-5 rounded-[6px]">
                    <button
                      class="absolute top-[6px] right-[6px] bg-white/50 text-secondary rounded-full z-20"
                      @click="clearInput('File0')"
                      v-if="ImagesState.File0ImageState"
                    >
                      <p class="relative -translate-y-[4px] w-[20px] h-[20px]">
                        x
                      </p>
                    </button>
                    <label
                      for="File0"
                      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 w-full h-full"
                      v-show="!ImagesState.File0ImageState"
                    >
                      <p
                        class="text-primary text-[30px] text-center flex justify-center items-center h-full"
                      >
                        +
                      </p>
                      <input
                        ref="File0ImageInput"
                        id="File0"
                        type="file"
                        class="hidden"
                        accept=".png, .jpg, .jpeg"
                        @change="handleFileUpload"
                      />
                    </label>
                    <div class="relative overflow-hidden rounded-[6px]">
                      <img
                        src="@image/memberFunction/11/pankcard01_bg.png"
                        alt=""
                        class="relative"
                      />
                      <img
                        :src="Images.defaultFile0Image"
                        alt=""
                        class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-10"
                      />
                    </div>
                  </div>
                  <p
                    class="text-[14px] md:text-[18px] my-[1dvh] col-span-3 md:col-span-2"
                    :class="{
                      hidden: nBankType === 1,
                    }"
                  >
                    {{ $t("MemberFunction.AddBank.DigitalAccountInfo") }}
                  </p>
                  <div
                    class="relative bg-black col-span-5 rounded-[6px]"
                    :class="{
                      hidden: nBankType === 1,
                    }"
                  >
                    <button
                      class="absolute top-[6px] right-[6px] bg-white/50 text-secondary rounded-full z-20"
                      @click="clearInput('File1')"
                      v-if="ImagesState.File1ImageState"
                    >
                      <p class="relative -translate-y-[4px] w-[20px] h-[20px]">
                        x
                      </p>
                    </button>
                    <label
                      for="File1"
                      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 w-full h-full"
                      v-show="!ImagesState.File1ImageState"
                    >
                      <p
                        class="text-primary text-[30px] text-center flex justify-center items-center h-full"
                      >
                        +
                      </p>
                      <input
                        ref="File1ImageInput"
                        id="File1"
                        type="file"
                        class="hidden"
                        accept=".png, .jpg, .jpeg"
                        @change="handleFileUpload"
                      />
                    </label>
                    <div class="relative overflow-hidden rounded-[6px]">
                      <img
                        src="@image/memberFunction/11/pankcard01_bg.png"
                        alt=""
                        class="relative"
                      />
                      <img
                        :src="Images.defaultFile1Image"
                        alt=""
                        class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-10"
                      />
                    </div>
                  </div>
                </div> -->
                <p class="text-red-600 mt-[12px]">
                  {{ addMemberCardMsg }}
                </p>
              </div>

              <div
                class="absolute md:static bottom-[-10%] md:bottom-[0] left-1/2 -translate-x-1/2 md:translate-x-0 flex items-center justify-center mt-0 md:mt-[2dvh]">
                <!-- <button
                  type="button"
                  class="btn-y-border w-[100px] px-[1em] py-[6px] mr-[2dvh]"
                  @click="openAddBankAlert3"
                >
                  <p class="text-[12px] md:text-[16px] text-secondary">
                    {{ $t("MemberFunction.AddBank.Example") }}
                  </p>
                </button> -->
                <button
                  class="btn-y-border w-[100px] px-[1em] py-[6px]"
                  type="submit"
                  :class="{ submitting: isSubmitting }"
                  :disabled="isSubmitting">
                  <p class="text-[12px] md:text-[16px] text-secondary">
                    {{
                      isSubmitting ? "" : $t("MemberFunction.AddBank.Submit")
                    }}
                  </p>
                </button>
              </div>
              <!-- 注意事項 -->
              <p class="text-red-600 text-[14px] md:text-[16px] font-bold">
                {{ Msg }}
              </p>
              <div class="text-primary text-[14px] md:text-[16px] mt-[2dvh]">
                <div class="flex justify-center items-center leading-[36px]">
                  <img
                    src="@image/memberFunction/11/驚嘆號icon.png"
                    alt=""
                    class="w-[16px] mr-1" />
                  <span> {{ $t("MemberFunction.AddBank.Notice") }} </span>
                </div>
                <div class="leading-[20px] md:leading-[24px] pb-[1vh]">
                  <p>
                    <span class="text-red-600 mr-1">※</span
                    >{{ $t("MemberFunction.AddBank.NoticeList1") }}
                  </p>
                </div>
                <table class="leading-[20px] md:leading-[24px]">
                  <tbody>
                    <tr>
                      <td class="w-[30%] md:w-[20%] align-top pb-[1vh]">
                        <span class="text-red-600 mr-1">※</span
                        >{{ $t("MemberFunction.AddBank.NoticeList2") }}
                      </td>
                      <td class="pb-[1vh]">
                        {{ $t("MemberFunction.AddBank.NoticeList3") }}
                      </td>
                    </tr>
                    <tr>
                      <td class="align-top pb-[1vh]">
                        <span class="text-red-600 mr-1">※</span
                        >{{ $t("MemberFunction.AddBank.NoticeList4") }}
                      </td>
                      <td class="pb-[1vh]">
                        {{ $t("MemberFunction.AddBank.NoticeList5") }}
                      </td>
                    </tr>
                    <tr>
                      <td class="align-top pb-[1vh]">
                        <span class="text-red-600 mr-1">※</span
                        >{{ $t("MemberFunction.AddBank.NoticeList6") }}
                      </td>
                      <td class="pb-[1vh]">
                        {{ $t("MemberFunction.AddBank.NoticeList7") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
