<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useErrorAlert } from "@/stores/errorAlert";
const errorAlert = useErrorAlert();
const { tips } = storeToRefs(errorAlert);
</script>

<template>
  <div class="fixed top-[20vh] left-1/2 -translate-x-1/2 z-[999]">
    <transition-group name="list" tag="ul">
      <!-- <div
      v-for="item in tips"
      :key="item.id"
      class="fade-element bg-cyan-950/90 border-2 border-cyan-500 rounded text-primary md:px-[8em] md:py-[6em] md:mb-[6px] p-7">
      <p class="text-primary text-[18px] md:text-[20px]">{{ item.msg }}</p>
    </div> -->
      <div
        v-for="item in tips"
        :key="item.id"
        class="fade-element rounded text-primary relative flex justify-center items-center"
      >
        <img
          class="w-[240px]"
          src="@image/memberFunction/common/search_fail.png"
          alt=""
        />
        <div
          class="absolute w-[130px] text-primary text-center text-[18px] md:text-[20px]"
        >
          <p style="white-space: pre-line;">{{ item.msg }}</p>
        </div>
      </div>
    </transition-group>
  </div>
</template>
<style>
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
