<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useCounterStore } from "@/stores/counter";
import { useLogin } from "@/stores/login";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useErrorAlert } from "@/stores/errorAlert";
const { t } = useI18n();

const login = useLogin();
const memberService = useMemberService();
const errorAlert = useErrorAlert();

const { ShareData } = storeToRefs(memberService);

const copyCuponCode = () => {
  // navigator.clipboard.writeText(ShareData.value.sUrl);
  const textArea: HTMLInputElement = document.createElement("input");
  textArea.value = ShareData.value.sUrl;
  document.body.appendChild(textArea);
  textArea.select();
  // 执行复制命令并移除文本框
  document.execCommand("copy")
    ? errorAlert.AddErrorAlert(t("MemberFunction.ShareMakeFortune.UrlCopy"))
    : errorAlert.AddErrorAlert("複製失敗");

  document.body.removeChild(textArea);
};

import QrcodeVue from "qrcode.vue";
import type { Level, RenderAs } from "qrcode.vue";

const qrValue = ref<String>(
  "https://www.icv8888.net/Project/t_icv00/ClientTest/ICV/#/PromotionReg?user=" +
    login.memberData.sAccount
);
const level = ref<Level>("M");
const renderAs = ref<RenderAs>("svg");
</script>
<template>
  <div class="px-[2vw] md:px-[5vw] py-[2dvh]">
    <div class="text-[#fff]">
      <div class="relative">
        <img
          src="@image/memberFunction/03/title_line.png"
          alt=""
          class="absolute h-[90%] -left-[10px] top-1/2 -translate-y-1/2"
        />
        <p class="font-bold text-[18px]">
          {{ $t("MemberFunction.ShareMakeFortune.YourQRcord") }}
        </p>
      </div>

      <div class="flex justify-center items-center my-[20px] relative">
        <img
          class="w-[120px] md:w-[10dvw] absolute z-10"
          :src="ShareData.sQrImg2"
          alt=""
        />
        <img
          class="w-[140px] md:w-[12dvw] relative"
          src="@image/memberFunction/03/QR_bg.png"
          alt=""
        />
      </div>
    </div>

    <div class="font-bold text-[18px] text-[#fff] break-words">
      <div class="relative">
        <img
          src="@image/memberFunction/03/title_line.png"
          alt=""
          class="absolute h-[90%] -left-[10px] top-1/2 -translate-y-1/2"
        />
        <p class="font-bold text-[18px]">
          {{ $t("MemberFunction.ShareMakeFortune.YourShareUrl") }}
        </p>
      </div>
      <div class="link-bg-border py-1 pl-2 pr-1 flex my-[2vh]">
        <div class="w-full text-[12px] md:text-[16px] overflow-hidden pr-1">
          {{ ShareData.sUrl }}
        </div>
        <button class="relative" @click="copyCuponCode()">
          <img
            src="@image/memberFunction/03/share_copy_icon.png"
            alt=""
            class="w-[50px] md:w-[10vh]"
          />
          <p
            class="text-[14px] md:text-[16px] text-secondary absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
          >
            {{ $t("MemberFunction.ShareMakeFortune.Copy") }}
          </p>
        </button>
      </div>
    </div>
  </div>
</template>
<style>
.link-bg-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/03/link_bg.png") 1;
  -webkit-border-image: url("@image/memberFunction/03/link_bg.png") 1;
  -o-border-image: url("@image/memberFunction/03/link_bg.png") 1;
  border-image: url("@image/memberFunction/03/link_bg.png") 1 fill;
}
</style>
