import { ref, computed, watchEffect } from "vue";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import { getMUserC } from "@/assets/API/API";
import { useLogin } from "@/stores/login";
import { useLanguagesStore } from "@/stores/languages";
import { useMaintain } from "@/stores/maintain";
import { useErrorAlert } from "@/stores/errorAlert";
import { useGameIFrame } from "@/stores/gameIFrame";
import { useBgAudio } from "@/stores/bgAudio";
import * as APIfn from "@/assets/API/API";
import * as APItypes from "@/assets/API/APItype";

interface GameLobby {
  nCanIframe: string;
  sGameType: string;
}

export const useLobbyList = defineStore("LobbyList", () => {
  const languages = useLanguagesStore();
  const errorAlert = useErrorAlert();
  const maintain = useMaintain();
  const gameIFrame = useGameIFrame();
  const bgAudio = useBgAudio();
  const router = useRouter();
  const login = useLogin();

  const LobbyGameList = ref<APItypes.LobbyList>({} as APItypes.LobbyList);
  const CanIframe = ref<string>("0");
  const GameType = ref<string>("");

  function ToGameLobby() {
    if (!GameType.value) return router.push({ name: "main" });
    login.CheckMUC(successGameLobby, failGameLobby);
  }
  function successGameLobby() {
    getLobbyList({
      nCanIframe: CanIframe.value,
      sGameType: GameType.value,
    });
    return;
  }
  function failGameLobby() {
    return;
  }
  async function getLobbyList(data: GameLobby) {
    const res = await APIfn.postLobbyList({
      ...data,
      // sLang: languages.LanguageType,
      m_user_c: login.CookieMUC(),
    });
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    switch (res.type) {
      case 1:
        LobbyGameList.value = res.aLobbyList;
        break;
      case "1":
        LobbyGameList.value = {};
        break;
      default:
        LobbyGameList.value = {};
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 進入遊戲
  async function ToGame(testUrl: string) {
    const url = testUrl.replace("_OpenWinApi", "_OpenWinApi_test");
    const newWindow = window.open("", "_blank");
    await login.getMoneyBack();
    if (CanIframe.value === "0") {
      newWindow!.location.href = url;
    } else {
      newWindow!.close();
      gameIFrame.ToGameIFrame(url);
      bgAudio.stopMusic();
    }
  }
  return {
    ToGameLobby,
    LobbyGameList,
    CanIframe,
    GameType,
    ToGame,
  };
});
