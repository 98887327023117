<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";

import { useMemberService } from "@/stores/memberFunction/memberService";

import BetRecord from "@/components/menberFunction/betRecord.vue";
import BetRecordList from "@/components/menberFunction/betRecordPage/betRecordList.vue";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";

const memberService = useMemberService();
const { MemberCompanyDepositMsg, MemberRecharge } = storeToRefs(memberService);

const showDepositPage = ref<number>(1);
function changeDepositPage(page: number) {
  showDepositPage.value = page;
  MemberCompanyDepositMsg.value = "";
}
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-[1vw] pb-[3rem] pt-0 md:pt-[3rem]"
  >
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9 relative">
      <div
        class="px-0 pt-0 pb-0 md:px-[5vw] md:pb-[10dvh] md:pt-[2dvh] relative z-10 web-gold-border rounded-[24px]"
      >
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.BetRecord.BetRecord") }}
        </p>
        <div class="flex justify-center items-center mt-5 mx-2">
          <button
            class="group relative flex justify-center items-center mr-[1vw]"
            @click="changeDepositPage(1)"
            :class="{
              'text-secondary': showDepositPage === 1,
              'text-primary hover:text-secondary': showDepositPage !== 1,
            }"
          >
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn_p.png"
              alt=""
              :class="{
                block: showDepositPage === 1,
                'hidden group-hover:block': showDepositPage !== 1,
              }"
            />
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn.png"
              alt=""
              :class="{
                hidden: showDepositPage === 1,
                'block group-hover:hidden': showDepositPage !== 1,
              }"
            />
            <div class="absolute text-[14px] font-bold">
              {{ $t("MemberFunction.BetRecord.WagerRecord") }}
            </div>
          </button>
          <button
            class="group relative flex justify-center items-center mr-[1vw]"
            @click="changeDepositPage(2)"
            :class="{
              'text-secondary': showDepositPage === 2,
              'text-primary hover:text-secondary': showDepositPage !== 2,
            }"
          >
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn_p.png"
              alt=""
              :class="{
                block: showDepositPage === 2,
                'hidden group-hover:block': showDepositPage !== 2,
              }"
            />
            <img
              class="w-[100px]"
              src="@image/memberFunction/common/top_btn.png"
              alt=""
              :class="{
                hidden: showDepositPage === 2,
                'block group-hover:hidden': showDepositPage !== 2,
              }"
            />

            <div class="absolute text-[14px] font-bold">
              {{ $t("MemberFunction.BetRecord.WagerTable") }}
            </div>
          </button>
        </div>
        <div class="relative member-in-bg w-full h-full">
          <!-- main -->

          <BetRecord v-if="showDepositPage === 1" />
          <BetRecordList v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
